import React from 'react'
import deniedImage from "../../../assets/img/theme/doctor-denied.png"

function DoctorDeniedScreen({user, setUser}) {

  const reRegistration = () => {
    setUser({...user, data:{...user.data, approvalState: null}})
  }

  return (
    <div className='denied-section card row'>
    <div className='denied-content text-center'>
      <h1 className='denied-title'>Registration failed<br />Please try again</h1>
      <div className='text-center'><span className='pointer register-link' onClick={reRegistration}>{'Back to registration form >>'}</span></div>
      <img src={deniedImage} alt='denied-img'/>
    </div>
  </div>
  )
}

export default DoctorDeniedScreen