import { getCountriesAndConfig, updateOrAddConfigurations } from 'api/admin-api'
import { CONFIG_FIELDS } from 'consts'
import React, { useEffect, useState } from 'react'
import FormEdit from './configurationsEdit'
import AppConfigEdit from './app-config-edit'
import { useSelector } from 'react-redux'

function Configurations() {
    const { countries, appConfigurations: configs } = useSelector(state => state)
    const [selectedConfig, setSelectedConfig] = useState()
    const [isEdit, setIsEdit] = useState(false)
    const [showAddConfigOptions, setShowAddConfigOptions] = useState()
    const [newData, setNewData] = useState([])

    const initialConfig = {
        watch_configuration_id: 0,
        name: '',
        configuration: {},
        patient_portal_enabled: false,
        physician_portal_enabled: false
    }


    const fetchData = async () => {
        if (!countries.length) await getCountriesAndConfig()
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        setShowAddConfigOptions(false)
    }, [isEdit, selectedConfig])

    const onSaveConfiguration = async () => {
        await updateOrAddConfigurations(selectedConfig)
        setIsEdit(false)
    }

    const getCountriesByConfig = (configId) => {
        const filteredCountries = countries.filter(c => c.app_configuration_id === configId)
        return filteredCountries
    }

    const handleInput = (e) => {
        setNewData(prevData => ({ ...prevData, [e.target.name]: e.target.value }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
    }

    const addConfigSelect = () => {
        return (
            <div className="dropdown">
                <button className="btn btn-primary mb-2 dropdown-toggle" onClick={() => setShowAddConfigOptions(!showAddConfigOptions)}>
                    Add new configurations
                </button>
                <ul className={`dropdown-menu ${showAddConfigOptions && 'show'}`}>
                    <li>
                        <button onClick={() => { setIsEdit(true); setSelectedConfig(initialConfig) }} className="dropdown-item" type="button">Add blank configurations</button>
                    </li>
                    {configs?.sort((a, b) => a.id - b.id).map(conf => (
                        <li>
                            <button onClick={() => { setIsEdit(true); setSelectedConfig({...conf, id: null, name: ''}) }} className="dropdown-item" type="button">{`clone configurations from ${conf.name}`}</button>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }

    return (
        <div className='card-body'>
            {!selectedConfig ?
                <div className='wrap-config'>
                    {addConfigSelect()}
                    <div className='list-group'>
                        {
                            configs?.sort((a, b) => a.id - b.id).map(conf => (
                                <div onClick={() => setSelectedConfig(conf)} key={conf.id} className='list-group-item d-flex justify-content-between cursor-pointer'>
                                    <p><strong className='pr-2'>ID:</strong><span>{conf.id}</span></p>
                                    <p><strong className='pr-2'>Name:</strong><span>{conf.name}</span></p>
                                    <p><strong className='pr-2'>patient portal enabled:</strong><span><input readOnly className='pointer-events-none' type='checkbox' checked={conf.patient_portal_enabled} /></span></p>
                                    <p><strong className='pr-2'>Physician portal enabled:</strong><span><input readOnly type='checkbox' checked={conf.physician_portal_enabled} /></span></p>
                                    <select className='w-25 form-control' value={'Countries'} onClick={(e) => e.stopPropagation()}>
                                        <option className='pointer-events-none'>Countries</option>
                                        {getCountriesByConfig(conf.id).map(c => <option className='pointer-events-none'>{c.name}</option>)}
                                    </select>
                                </div>
                            ))
                        }
                    </div>
                </div>
                :
                <div>
                    <span onClick={() => setSelectedConfig()} className='btn btn-outline-dark mb-2'>{'< back to configuration list'}</span>
                    <FormEdit isEdit={isEdit} setIsEdit={setIsEdit} formFields={CONFIG_FIELDS} data={selectedConfig} onSubmit={onSaveConfiguration} onInput={setSelectedConfig} />
                    <AppConfigEdit configurations={selectedConfig?.configuration} setConfigurations={setSelectedConfig} isEdit={isEdit} />
                </div>
            }
        </div>

    )
}

export default Configurations