import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const CaptchaComponent = ({ onCaptchaChange }) => {
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    onCaptchaChange(value);
  };
  // a test site key for testing
  // const testSiteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
  return (
    <div>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        onChange={handleCaptchaChange}
      />
    </div>
  );
};

export default CaptchaComponent;
