import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Container } from 'reactstrap'
import './account-deletion.css'
import axios from 'axios'
import ConfirmModal from "components/Modals/confirm-modal";


export default function AccountDeletion() {
  const [reason, setReason] = useState('')
  const [showPopUpConfirm, setShowPopUpConfirm] = useState(false)
  const { user } = useSelector(state => state)
  const handleChange = (event) => {
    setReason(event.target.value)
  }

  const sendRequest = async () => {
    await axios.post(`${process.env.REACT_APP_API_URL}/app/user/delete-account`, { description: reason }, { headers: { 'Authorization': `Bearer ${user.jwtToken}` } })
    setShowPopUpConfirm(false)
    setReason('')
  }
  return (
    <Container fluid>
      <div id='delete-account'>
        <div className="profile-content-container">
          {showPopUpConfirm &&
            <ConfirmModal
              text={`Are you sure you want to delete your account forever?`}
              onConfirm={sendRequest}
              onCancel={setShowPopUpConfirm}
              confirmButtonText='Send'
              cancelButtonText='Cancel'
            />
          }

          <div className='title'>Account deletion</div>
          <div className='line'></div>
          <div className='description'>Pressing the button below will initiate your account<br />deletion, thus process the right to be forgotten. </div>
          <div className='worning' >WARNING !</div>
          <div className='after-delete'>After an account deletion, there will be no access to<br />your historical measurements.</div>
          <div className='input-title'>Let us know why you would like to delete your account</div>
          <input placeholder='(Optional)' onChange={handleChange} />
          <div className='support'>Our support team will contact you to confirm your<br />request prior to the final account deletion.</div>
          <div className='line long-line'></div>
          <div className='send-request' onClick={() => setShowPopUpConfirm(true)}>Send Request</div>
        </div>
      </div>

    </Container>
  )
}