import React from 'react'
import ReminderIcon from '../../../assets/img/icons/remindmelater.svg'
import OffwristIcon from '../../../assets/img/icons/offwrist.svg'
import AppDisconnectIcon from '../../../assets/img/icons/app_disconnected.svg'
import WatchDisconnected from '../../../assets/img/icons/watch_diconnected.svg'
import AlertIcon from '../../../assets/img/icons/alerton.svg'
import BatteryIconComponent from 'components/icons/battery-icon/battery-icon.component'
import './patient-status.scss'
import { parseBoolean } from 'utils/formaters'
import moment from 'moment'

function PatientStatusComponent({ sockeData, dbAlerts, remindAlerts }) {

    const renderAlertStatus = () => {
        let src;
        let title;
        if (remindAlerts?.length) {
            src = ReminderIcon
            title = 'Snooze alert'
        }

        //checking if there are alerts from socket or from DB
        if ((sockeData?.onAlert && Object.values(sockeData?.onAlert)?.length) || dbAlerts?.length) {
            src = AlertIcon
            title = 'Alerts detected'
        }
        return <img className={`status-icon alert-icon ${!src && 'hide'}`} src={src} alt={title} title={title} />
    }

    const renderBatteryStatus = () => {
        let percent = sockeData?.batteryPercentage?.value || 0
        let color = '#1D3378'
        if(percent <= 25) color = '#F2E500'
        if(percent <= 10) color = '#EF3054'
        return <div className={`status-icon ${percent < 1 && 'hide'}`}><BatteryIconComponent percent={percent} fill={color} /></div>
    }

    const renderConnectStatus = () => {
        let src = AppDisconnectIcon;
        let title = 'App disconnected';
        let offWristTimeStatus;
        if (sockeData) src = null//the app is connected

        if (sockeData?.connectionStatus?.value === 0) {//the watch is not connected to the app
            src = WatchDisconnected
            title = 'Watch disconnected'
        }
        else if (parseBoolean(sockeData?.offWrist?.value)) {
            src = OffwristIcon
            title = 'Off-Wrist'
            const offWristHours = moment().diff(moment(sockeData?.offWrist?.lastChange), 'hours')
            if(offWristHours){
                offWristTimeStatus = `${offWristHours}H`
            }
            if(offWristHours > 24){//display offwrist time in days
                offWristTimeStatus = `+${Math.floor(offWristHours/24)}D`
            }
        }
        return <div className='wrap-status-icon'>
                    <img className={`status-icon ${!src && 'hide'}`} src={src} alt={title} title={title} />
                    {offWristTimeStatus && <div className='offwrist-time'>{offWristTimeStatus}</div>}
                </div>
    }
    return (
        <div className='patient-status-row'>
            <div className="patient-status-col alert-status">{renderAlertStatus()}</div>
            <div className="patient-status-col battery-status">{renderBatteryStatus()}</div>
            <div className="patient-status-col connect-status">{renderConnectStatus()}</div>
        </div>
    )
}

export default PatientStatusComponent