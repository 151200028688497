/* eslint-disable import/no-anonymous-default-export */

export default (file) => {
    return new Promise((resolve,reject)=>{
      if(!file) {
        reject()
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const contents = e.target.result;
        resolve(contents)
      };
      reader.readAsDataURL(file);
    })
  }