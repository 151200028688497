import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Container } from 'reactstrap'
import DatePickerModal from 'components/Modals/DatePicker'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import ReportIconComponent from 'components/icons/report-pdf-icon/report-icon-component'
import { DetectionAlerts } from 'components/Modals/DoctorAlerts'
import ArrythmiaFilter from './arrythmia-filter'
import './archive.scss'
import DateFilterIcon from '../../assets/img/icons/timefilter.svg'
import DateIcon from '../../assets/img/icons/calander.svg'
import { ARRYTHMIA_EVENT_TYPES } from 'consts'

export default function Archive({ user, jwtToken, role = 'patient', dates: initDates }) {
    const [selectedArrythmias, setSelectedArrythmias] = useState({})
    const location = useLocation()
    const [togglePicker, setTogglePicker] = useState(false)
    const [isArrythFilterOpen, setIsArrythFilterOpen] = useState(false)
    const [annotaionReports, setAnnotaionReports] = useState({ links: [], length: 0, currentPage: 0 })
    const [monthlyReports, setMonthlyReports] = useState({ links: [], length: 0, currentPage: 0 })
    const [realtimeReports, setRealtimeReports] = useState({ links: [], length: 0, currentPage: 0 })
    const [arrythmiaReports, setArrythmiaReports] = useState({ links: [], length: 0, currentPage: 0 })
    const [dates, setDates] = useState(initDates || location?.state?.dates || [])

    const filterDates = (timestamp) => {
        if (!dates.length) {
            return true
        }
        return new Date(timestamp) >= new Date(dates[0]) && new Date(timestamp) <= new Date(dates[1])
    }

    const filterByArrythmias = () => {
        const isSelectedAllTags = Object.values(selectedArrythmias).length && Object.values(selectedArrythmias)?.filter(ar => !ar).length === 0
        if(isSelectedAllTags) return arrythmiaReports
        
        const isNSRSelected = selectedArrythmias[ARRYTHMIA_EVENT_TYPES.NORMAL_SINUS_RHYTHM]
        //we set NSR to false because if there is a report with NSR and other arrythmias it should not be display by th NSR filter
        const arSelected = {...selectedArrythmias, [ARRYTHMIA_EVENT_TYPES.NORMAL_SINUS_RHYTHM]: false}
        const filtered = arrythmiaReports?.links?.filter(report => {
            //if isNSRSelected we are showing all of the reports without arrythmias alerts
            if(isNSRSelected && !report.alerts.length)
                return report
            //filter by the report arrythmias alert slected
            return report.alerts.some(tag => arSelected[tag]);
        });
        return { ...arrythmiaReports, links: filtered }
    }

    const getFilterdReports = (reports) => {
        return reports?.links?.filter((link) => filterDates(link.timestamp)).slice(reports.currentPage, reports.currentPage + 5)
    }

    const Header = ({ reports, setReports, title }) => {
        const linksFilterd = reports.links?.filter((link) => filterDates(fixTimestamp(link.timestamp)))

        const onClear = () => {
            setReports((prev) => ({ ...prev, currentPage: 0, dates: [] }))
        }

        const PagingNav = () => {
            const lastIndex = reports.currentPage + 5 > linksFilterd?.length ? linksFilterd.length : reports.currentPage + 5
            const leftDisable = reports.currentPage < 1
            const rightDisable = reports.currentPage + 5 >= linksFilterd?.length

            return (
                <div className='wrap-pagination' >
                    <i className="fas fa-arrow-left"
                        style={{ opacity: leftDisable ? '.3' : '1', cursor: leftDisable ? 'default' : 'pointer' }}
                        onClick={() => previousPage(reports, setReports)} />

                    <div>
                        {linksFilterd?.length > 0 ? reports?.currentPage + 1 : 0}{lastIndex > 1 ? `-${lastIndex}` : ''} of {linksFilterd?.length}
                    </div>
                    <i className="fas fa-arrow-right"
                        style={{ opacity: rightDisable ? '.3' : '1', cursor: rightDisable ? 'default' : 'pointer' }}
                        onClick={() => rightDisable ? null : nextPage(reports, setReports)} />
                </div>
            )
        }

        return (
            <div className='archive-header' >
                <div className='archive-header-left'>
                    <span className='archive-report-title'>{title} reports</span>
                    {title === 'Measurement' && <ArrythmiaFilter selectedArrythmias={selectedArrythmias} setSelectedArrythmias={setSelectedArrythmias} isArrythFilterOpen={isArrythFilterOpen} setIsArrythFilterOpen={setIsArrythFilterOpen} />}
                </div>
                <PagingNav />
            </div>
        )
    }

    useEffect(() => {
        axios.post(
            `${process.env.REACT_APP_API_URL}/app/${role}/get-patinet-report-links`,
            { patientName: `${user.firstName}-${user.lastName}`, patientId: user.id },
            { headers: { 'Authorization': `Bearer ${jwtToken}` } }
        )
            .then(({ data }) => {
                const { monthlyLinks, annotationLinks, realtimeLinks, arrythmiaLinks } = data
                setAnnotaionReports({ ...annotaionReports, links: annotationLinks, length: annotationLinks.length })
                setMonthlyReports({ ...monthlyReports, links: monthlyLinks, length: monthlyLinks.length })
                setRealtimeReports({ ...realtimeReports, links: realtimeLinks, length: realtimeLinks?.length })
                setArrythmiaReports({ ...arrythmiaReports, links: arrythmiaLinks, length: arrythmiaLinks?.length })
            })

    }, [])

    const isReportAlert = (alerts) => {
        if (alerts?.length) return true
        return false
    }

    const previousPage = (reports, setReports) => {
        if (reports.currentPage < 5) {
            return
        }
        else {
            setReports({ ...reports, currentPage: reports.currentPage - 5 })
        }
    }
    const nextPage = (reports, setReports) => {
        if (reports.currentPage + 5 >= reports.length) {
            return
        }
        else {
            setReports({ ...reports, currentPage: reports.currentPage + 5 })
        }
    }

    const ReportLink = ({ name, href, isAlert, alerts, patientId }) => {
        alerts = alerts?.map(a => ({eventType: a}))
        return <div className='report-link'>
            <ReportIconComponent isAlert={isAlert} />
            <a target="_blank" rel="noreferrer" href={href}> {name} </a>
            {alerts && <span className='wrap-report-detections'><DetectionAlerts alerts={alerts} patientId={patientId} /></span>}
        </div>
    }

    const RealtimeReportLink = ({ name, data }) =>
        <div className='report-link' onClick={() => {
            localStorage.setItem('realtimeData', JSON.stringify(data.jsonObject))
            return window.open("/static/reports/realtime/", "_blank")
        }}>
            <ReportIconComponent />
            <div className='report-link-url'> {name} </div>
        </div>

    const onConfirm = (year, startDate, endDate) => {
        setDates([startDate || `01/01/${year}`, endDate || `12/31/${year}`])
    }

    const fixTimestamp = (timestamp, toUtc) => {
        const str = timestamp?.toString().padEnd(13, 0)
        if (toUtc) return moment(Number(str)).utc().format('DD MMM YYYY HH:mm')
        return moment(Number(str)).format('DD MMM YYYY HH:mm')
    }


    const NoReportsContent = () => {
        return (
            <div className='no-reports-content' >No matches found, try changing the filter</div>
        )
    }

    return (
        <Container fluid>
            <DatePickerModal
                isOpen={togglePicker}
                toggle={setTogglePicker}
                onConfirm={onConfirm}
                chooseRange={true}
            />
            <div className="profile-content-container wrap-archive">
                <div className='toggle-date-picker'>
                    <img alt='date-filter' src={DateFilterIcon} />
                    <span className='date-toggle-title'>Date</span>
                    <span onClick={() => setTogglePicker(true)} className='filter-input'>{new Date(dates?.[0]).toLocaleDateString('en-GB')} - {new Date(dates?.[1]).toLocaleDateString('en-GB')}</span>
                    <img alt='date' src={DateIcon} />
                </div>
                <div className='wrap-reports'>
                    <Header setReports={setArrythmiaReports} reports={filterByArrythmias()} title={'Measurement'} />
                    {getFilterdReports(filterByArrythmias())?.length ?
                        getFilterdReports(filterByArrythmias())?.map((data, i) =>
                            <ReportLink
                                key={i}
                                isAlert={isReportAlert(data.alerts)}
                                alerts={data.alerts}
                                name={fixTimestamp(data.timestamp, true)}
                                href={data.link} 
                                patientId={user.id} />
                        ) : <NoReportsContent />}
                    {role !== 'patient' &&
                        <div>
                            <Header setReports={setRealtimeReports} reports={realtimeReports} title={'Requested by physician'} />
                            {getFilterdReports(realtimeReports).length ?
                                getFilterdReports(realtimeReports).map((data, i) =>
                                    <RealtimeReportLink
                                        key={i}
                                        name={fixTimestamp(data.timestamp)}
                                        data={data} />
                                ) : <NoReportsContent />}
                        </div>
                    }
                    <Header setReports={setMonthlyReports} reports={monthlyReports} title={'Monthly'} />
                    {getFilterdReports(monthlyReports)?.length ?
                        getFilterdReports(monthlyReports).map((data, i) =>
                            <ReportLink
                                key={i}
                                name={fixTimestamp(data.timestamp)}
                                href={data.link} />
                        ) : <NoReportsContent />}
                    <br />
                </div>
            </div>
        </Container>
    )
}
