import { addOrUpdateMedications, fetchMedicationsApi } from 'api/api-manager';
import React, { useEffect, useState } from 'react'
import SearchIcon from '../../../assets/img/icons/Search.svg'
import ArrowDown from '../../../assets/img/icons/arrow-down.svg'
import ReminderForm from './reminder-form';

function MedicationForm({ patient, editMedication, closeModal }) {
    const isEditMode = editMedication?.id;
    const [medication, setMedication] = useState(editMedication);
    const [showDosageOptions, setShowDosageOptions] = useState();
    const [medicationOptions, setMedicationOptions] = useState([]);
    const [dosageOptions, setDosageOptions] = useState([]);
    const [remindersToDelete, setRemindersToDelete] = useState([]);

    useEffect(() => {
        fetchMedicationOptions();
    }, [medication.name]);

    useEffect(() => {
        dosageOptions?.includes(medication.dosage) ? setShowDosageOptions(false) : setShowDosageOptions(true)
    }, [medication.dosage, dosageOptions]);

    async function fetchMedicationOptions() {
        if (!medication?.name) return;
        const res = await fetchMedicationsApi(medication?.name);
        const [resultCount, medicationNames, medicationDosages] = res;
        setMedicationOptions(medicationNames || []);
        if (medicationNames?.includes(medication.name)) {
            setMedicationOptions([]);
            setDosageOptions(medicationDosages?.STRENGTHS_AND_FORMS?.[0] || []);
        }
    }

    const updateMedication = (medicationKey, val) => {
        setMedication((prev) => ({ ...prev, [medicationKey]: val }));
    };

    const addReminder = () => {
        const initialReminder = { time: '00:00', days: '', is_alarm_on: true };
        const reminders = [...medication.reminders, initialReminder];
        updateMedication('reminders', reminders);
    };

    const onSelectDosage = (option) => {
        updateMedication('dosage', option)
        setShowDosageOptions(false)
    }

    const updateReminder = (reminderIndex, updatedField) => {
        let reminders = medication?.reminders?.map((r, i) =>
            i === reminderIndex ? { ...r, ...updatedField } : r,
        );
        updateMedication('reminders', reminders);
    };

    const deleteReminders = ([indexToDelete]) => {
        const filteredReminders = medication?.reminders?.filter((r, i) => {
            if (i === indexToDelete) {
                if (r.id) {
                    setRemindersToDelete([...remindersToDelete, r.id]);
                }
            } else {
                return r
            }
        });
        updateMedication('reminders', filteredReminders);
    };

    const saveMedication = async () => {
        try {
            await addOrUpdateMedications(patient.id, [medication], remindersToDelete, isEditMode);
        } catch (error) {
            console.log('error saveMedication', error);
        }
        closeModal()
    };

    return (
        <div className='wrap-reminder-form'>
            <p className='reminder-form-title'>Adding medication</p>
            <form className='reminders-form'>
                <div className='form-group'>
                    <span className='label'>Add your medication</span>
                    <input onInput={(e) => updateMedication('name', e.target.value)} className='form-control name-input' value={medication?.name} />
                    <span className='input-icon'><img src={SearchIcon} alt='search' /></span>
                    {medicationOptions.length > 0 &&
                        <ul className='api-options'>
                            {medicationOptions.map(op => <li key={op} onClick={() => updateMedication('name', op)}>{op}</li>)}
                        </ul>
                    }
                </div>
                <div className='form-group'>
                    <span className='label'>Dosage</span>
                    <input onInput={(e) => updateMedication('dosage', e.target.value)} className='form-control dosage-input' value={medication?.dosage} />
                    <span onClick={() => setShowDosageOptions(!showDosageOptions)
                    } className='input-icon'><img className={`${showDosageOptions && 'arrow-up'}`} src={ArrowDown} alt='search' /></span>
                    {dosageOptions.length > 0 && showDosageOptions &&
                        <ul className='api-options'>
                            {dosageOptions.map(op => <li key={op} onClick={() => onSelectDosage(op)}>{op}</li>)}
                        </ul>
                    }
                </div>
                <p className='reminder-form-title'>Reminders<small className='text-black'>Reminder will show in mobile app only</small></p>
                {medication.reminders?.length > 0 &&
                    medication.reminders?.map((r, reminderIndex) =>
                        <ReminderForm key={reminderIndex} reminder={r} reminderIndex={reminderIndex} updateReminder={updateReminder} deleteReminders={deleteReminders} />
                    )
                }
                <span className='add-reminder' onClick={addReminder}>+ Add reminder</span>
            </form>
            <div className="modal-footer p-0">
                <button
                    className="btn cancel-btn"
                    type="button"
                    onClick={() => closeModal()}>Cancel</button>

                <button
                    disabled={!medication?.name || !medication?.dosage}
                    className="btn save-btn"
                    type="button"
                    onClick={saveMedication}>Save</button>
            </div>
        </div>
    )
}

export default MedicationForm