import moment from "moment"
import { Modal, Button, Row } from "reactstrap"
import Alerton from '../../assets/img/icons/alerton.svg'
import Bell from '../../assets/img/icons/bell.svg'
import axios from "axios"
import { useDispatch } from "react-redux"
import { updatePatients } from "store/actions"
import { fixNumAndRemoveDecimalZero } from "utils"
import './doctor-alerts.scss'
import { ARRYTHMIA_EVENTS } from "consts"
import { downloadArrythReport } from "api/api-manager"

export const DetectionAlerts = ({alerts, patientId}) => {

  const downloadReport = async (e, rt_session_id) => {
    e.stopPropagation();
    await downloadArrythReport(patientId, rt_session_id)
  }

  return(
    <div className="detection-alerts">
      {alerts.map(({eventType, rt_session_id}) => (
        <div onClick={(e) => downloadReport(e, rt_session_id)} key={eventType} className={`detection-alert ${rt_session_id && 'cursor-pointer'}`}>
          <span className="alert-hover">{ARRYTHMIA_EVENTS[eventType]?.desc}</span>
          <span className={`alert-tag ${ARRYTHMIA_EVENTS[eventType]?.detection_type}`}>{ARRYTHMIA_EVENTS[eventType]?.short}</span>
        </div>
      ))}
    </div>
  )
}

export default function DoctorAlerts({ alerts, toggleModal, patient, user, alertsOrRemind, isRealtime }) {
  const dispatch = useDispatch()
  const onConfirm = async (status) => {
    const listId = alerts.map(a => a.alertId)
    try {
      let userId = user?.data?.id
      await axios.post(
        `${process.env.REACT_APP_API_URL}/app/patient/update-alert-status`,
        { id: listId, status, userId },
        { headers: { 'Authorization': `Bearer ${user.jwtToken}` } }
      )
      if (alertsOrRemind === 'remindAlerts' && status === 'remind_later') return toggleModal(false)
      alertsOrRemind === 'alerts' && status === 'remind_later'
        ? dispatch(updatePatients({ ...patient, remindAlerts: [...patient.remindAlerts, ...alerts], [alertsOrRemind]: [] }))
        : dispatch(updatePatients({ ...patient, [alertsOrRemind]: [] }))
      toggleModal(false)
    }
    catch (err) {
      console.log('error marking alerts', err)
    }
  }

  const orderAlerts = () => {
    if (!alerts) return []
    const arrythmiaAlerts = alerts.filter(a => ARRYTHMIA_EVENTS[a.eventType])
    const measurementAlerts = alerts.filter(a => !ARRYTHMIA_EVENTS[a.eventType])
    const mergeArrythAlertsByDate = {}
    arrythmiaAlerts.map(a => {
      const { alertDate, eventType, rt_session_id } = a
      if (!mergeArrythAlertsByDate[a.alertDate]) {//is still not exist in object this date
        mergeArrythAlertsByDate[alertDate] = { isDetections: true, alertDate, eventTypes: [{eventType, rt_session_id}] }
      } else {
        mergeArrythAlertsByDate[alertDate].eventTypes.push({eventType, rt_session_id})
      }
    })
    const mergeAlerts = [...measurementAlerts, ...Object.values(mergeArrythAlertsByDate)].sort((a, b) => a.alertDate - b.alertDate)
    return mergeAlerts
  }

  return (
    <Modal
      className={`alerts-modal modal-dialog-${isRealtime ? 'bottom modal-overlay-none' : 'centered'}`} size="md"
      isOpen={alerts && true}
      toggle={() => toggleModal(undefined)}
    >
      <div className="modal-header">
        <Row style={{ display: 'flex', justifyContent: 'flex-start', }}>
          <div style={{ fontSize: 20, marginLeft: 25, marginTop: 20, color: '#060606' }} >Name</div>
          <div style={{ fontSize: 20, marginTop: 20, color: '#1D3378', fontWeight: '500', marginLeft: 15 }}>{patient?.firstName} {patient?.lastName}</div>
          <div style={{ fontSize: 20, marginLeft: 25, marginTop: 20, color: '#060606' }}>ID</div>
          <div style={{ fontSize: 20, marginTop: 20, color: '#1D3378', fontWeight: '500', marginLeft: 15 }}>{patient?.idNumber}</div>
          <div style={{ marginLeft: 25, width: 450, border: '1px solid #003F7B', marginTop: 20 }}></div>
        </Row>
      </div>
      <div className="modal-body" style={{ paddingTop: '0px' }}>
        <div className="px-2">
          {orderAlerts().map(alert =>
            <Row key={alert.alertId} className="mt-2">
              <div className="col-sm-1 me-1">
                <img className="status" src={Alerton} alt='alert' />
              </div>
              <div className="alert-date">
                {moment(alert.alertDate).format('MMM D, HH:mm')}
              </div>
              <div className="alert-type">{alert.isDetections ? 'Detections' : alert.alertType}</div>
              <div className="alert-value">
                {alert.isDetections 
                  ? 
                    <DetectionAlerts alerts={alert.eventTypes} patientId={patient.id}/>
                  :
                    <div>{fixNumAndRemoveDecimalZero(alert.value, 1)}</div>
                }
              </div>
            </Row>
          )}
        </div>
      </div>
      <div style={{ marginLeft: 25, width: 450, border: '1px solid #003F7B', marginTop: 20 }}></div>
      <div className="modal-footer">
        <Button style={{ width: 260, height: 54, color: '#000000', fontSize: 20, fontWeight: '500', border: '2.5px solid #3873AE', borderRadius: 30, textAlign: 'center' }}
          type="button"
          onClick={() => onConfirm('remind_later')}>
          <img src={Bell} alt='Bell' style={{ marginRight: 10 }} />
          Remind me later
        </Button>
        <Button style={{ width: 144, height: 54, color: '#ffffff', fontSize: 20, marginLeft: 30, fontWeight: '500', backgroundColor: '#2F659C', borderRadius: 30, textAlign: 'center' }}
          type="button"
          onClick={() => { onConfirm('closed') }}>
          Checked
        </Button>
      </div>
    </Modal>
  )
}
