import React, { useState } from 'react'
import PeripheralSearch from './peripheral-search-form'

function Watches() {
  const [peripheralData, setPeripheralData] = useState({})
  const { peripheral, peripheralPatients, peripherlVersions, patientPeripherals } = peripheralData
  console.log('peripheral --->', peripheral)
  return (
    <div className='wrap-watches-page p-3'>
      <PeripheralSearch setPeripheralData={setPeripheralData} />
      <div className='p-3'>
        {
          peripheral && <div>
            <h3>Watch Data:</h3>
            <div className='mb-7 watch-data-table'>
              <table className='w-75 table table-striped'>
                <tbody>
                  {Object.keys(peripheral).map(key => (
                    <tr key={key}>
                      <th width={200}>{key}:</th>
                      <td>{peripheral[key]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <hr />
          </div>
        }
        {
          peripherlVersions && <div>
            <h3>Watch versions:</h3>
            <div className='mb-7 watch-data-table table-fix-head'>
              <table className='w-75 table table-striped'>
                <thead>
                  <tr>
                    <th>fota version</th>
                    <th>fota updated at</th>
                  </tr>
                </thead>
                <tbody>
                  {peripherlVersions.map((ver, i) => (
                    <tr key={i}>
                      <td>{ver.fw_version}</td>
                      <td>{ver.version_updated}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <hr />
          </div>
        }
        {
          peripheralPatients && <div>
            <h3>Watch connections:</h3>
            <div className='watch-data-table table-fix-head'>
              <table className='w-75 table table-striped'>
                <thead>
                  <tr>
                    <th>patient ID:</th>
                    <th>Name:</th>
                    <th>Paired at:</th>
                    <th>Unpaired at:</th>
                  </tr>
                </thead>
                <tbody>
                  {peripheralPatients.map((pat, i) => (
                    <tr key={i}>
                      <td>{pat.patient_id}</td>
                      <td>{pat.patient_name}</td>
                      <td>{pat.paired_at}</td>
                      <td>{pat.unpaired_at}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        }
        {
          patientPeripherals && <div>
            <h3>Patient watches:</h3>
            <div className='watch-data-table table-fix-head'>
              <table className='table table-striped'>
                <thead>
                  <tr>
                    <th>patient ID:</th>
                    <th>Name:</th>
                    <th>Peripheral ID:</th>
                    <th>Peripheral name:</th>
                    <th>Paired at:</th>
                    <th>Unpaired at:</th>
                  </tr>
                </thead>
                <tbody>
                  {patientPeripherals.map((pat, i) => (
                    <tr key={i}>
                      <td>{pat.patient_id}</td>
                      <td>{pat.patient_name}</td>
                      <td>{pat.peripheral_id}</td>
                      <td>{pat.peripheral_name}</td>
                      <td>{pat.paired_at}</td>
                      <td>{pat.unpaired_at}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default Watches