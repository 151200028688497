import React, { useState } from 'react'
import { isKeyValueObject } from 'utils'
import { convertSpaceToUnderScore, convertUnderScoreToSpace } from 'utils/formaters'
const CONFIG_OPTIONS = ['new object', 'on', 'off', 'watch', 'manual', 'external']

function AppConfigEdit({ configurations, setConfigurations, isEdit }) {

    const [newConfig, setNewConfig] = useState({})

    const handleSubmit = async (e) => {
        e.preventDefault()
    }

    const saveNewConfig = (path) => {
        const {key, value} = newConfig[path]
        const newPath = path ? `${path}/${key}` : key
        updateConfigurations(newPath, value)
        setNewConfig(prev => {
            delete prev[path]
            return prev
        } )
    }

    const deleteConfig = () => {

    }

    const updateNewConfig = (path, key, value) => {
        const confKey = key || ''
        const confVal = value || newConfig[path]?.value || ''
        setNewConfig(prev => ({ ...prev, [path]: { key: convertSpaceToUnderScore(confKey)?.toLowerCase(), value: confVal } }))
    }

    const updateConfigurations = (path, confValue) => {
        if(confValue === 'new object') confValue = {}
        const keys = path.split('/');
        const lastKey = keys.pop();
        const _configurations = {...configurations}
        const target = keys.reduce((acc, key) => (acc[key] = acc[key] || {}), _configurations);
        target[lastKey] = confValue;
        setConfigurations(prev => ({ ...prev, configuration: _configurations}));    
    }

    const deleteConfField = (confFields, key) => {
        setConfigurations(prev => {
            const confForDelete = { ...prev }
            delete confForDelete.configuration[confFields][key]
            return confForDelete
        })
    }

    const getAddConfirmForm = (path) => {
        return (
            <div className='form-group d-flex'>
                <div className='col-4 p-0'>
                    <p className='m-0'>Config name:</p>
                    <input className='form-control' name='key' value={newConfig[path]?.key} onInput={(e) => updateNewConfig(path, e.target.value)} />
                </div>
                <div className='pl-2 col-7'>
                    <p className='m-0'>Config value:</p>
                    {configOptionsSelect(null, newConfig[path]?.key, path, true)}
                </div>
                <button disabled={!newConfig[path]?.key || !newConfig[path]?.value} onClick={() => saveNewConfig(path)} className='btn btn-primary col-4 h-60 align-self-end ml-n6 m-0'>Add config</button>
            </div>
        )
    }

    const configOptionsSelect = (confVal, key, path, isNewConf) => {
        return (
            <select onChange={(e) => isNewConf ? updateNewConfig(path, key, e.target.value) : updateConfigurations(path, e.target.value)} disabled={!isEdit} className='col-8 form-control' value={isNewConf ? newConfig[path]?.value : confVal || ''}>
                <option disabled hidden value='' key='select'>select...</option>
                {CONFIG_OPTIONS.map(co => <option value={co} key={co}>{co}</option>)}
            </select>
        )
    }

    const renderConfigFields = (confKey, confVal, path) => {
        return (
            <div className='ml-5 w-50'>
                <div>
                    <form onSubmit={handleSubmit} className='form'>
                        <div key={confKey} className='form-group col-8 d-flex align-items-center row'>
                            <span className='p-0 col'>{convertUnderScoreToSpace(confKey)}: </span>
                            {configOptionsSelect(confVal, confKey, path)}
                            {/* {isEdit && <span className='pl-1 cursor-pointer' onClick={() => deleteConfField(conf, key)}>&#x274C;</span>} */}
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    const getSortedConfKeys = (element) => {
        //sort objects to end
        return Object.keys(element).sort((a, b) => +isKeyValueObject(element[a]) - +isKeyValueObject(element[b]))
    }

    const recursiveRenderConfig = (element, path) => {
        return getSortedConfKeys(element).map(conf => {
            const newPath = path ? `${path}/${conf}` : conf
            if(!isKeyValueObject(element[conf])) {
                return renderConfigFields(conf, element[conf], newPath)//<p className='ml-5'>{`${conf}: ${element[conf]}`}</p> 
            }

            return (
                <div className='ml-5 mt-5'>
                    <h3>{newPath}:</h3>
                    <div className='config-actions row mb-3 ml-5'>
                        {!newConfig[newPath] && <button disabled={!isEdit} onClick={() => updateNewConfig(newPath)} className='btn btn-secondary mr-2'>
                            Add {conf || 'configuratio'} field
                        </button>}
                        {newConfig[newPath] && getAddConfirmForm(newPath)}

                    </div>
                    {recursiveRenderConfig(element[conf], newPath)}
                </div>
            )
        })
    }

    return (
        <>
            <div className='wrap-config-edit '>
                <div className='card w-50'>
                    <h3>Add configurations Object</h3>
                    {getAddConfirmForm()}
                </div>
                {recursiveRenderConfig(configurations, '')}

            </div>
        </>
    )
}

export default AppConfigEdit