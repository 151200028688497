import React from 'react';

const HrGraphBackgroundComponent = ({linesNumber, color, children}) => {
    //console.log('ffffff',linesNumber,color,children)

  const _buildBackgroundLines = (linesNumber, color) => {
    const lines = [];

    for (let i = 1; i <= linesNumber; i++) {
      lines.push(
        <div style={{height:'100%',width:1,opacity:0.15,backgroundColor:'red',overflowX:'hidden'}}
          key={i}
        />,
      );
    }
    return lines;
  }

  return (
    <div style={{flex:1,position:'relative',backgroundColor:'transparent'}}>
      <div style={{flex:1,flexDirection:'row',justifyContent:'space-evenly'}}>
        {_buildBackgroundLines(linesNumber, color)}
      </div>
      <div style={{position:'absolute',top:0,right:0,bottom:0,left:0}}>{children}</div>
    </div>
  );
}

export default HrGraphBackgroundComponent;
