import { initialMesutments } from "consts"
import _ from "lodash"
import { SET_MEASUREMENT_REMIDERS } from "./action-types"
import { mergeStateDataByPatients } from "store/store-utils"

export function reducer(state, action){
    const {type,payload} = action
    switch(type){
        case 'SET_MONTHLY_DATA':
            let {date, data, patientId} = payload
            const oldData = state.monthlyData?.[patientId]?.[date]
            const newData = Object.assign({},oldData,data)
            let newMonthlyData = Object.assign({},state.monthlyData, {[patientId]: {...state.monthlyData?.[patientId], [date]: newData }})
            return Object.assign({},state, {monthlyData:newMonthlyData})
        case 'SET_PATIENTS':
            return Object.assign({},state, {patients: payload.patients})
        case 'SET_UPDATE_PATIENTS':{
            const filteredPatients = state.patients?.filter(p => p.id !== payload.patient.id)
            return Object.assign({},state, {patients: [...filteredPatients, payload.patient]})
        }
        case 'SET_DAILY_DATA':
            return Object.assign({},state, {dailyData:{...state.dailyData, [payload.patientId]:{...state.dailyData[payload.patientId], [payload.date]: payload.data}}})
        case 'SET_PATIENTS_CONFIGURATION':
            return Object.assign({},state, {patientsConfiguration:{...state.patientsConfiguration, [payload.patientId]: payload.configurations}})
        case 'SET_DATE':
            return Object.assign({},state, {selectedDate:payload})
        case 'SET_USER_DATA':
            return Object.assign({},state, {user:payload})
        case 'SET_UNSAVED_CHANGES':
            return Object.assign({},state, {unsavedChanges:payload})
        case 'SET_PATIENT_MEASURMENTS':{
            let newMeasurmentsData = payload
            const oldMeasurments = {...state.patientMeasurments}
            const newMeasurments = _.merge(oldMeasurments,newMeasurmentsData)
            return newMeasurmentsData ? Object.assign({},state, {patientMeasurments:newMeasurments}) : state
        }
        case 'SET_PATIENT_ALERTS':{
            let newAlertsData = payload
            const oldAlerts = {...state.patientAlerts}
            return !(_.isEmpty(oldAlerts)  && _.isEmpty(newAlertsData) ) ? Object.assign({},state, {patientAlerts:newAlertsData}) : state
        }
        case 'SET_CONNECTION_ID':{
            return {...state, connectionId: payload}
        }
        case 'SET_PATIENT_CHANNELS':{
            return {...state, patientsChannels: payload}
        }
        case 'SET_PATIENTS_SOCKET_DATA':{
            return {...state, socketPatientsData: {...state.socketPatientsData, ...payload}}
        }
        case 'SET_REALTIME_SOCKET_DATA':{
            return {...state, socketRealtimeData: {...state.socketRealtimeData, ...payload}}
        }
        case 'SET_MEDICATIONS_DATA':{
            return {...state, patientsMedication: {...state.patientsMedication, ...payload}}
        }
        case SET_MEASUREMENT_REMIDERS:{
            return {...state, patientsMeasurementReminders: {...state.patientsMeasurementReminders, ...payload}}
        }
        case 'SET_DB_MEASUREMENTS':{
            return {...state, measurementsFromDB: mergeStateDataByPatients(state.measurementsFromDB, payload)}
        }
        case 'SET_PHYSICIANS':{
            return{...state,physicians:[...payload]}
        }
        case 'SET_COUNTRIES':{
            return{...state, countries: payload}
        }
        case 'SET_VERSIONS':{
            return{...state, versions: payload}
        }
        case 'SET_APP_CONFIGURATIONS':{
            return{...state, appConfigurations: payload}
        }
        case 'SET_FOTA_LIST':{
            return{...state, fotaList: payload}
        }
        default: return state
    }
  }
    
export const initialState ={
    monthlyData: {},
    patients: [],
    dailyData: {},
    selectedDate: new Date(),
    patientMeasurments: initialMesutments({}),
    patientAlerts:{},
    user:{},
    connectionId: null,
    patientsChannels:{},
    socketPatientsData:{},
    socketRealtimeData:{},
    patientsConfiguration: {},
    physicians:[],
    countries:[],
    versions:[],
    patientsMedication: {},
    patientsMeasurementReminders: {},
    measurementsFromDB: {},
    checked:true,
    appConfigurations: [],//all configurations
    fotaList: []
  }