import { Modal } from "reactstrap"
import './modals.scss'

export default function EmptyReportModal({routeBack}) {
  
  return (
    <Modal
      className="empty-report-modal modal-dialog-centered"
      backdropClassName="empty-report-modal-bg"
      size="md"
      isOpen={true}
    >
      <div className="modal-header d-block text-center">
        <span className="text-center">Sorry</span>
      </div>
      <div className="modal-body">
        <span>
          {`There are no measurement for the report time frame, \n
          please wear the watch for few days before creating a monthly report.`}
        </span>
      </div>
      <div className="modal-footer">
        <a className="btn btn-primary" href={routeBack}>
            Back to Reports
        </a>
      </div>
    </Modal>
  )
}
