import { changePasswordForUser } from 'api/admin-api'
import { VALID_PASSWORD_REGEX } from 'consts'
import React, { useEffect, useState } from 'react'
import { Modal } from 'reactstrap'

function ChangeUserPassword({userId}) {
    const [isEditPass, setIsEditPass] = useState()
    const [password, setPassword] = useState('')
    const [errorConfirm, setErrorConfirm] = useState()

    useEffect(() => {
        checkPass()
    }, [password])

    const handelSubmit = async (e) => {
        e.preventDefault()
        console.log('password', password)
        await changePasswordForUser(userId, password, true)
        setPassword('')
        setIsEditPass()
    }

    const checkPass = () => {
        if(password && !VALID_PASSWORD_REGEX.test(password)) {
            return setErrorConfirm('invalid password please follow the Password policy')
        } else{
            setErrorConfirm()
        }
    }

  return (
    <div>
        <button onClick={() => setIsEditPass(!isEditPass)} className='btn btn-success mr-2'>
            change user's password
        </button>
        {isEditPass && 
            <Modal
                style={{maxWidth: '600px'}}
                className="modal-dialog-centered"
                isOpen={true}
                toggle={()=> setIsEditPass()}
            >
                <form className='text-center'>
                    <h2 className='text-danger'>Be shure that you want to change for a uesr his password.</h2>
                    <h2>Enter a new valid password</h2>
                    <input placeholder='password' className='form-control w-75 m-auto' onChange={(e) => setPassword(e.target.value)} value={password} />
                    <div className='text-danger'>{errorConfirm}</div>
                    <button disabled={errorConfirm || !password} onClick={handelSubmit} className='btn btn-dark m-2'>
                        Save user's password
                    </button>
                </form>
            </Modal>
        }
    </div>
  )
}

export default ChangeUserPassword