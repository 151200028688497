import React from 'react'

function BatteryIconComponent({percent=0, fill='#1d3378', width=13, height=21}) {
    const contentHeight = height - height/7
    const contentWidth = width - width/10
    const fillHeight = contentHeight / 100 * percent

    const svg = <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g id="Group_2361" data-name="Group 2361" transform="translate(-356.261 -554.071)">
      <rect id="Rectangle_2" data-name="Rectangle 2" width={fillHeight} height={contentWidth} rx="0" transform="translate(357.011 574.15) rotate(-90)" fill={fill} stroke={fill} stroke-width="0"/>
      <rect id="Rectangle_2" data-name="Rectangle 2" width={contentHeight} height={contentWidth} rx="2" transform="translate(357.011 574.643) rotate(-90)" fill="none" stroke="#1d3378" stroke-width="1.5"/>
      <path id="Path_324" data-name="Path 324" d="M.547,0H-.114V6.835H.564a.871.871,0,0,0,.866-.873L1.417.869A.87.87,0,0,0,.547,0" transform="translate(359.68 555.501) rotate(-90)" fill="#1d3378" fill-rule="evenodd"/>
    </g>
  </svg>
  
  return (
    <span className='battery-icon'>
        {svg}
    </span>
  )
}

export default BatteryIconComponent