import React from 'react'
import pendingImg from "../../../assets/img/theme/doctor-pending.png"
function DoctorPendingScreen() {

  return (
      <div className='pending-section card row'>
        <div className='pending-content p-2 text-center'>
          <h1 className='pending-title'>Your application for registration is pending <br />An email will be sent to you once you will be cleared</h1>
          <img src={pendingImg} />
        </div>
      </div>
  )
}

export default DoctorPendingScreen