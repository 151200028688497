
import React,{useEffect} from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import PatientNavbar from "components/Navbars/PatientNavbar.js";
import PatientFooter from "components/Footers/PatientFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import '../components/admin/admin.scss'

const AdminLayout = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {

      const [upperLayout, innerLayout] = prop.layout.split('/').filter(layout=>layout)
      if(upperLayout === "admin" && innerLayout){
        return (
          <Route
            path={prop.path}
            component={()=> <prop.component user={props.user.data} setUser={props.setUser} jwtToken={props.user.jwtToken} />}//<ProfileWrapper/>}
            key={key}
          />
        );
      } 
      
       if (prop.layout === "/admin") {

        return (
          <Route
            exact path={prop.path}
            component={()=> <prop.component setUser={props.setUser} user={props.user} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <PatientNavbar {...props}  display={true} />
      <Sidebar {...props} routes={routes} display={true} />
      <div className="main-content" ref={mainContent}>
        <Container fluid>
        <div style={{height:'93px'}} />
        </Container>
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/patients" />
        </Switch>
        <Container fluid>
          <PatientFooter />
        </Container>
      </div>

      </>
  );
};
export default AdminLayout;