import moment from 'moment';
import validator from 'validator';

const languges = validator.isAlphaLocales

export const isValidEmail = (email) => {
    
}
export const isValidPhoneNumber = (phoneNumber) => {
    return validator.isMobilePhone(phoneNumber)
}

export const isValidString = (string) => {
   
}

export const isValidPostalCode = (postalCode) => {
    return validator.isPostalCode(postalCode, 'any')
}

export const isValidVersion = (str) => {
    //checks if a string is a version in this format:  num.num.num - 1.0.0 / 10.100.1000
    const versionPattern = /^\d+\.\d+\.\d+$/;
    return versionPattern.test(str);
}


export const isPassedFromMidnight = (date) => {
    if (!date) {
      return true;
    }
    return moment().startOf('day').isAfter(date);
};
  