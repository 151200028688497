import React, { useState } from 'react'

function PasswordRestrictions() {
    const [togglePolicy, setTogglePolicy] = useState(0)

    return (
        <div>
            <div onClick={() => setTogglePolicy(1 - togglePolicy)} style={{ cursor: 'pointer' }} >
                Password policy
                <img alt="expand"
                    src={require(`../../assets/img/icons/Expand.svg`).default}
                    style={{ height: '15px', marginLeft: '5px', transform: togglePolicy ? 'rotate(180deg)' : undefined }} />
            </div>
            <div style={{ display: togglePolicy ? 'block' : 'none', marginLeft: '5px', fontSize: 'smaller' }} >
                <div>At least 9 characters</div>
                <div>At least one capital letter [A-Z]</div>
                <div>At least and one small letters [a-z]</div>
                <div>At least one alphanumeric character [0-9]</div>
                <div>{'At least one special character [!@#$%^&*}]'}</div>
            </div>
        </div>
    )
}

export default PasswordRestrictions