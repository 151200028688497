import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Button, Col, Container, DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupAddon, InputGroupText, Row, UncontrolledDropdown } from 'reactstrap'
import { setUnsavedChanges } from 'store/actions'
import axios from 'axios'
import Cookies from 'js-cookie'
import { DOCTOR_PREFIX, DOCTOR_TYPE, DOCTOR_INFO_FIELDS as fields } from 'consts'
import Edit from '../../assets/img/icons/Edit.svg'
import { convertKeysToCamelCase } from 'utils'
import SubNavbar from 'components/Navbars/SubNavbar'
import { useHistory } from "react-router-dom";
import './doctor-info.scss'
import RadioButtonComponent from 'components/Inputs/radio-button.component'
const MAX_CHARS_LIMITS = 24

export function DoctorInfo({ user, setUser, unsavedChanges, setUnsavedChanges }) {
    const jwtToken = user.jwtToken

    const [data, setData] = useState(user?.data)
    const [inputError, setInputError] = useState(null)
    const [saveError, setSaveError] = useState()
    const [save, setSave] = useState(false)
    const [fieldToEdit, setFieldToEdit] = useState()
    const history = useHistory()

    useEffect(() => {
        if (inputError === null) return
        (inputError && inputError !== '') ? setUnsavedChanges(null, false) : setUnsavedChanges(handleSave, true)
    }, [data])

    const handleSave = async () => {
        axios.post(`${process.env.REACT_APP_API_URL}/app/doctor/update-doctor-information`,
            data, { headers: { 'Authorization': `Bearer ${jwtToken}` } })
            .then(() => {
                setUser({ data: { ...user.data, ...convertKeysToCamelCase(data) }, type: 'doctor', jwtToken })
                Cookies.set('user', JSON.stringify({ data: { ...user.data, ...convertKeysToCamelCase(data) }, type: 'doctor', jwtToken }), { expires: 1 / 24 })
                setUnsavedChanges()
            })
            .catch(err => {
                console.log('err', err)
                const { data } = err.response
                const { message } = data
                setSaveError(message)
            })
    }

    /**
     * This function only has to perform client side validation. Actual
     * data conversions take place in the server.
     */
    const handleChange = (value, key) => {
        if (key !== "Date of birth" && String(value).length > MAX_CHARS_LIMITS) {
            setInputError('max characters exceed')
            return
        } else {
            setData(prevData => ({ ...prevData, [key]: value }))
        }
        setSave(true)
    }

    const renderRadioInput = (action) => {
        let options = {
            type: DOCTOR_TYPE,
            prefix: DOCTOR_PREFIX
        }
        return <RadioButtonComponent name={action} options={options[action]} checkedOption={data[action]} onChange={handleChange} />
    }

    const handleEditButtonClick = (action, e) => {
        e.preventDefault();
        setFieldToEdit(action)
        inputError && setInputError()
    }

    const renderEditInput = (field) => {
        if(fields[field].type === 'radio') return renderRadioInput(field)
        return (
            <input 
                className='edit-profile-input'
                autoFocus 
                type={fields[field].type}
                value={data[field]}
                onChange={e => handleChange(e.target.value, field)}
            />
        )
    }

    const editButton = (action) => fields[action].editble &&
        <UncontrolledDropdown>
            <div className='cursor-pointer' onClick={e => handleEditButtonClick(action, e)} >
                <img alt="edit" src={Edit} width="25px" />
            </div>
        </UncontrolledDropdown>


    const changePassword = () => {
        history.push({
            pathname: 'profile/passwords',
            state: { isOpen: true }
        })
    }

    const handleDeleteAccount = () => {
        history.push('profile/account-deletion')
    }

    const isNewColumn = (rowIndex) => {
        const indexToBreak = Math.ceil(Object.keys(fields).length / 2)
        if (indexToBreak === rowIndex) return 'column-break'
        return ''
    }

    return (<>
        <SubNavbar title={'My Profile'} subPages={['My Profile']} />
        <Container fluid>
            <div className="mt-3 profile-content-container">
                <p className='edit-profile-title'>Edit your personal info</p>
                <div className='personal-fields'>
                    {Object.keys(fields).map((key, i) => {
                        return (
                            <Row key={key} className={`personal-info-row ${fields[key].editble ? 'row-editble' : ''} ${key === fieldToEdit && 'active'} ${isNewColumn(i)}`}>
                                <Col style={{ color: '#000', fontWeight: 200 }} > {fields[key].title} </Col>
                                <Col>{key === fieldToEdit ? renderEditInput(key) : data[key]} </Col>
                                <Col xl="1" >
                                    {editButton(key)}
                                </Col>
                            </Row>
                        )
                    })}
                </div>
                <div className='edit-profile-actions'>
                    <small style={{ color: '#EF3054', padding: '2px 5px' }}>{saveError?.join(', ')}</small>
                    {save && !inputError && <Button className="btn-save"
                        onClick={handleSave}>
                        Save
                    </Button>}
                    <div className='break-line-flex'></div>
                    <p onClick={changePassword} className='theme-link'>Change Password</p>
                    <span className='edit-actions-divider'>|</span>
                    <p onClick={handleDeleteAccount} className='theme-link'>Account Deletion</p>
                </div>
            </div>
        </Container>
    </>
    )
}


const mapStateToProps = ({ unsavedChanges }) => ({ unsavedChanges })
const mapDispatchToProps = { setUnsavedChanges }
export default connect(mapStateToProps, mapDispatchToProps)(DoctorInfo)