/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import _ from 'lodash'
import { Container, Row, Col, Button } from "reactstrap";
import useWebSocket from 'react-use-websocket';
import { MeasureBox, MassagesBox, NotificationsBox, ChartBox } from 'components/Cards'
import SubNavbarDoctor from "components/Navbars/SubNavbarDoctor";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import MonthlyChart from "components/Charts/Monthly";
import DoctorsPatientBar from "components/Navbars/DoctorsPatientBar";
import { useParams } from "react-router-dom";
import moment from "moment";
import { setMonthlyData } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import Bell from '../../assets/img/icons/bell.svg'
import axios from "axios";
import CaloriesAndSteps from "components/Cards/calories-and-steps";
import { UNIT_KEYS, USER_TYPES, setupMesutments, REALTIME_MESSAGE_ACTION, MESSAGE_TYPE, MESSAGE_PLATFORM, MONTHLY_CHARTS_TITLES } from "consts";
import { getFirstDayInMonth } from "utils";
import Realtime from '../../components/Modals/Realtime'
import DoctorAlerts from "components/Modals/DoctorAlerts";
import { useReactToPrint } from 'react-to-print';
import { DASHBOARD_FIRST_ROW } from "consts";
import { DASHBOARD_SECOND_ROW } from "consts";
import { getPatientAppConfig } from "api/api-manager";
import { DailyChart } from "@cardiacsense/report-templates-npm";
import { emptyMonthlyData } from "variables/charts";

const DoctorsPatient = () => {
  const { patientId, subLocation } = useParams()
  const { user, patients, connectionId, patientsChannels, patientsConfiguration, monthlyData, measurementsFromDB } = useSelector(state => state)
  const configurations = patientsConfiguration[patientId]
  const patient = patients.find(p => p.id === +patientId);
  const dispatch = useDispatch()
  const patientMeasurments = useSelector(state => state?.socketPatientsData?.[patientId])
  const measurments = setupMesutments(patientMeasurments, measurementsFromDB[patientId], patient)
  const onAlert = measurments?.onAlert
  delete measurments?.onAlert
  const [showPopUpAlert, setShowPopUpAlert] = useState(false)
  const [monthlyCharts, setMonthlyCharts] = useState([])
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
  const [alertsModal, setAlertsModal] = useState()
  const { sendMessage } = useWebSocket(`${process.env.REACT_APP_SOCKET_URL}?id=${user.data.id}&user_type=${user.type}&platform=portal&jwtToken=${user.jwtToken}`, { share: true });
  const graphClick = (date) => setDate(moment(date).format('YYYY-MM-DD'))
  const units = _.mapValues(measurments, ({ unit }) => unit)
  const localPatientId = localStorage.getItem('currentPatientId');
  const [startRealtime, setStartRealtime] = useState(false)
  const chartBoxRef = useRef()

  useEffect(() => {
    if (!patient || subLocation !== 'Dashboard') return
    const fetchData = async () => {
      if (configurations && patientId === localPatientId) return
      localStorage.setItem('currentPatientId', patientId)

      if(!configurations){
        getPatientAppConfig(patient.id)
      }
      getPatientMonthly()
    }
    fetchData()
    if (patient?.alerts.length) {
      setAlertsModal(patient?.alerts)
}
  }, [patient])

  useEffect(() => {
    localStorage.setItem('selectedPatient', JSON.stringify(patient))
  }, [patient])

  useEffect(() => {
    if (startRealtime) {
      try {
        if (connectionId) {
          sendMessage(JSON.stringify({
            type: MESSAGE_TYPE.REALTIME,
            action: REALTIME_MESSAGE_ACTION.REQUEST_START,
            from: {
              source_id: user.data.id,
              source_type: user.type,
              connection_id: connectionId,
              platform: MESSAGE_PLATFORM.PORTAL,
            },
            to: [{ channel_id: patientsChannels[patient.id], target_type: USER_TYPES.PATIENT, target_id: patient.id, platform: MESSAGE_PLATFORM.MOBILE }],
          }))
        }
      } catch (err) {
        console.log('err', err)
      }
      axios.post(`${process.env.REACT_APP_API_URL}/app/notifications/request-realtime`, { patientId: +patientId ,doctorName: `${user.data.firstName} ${user.data.lastName}`}, { headers: { 'Authorization': `Bearer ${user.jwtToken}` } })
        .then(() => {
          console.log('notification sent to patient')
        })
        .catch(err => {
          console.log('error fetching monthly data 1', err)
        })
    }

  }, [startRealtime]);

  function getPatientMonthly() {
    const day = getFirstDayInMonth()
    if(monthlyData?.[patientId]?.[day]) return
    axios.post(`${process.env.REACT_APP_API_URL}/app/doctor/get-patient-monthly`, { day, patientId: +patientId }, { headers: { 'Authorization': `Bearer ${user.jwtToken}` } })
      .then(res => {
        const data = res.data || emptyMonthlyData(day)
        dispatch(setMonthlyData(data, day, false, patientId))
      })
      .catch(err => {
        console.log('error fetching monthly data 1', err)
        dispatch(setMonthlyData({}, day, false, patientId))
      })
  }

  const handleBoxClick = (title) => {
    const chartIndex = monthlyCharts.findIndex(chartTitle => chartTitle === title)
    if (chartIndex > -1) {
      const removed = [...monthlyCharts]
      removed.splice(chartIndex, 1)
      setMonthlyCharts(removed)
    } else {
      setMonthlyCharts(prev => [title, ...prev])
    }
  }

  const handlePrint = useReactToPrint({
    content: () => chartBoxRef.current,
  });

  if (!patient) return (
    <div className="lead text-center w-100" >
      <div style={{ height: '93px' }} />
    </div>
  )

  const openModal = () => {
    setStartRealtime(true)
  }
  
  const getInnerContentVal = (item) => {
    const key = measurments[item]?.innerContent?.key
    if(!key) return
    return measurementsFromDB[patientId]?.[key]
  }

  const isConfigOff = (item) => {
    const key = measurments[item]?.configKey || measurments[item]?.key || measurments[item]?.title?.toLocaleLowerCase().replace(' ','_')
    return configurations?.configuration?.measurements?.[key] === 'off'
  }

  return (
    <div>
      {startRealtime &&
        <Realtime toggleModal={setStartRealtime} patient={patient} onAlert={onAlert} configurations={configurations}/>
      }
      {patient?.alerts.length > 0 && <DoctorAlerts alerts={alertsModal} patient={patient} toggleModal={setAlertsModal} user={user} alertsOrRemind={'alerts'} isRealtime={startRealtime} />}
      {showPopUpAlert && <DoctorAlerts alerts={patient?.remindAlerts} patient={patient} toggleModal={setShowPopUpAlert} user={user} alertsOrRemind={'remindAlerts'} />}
      <DoctorsPatientBar patient={patient} />
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20, marginLeft: 20, marginRight: 20 }}>
        <Button outline color="blue" type="button" style={{ padding: '0.325rem 1.05rem', borderRadius: 35, cursor: 'pointer' }} onClick={openModal}>
          Request Realtime measurement
        </Button>
        {!!patient?.remindAlerts?.length && <img style={{ cursor: 'pointer', right: 0 }} src={Bell} onClick={() => setShowPopUpAlert(true)} alt='remind me' />}
      </div>
      <SubNavbarDoctor appConfigs={configurations} subLocation={subLocation} patient={patient} jwtToken={user?.jwtToken} />
      {subLocation !== 'Dashboard' || (!configurations) ?
        null
        : <div ref={chartBoxRef}>
          <Container fluid style={{ maxWidth: '2000px' }} >
            <Row className="mt-3" style={{ paddingLeft: '10px' }} >
              <Col xl="9">
                <Row>
                  <Col style={{ padding: '0px 4px' }}>
                    <NotificationsBox alerts={onAlert} />
                  </Col>
                  {
                  DASHBOARD_FIRST_ROW.map((item) => 
                    <Col key={measurments[item]?.title} style={{ padding: '0px 4px' }} >
                      <MeasureBox
                        {...measurments[item]}
                        onClick={handleBoxClick} 
                        disabled={isConfigOff(item)}
                        innerContentVal={getInnerContentVal(item)}
                      />
                    </Col>
                  )}
                </Row>
                <Row className="mt-2">
                 { DASHBOARD_SECOND_ROW.map((item) => 
                 <Col key={measurments[item]?.title} style={{ padding: '0px 4px' }} >
                      <MeasureBox 
                        {...measurments[item]}
                        onClick={handleBoxClick} 
                        disabled={isConfigOff(item)} 
                      />
                    </Col>
                 )}
                </Row>
              </Col>
              <Col xl="3">
                <CaloriesAndSteps patientId={+patientId} steps={measurments.Steps} calories={measurments.Calories} configurations={configurations?.configuration} />
                <MassagesBox readyState={0} user={user} />
              </Col>
            </Row>

            <TransitionGroup>
              {monthlyCharts.map(title =>
                <CSSTransition key={title} timeout={500} classNames="chart-card">
                  <ChartBox key={title} title={`Monthly ${MONTHLY_CHARTS_TITLES[title] || title}`} patientId={+patientId} token={user?.jwtToken} handlePrint={handlePrint} role={user?.type} >
                    <MonthlyChart
                      thresholds={patient.thresholds}
                      unit={patient[UNIT_KEYS[title]]}
                      type={title} onClick={graphClick}
                      patientId={patientId}
                      statusThresholds={configurations?.configuration?.alerts?.thresholds}
                    />
                  </ChartBox>
                </CSSTransition>
              )}
            </TransitionGroup>

            <ChartBox title={'Daily Chart'} role={user?.type} patientId={+patientId} token={user?.jwtToken} tmp2={date} handlePrint={handlePrint} >
              <DailyChart configurations={configurations?.configuration} units={units} />
            </ChartBox>
          </Container>
        </div>
      }
    </div>
  )
};
export default DoctorsPatient;