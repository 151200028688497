import React from 'react'
import reportIcon from "../../../assets/img/icons/PDF.svg"
import './report-icon.scss'

function ReportIconComponent({isAlert}) {
  return (
    <span className='pdf-icon'>
        <img src={reportIcon} alt='report' />
        {isAlert && <span className='pdf-alert-sign'>!</span>}
    </span>
  )
}

export default ReportIconComponent