import axios from 'axios'
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { timeZoneConvert } from 'utils';
import { AfReportComponent, MonthlyTempReportComponent, BpReportComponent, PwvReportComponent, DailyReportComponent } from '@cardiacsense/report-templates-npm';
import './daily-report.scss'
import EmptyReportModal from 'components/Modals/empy-report-modal';

const apiEndPoints = {
    doctor: {
        'monthly-report': 'doctor/get-patient-monthly-report',
        'af-report': 'doctor/get-patient-monthly-report', //for now we using the same data (Danny)
        'blood-pressure-report': 'doctor/get-patient-bp-report',
        'pwv-report': 'doctor/get-patient-pwv-report',
    },
    patient: {
        'monthly-report': 'measurements/get-monthly-report',
        'af-report': 'measurements/get-monthly-report', //for now we using the same data (Danny)
        'blood-pressure-report': 'measurements/get-bp-report',
        'pwv-report': 'measurements/get-pwv-report',
    },
}

export default function StaticReports(props) {
    const role = props.user.type
    const isDoctor = role === 'doctor'
    const patientId = localStorage.getItem('currentPatientId')
    const { type, dates: datesParams } = useParams()
    const isAfReport = type === 'af-report'
    const dates = datesParams.split(',').map(date => new Date(date))
    const [onLoad, setOnLoad] = useState()
    const [data, setData] = useState([])
    const chartBoxRef = useRef()

    const baseUrl = window.location.origin
    const routeBack = isDoctor ? `${baseUrl}/patient=${patientId}/Reports` : `${baseUrl}/reports/marketplace`

    const fetchDailyData = async () => {
        console.log('dates', dates)
        const correctDate = moment(dates[0]).format('YYYY-MM-DD')
        console.log('correctDate', correctDate)
        setOnLoad(true)
        try {
            const endPointApi = isDoctor ? 'doctor/get-patient-daily-report' : 'measurements/get-daily-report'
            const res = await axios.post(
                `${process.env.REACT_APP_API_URL}/app/${endPointApi}`,
                { day: correctDate, patientId },
                { headers: { 'Authorization': `Bearer ${props.user.jwtToken}` } }
            ).then(res => res.data)
            setOnLoad(false)
            setData(res.measurementsData)
        } catch (err) {
            console.log(err)
            setOnLoad(false)
        }
    }

    const fetchData = async () => {
        setOnLoad(true)
        try {
            const endPointApi = apiEndPoints[role][type]
            const body = { start: moment(dates[0]).startOf('day').format('YYYY-MM-DD HH:mm:ssZ'), patientId, isAfReport }
            if(dates[1]) body.end = moment(dates[1]).endOf('day').utc().format('YYYY-MM-DD HH:mm:ssZ')
            const res = await axios.post(
                `${process.env.REACT_APP_API_URL}/app/${endPointApi}`, body,
                 {headers: { 'Authorization': `Bearer ${props.user.jwtToken}` }}
            ).then(res => res.data)
            setOnLoad(false)
            setData(res)
        } catch (err) {
            console.log(err)
            setOnLoad(false)
        }
    }

    useEffect(() => {
        if (type === 'daily-report'){
            fetchDailyData()
            return
        }
        fetchData()
    }, [])

    const renderCharts = () => {
        if (type === 'daily-report') {
            return (
                <DailyReportComponent data={data} isPdf={false} />
            )
        }
        if (type === 'monthly-report') {
            if (!data.patientProfile && onLoad ) return <></>
            if (!data.patientProfile && !onLoad ) return <EmptyReportModal routeBack={routeBack} />
            return (
                <MonthlyTempReportComponent data={data} isPdf={false} />
            )
        }
        if (type === 'af-report') {
            if (!data.patientProfile) return <></>
            return (
                <AfReportComponent data={data} isPdf={false} />
            )
        }
        if (type === 'blood-pressure-report') {
            if (!data.patientProfile) return <></>
            return (
                <BpReportComponent data={data} isPdf={false} />
            )
        }
        if (type === 'pwv-report') {
            if (!data.patientProfile) return <></>
            return (
                <PwvReportComponent data={data} isPdf={false} />
            )
        }
        else return ''
    }

    if(!data || !Object.keys(data).length) return <></>
    return (
        <div className={`wrap-${type} wrap-monthly-report wrap-report`} ref={chartBoxRef} style={{ position: 'absolute', width: '99vw', height: '100%', left: 0 }} >
            <div className={`${type}-static-container d-print-none mt-5`} style={{ backgroundColor: 'white', padding: `100px ${type === 'daily-report' ? '2%' : '1.5% 2% 1%'}`, width: '100%' }}>
                {renderCharts()}
            </div>
            {/* the same chart but smaller one, for print mode */}
            {type === 'daily-report' && <div className={`${type}-static-container print-daily-show`} style={{ backgroundColor: 'white', padding: `1% ${type === 'daily-report' ? '2%' : '1.5% 2% 1%'}`, width: '1100px' }}>
                {renderCharts()}
            </div>}
            {onLoad && <div style={{ position: 'fixed', width: '99vw', height: '100vh', backgroundColor: 'white', left: 0, top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{/*to cover the side bar in doctor mode */}
                <object type="image/svg+xml"
                    data={require("../../assets/img/theme/loading-heart.svg").default}
                    style={{ display: onLoad ? 'initial' : 'none', width: '20%', position: 'absolute', inset: 0, margin: 'auto', zIndex: 2 }}>
                    loading
                </object>
            </div>}
        </div>
    )
}
