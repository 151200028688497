import axios from 'axios'
import {store} from '../store'
import { setCountries, setMedicationsData, setPatientsConfiguration, setPhysicians, setMeasurementsFromDB, setUserToState, setMeasurementReminders } from 'store/actions'

const dispatch = store.dispatch
const getJwt = () => store.getState().user?.jwtToken
const getUserType = () => store.getState().user?.type

const lastTimeExec = {}

const isApiDoublleCall = (apiName) => {
    const now = new Date().getTime()
    const diff = now - (lastTimeExec[apiName]||0)
    if(diff < 5000) return true
    lastTimeExec[apiName] = now
    return false
}

export const getSelectedDoctors = async (controllerRoute = 'doctor', patientId) => {
    if(isApiDoublleCall('getSelectedDoctors')) {
        console.log('is double call not fetching getSelectedDoctors()')
        return store.getState().physicians
    }
    const resSelectedDoctors = await axios.get(`${process.env.REACT_APP_API_URL}/app/${controllerRoute}/get-selected-doctors?patientId=${patientId}`, { headers: { 'Authorization': `Bearer ${getJwt()}` } })
    dispatch(setPhysicians(resSelectedDoctors.data))
    return resSelectedDoctors.data
}

export const getRefreshedUserData = async () => {
    //TODO merge this func with login by jwt
    const user = store.getState().user
    const jwtoken = getJwt()
    if(isApiDoublleCall('getRefreshedUserData')) {
        console.log('is double call not fetching getRefreshedUserData()')
        return user
    }
    const result = await axios.post(`${process.env.REACT_APP_API_URL}/app/auth/login-by-jwt`,{jwtoken},{ headers: {'Authorization': `Bearer ${jwtoken}`} })
    const {role, doctor, patient, username, id: userId, token:jwtToken,accessToken} = result.data.data;
    // const appConfigs = (role === 'patient') ? await axios.get(`${process.env.REACT_APP_API_URL}/app/patient/app-configuration-jwt`,{ headers: {'Authorization': `Bearer ${jwtToken}`} }) : {}//its may just in patient case
    const { appConfigs } = user.data
    const patientOrDoctorData = doctor || patient
    if(patientOrDoctorData){
      const getUser = {data:{...patientOrDoctorData,userId,username ,accessToken,appConfigs}, type:role||'patient', jwtToken}
      delete getUser.data.peripheralHistory
      dispatch( setUserToState( getUser ) )
      return getUser
    }
}

const medicationApiUrl = 'https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search';
export const fetchMedicationsApi = async (query) => {
  try {
    const res = await axios.get(
      `${medicationApiUrl}?terms=${query}&ef=STRENGTHS_AND_FORMS&maxList=20`
    );
    return res?.data
  } catch (error) {
    console.log('fetchMedicationsApi() - error', error)
  }
};

export const getPatientMeasurementReminders = async (patientId) => {
    try {
        const jwtoken = getJwt()
        const result = await axios.get(`${process.env.REACT_APP_API_URL}/app/patient/get-measurement-reminders?patientId=${patientId}`,{ headers: {'Authorization': `Bearer ${jwtoken}`} })
        dispatch( setMeasurementReminders( {[patientId]: result.data} ) )
        return result.data
   } catch (error) {
        console.log('getPatientMeasurementReminders() - error:', error)
   }
}

export const addOrUpdateMeasurementReminders = async (patientId, reminders) => {
    try {
        const jwtoken = getJwt()
        const result = await axios.post(`${process.env.REACT_APP_API_URL}/app/patient/update-measurement-reminders`,
            { reminders, patientId, sendSyncNotification: true },{ headers: {'Authorization': `Bearer ${jwtoken}`} })
        dispatch( setMeasurementReminders( {[patientId]: result.data} ) )
        return result.data
   } catch (error) {
        console.log('addOrUpdateMeasurementReminders() - error:', error)
   }
}

export const getPatientMedications = async (patientId) => {
    try {
        const userType = getUserType()
        const jwtoken = getJwt()
        const result = await axios.post(`${process.env.REACT_APP_API_URL}/app/${userType}/get-medications`,{subjectId: patientId},{ headers: {'Authorization': `Bearer ${jwtoken}`} })
        dispatch( setMedicationsData( {[patientId]: result.data.medicationList} ) )
        return result.data.medicationList
   } catch (error) {
        console.log('getPatientMedications() - error:', error)
   }
}

export const addOrUpdateMedications = async (patientId, medications, remindersToDelete, isEditMode) => {
    try {
        const jwtoken = getJwt()
        const result = await axios.post(`${process.env.REACT_APP_API_URL}/app/patient/${isEditMode ? 'update' : 'add'}-medications`,
            { medications, subjectId: patientId, remindersToDelete, sendSyncNotification: true },{ headers: {'Authorization': `Bearer ${jwtoken}`} })
        dispatch( setMedicationsData( {[patientId]: result.data.medicationList} ) )
        return result.data.medicationList
   } catch (error) {
        console.log('getPatientMedications() - error:', error)
   }
}

export const deleteMedications = async (patientId, idList) => {
   try{
    const jwtoken = getJwt()
    const result = await axios.post(`${process.env.REACT_APP_API_URL}/app/patient/delete-medications`,{ idList, subjectId: patientId, sendSyncNotification: true },{ headers: {'Authorization': `Bearer ${jwtoken}`} })
    dispatch( setMedicationsData( {[patientId]: result.data.medicationList} ) )
    return result.data.medicationList
  } catch (error) {
      console.log('getPatientMedications() - error:', error)
  }
};
  
export const getAllCountries = async () => {
  const countries = store.getState().countries
  if(countries.length) return countries
  try {
    const jwtoken = getJwt()
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/app/country/get-all-countries`,{ headers: {'Authorization': `Bearer ${jwtoken}`} })
    dispatch( setCountries( res.data ) )
    return res.data
  } catch (error) {
    console.log('getAllCountries error', error)
   }
}

export const getPatientAppConfig = async (patientId) => {
   try {
      const jwtoken = getJwt()
      const appConfigs = await axios.get(`${process.env.REACT_APP_API_URL}/app/doctor/patient-app-configuration/${+patientId}`, { headers: { 'Authorization': `Bearer ${jwtoken}` } })
      localStorage.setItem('patientConfig', JSON.stringify(appConfigs.data))
      dispatch( setPatientsConfiguration(patientId, appConfigs.data) )
      return appConfigs.data
   } catch (error) {
      console.log('getAllCountries error', error)
   }
  }

export const getMeasurementsFromDB = async (patientId) => {
  try {
    const jwtoken = getJwt()
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/app/measurements/get-last-measurements?patientId=${patientId}`, { headers: { 'Authorization': `Bearer ${jwtoken}` } })
    dispatch( setMeasurementsFromDB( {[patientId]: res.data} ) )
    return res.data
  } catch (error) {
    console.log('getAllCountries error', error)
  }
}

export const downloadArrythReport = async (patientId, rt_session_id) => {
  try {
      const jwtoken = getJwt()
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/app/doctor/get-report-download-url?patientId=${patientId}&rt_session_id=${rt_session_id}`, { headers: { 'Authorization': `Bearer ${jwtoken}` } })
      if(res.data.reportLink) window.location.href = res.data.reportLink
  } catch (error) {
      console.log('downloadArrythReport error', error)
  }
}
  