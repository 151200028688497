import React from 'react'
import { Modal } from 'reactstrap'

function ConfirmModal({text, subText, onConfirm, displayCancelButton=true, onCancel,confirmButtonText=null, displayTopLine ,cancelButtonText=null, titleWheight='normal', contentAlign='start', }) {
  return (
    <>
    <style scoped>{css}</style>
     <Modal
        style={{maxWidth: '600px'}}
        className="modal-dialog-centered"
        isOpen={ true}
        toggle={()=> onCancel(false)}
     >
        <div className={`modal-body text-${contentAlign}`}>
            {displayTopLine && <hr className="divide-line" />}
            <p className={`size-2 text-dark font-weight-${titleWheight}`} >{text}</p>
            {subText && <p className="size-2 text-dark font-weight-bold" >{subText}</p>}
            <hr className="divide-line" />
            <div className="modal-footer p-0">
                <button 
                    className={`modal-btn ${displayCancelButton ? 'delete' : 'cancel'} btn`}
                    type="button" 
                    onClick={onConfirm}>
                    {confirmButtonText|| 'Yes'}
                </button>
                {displayCancelButton &&
                    <button
                        className="modal-btn cancel btn"
                        type="button" 
                        onClick={()=> onCancel(false)}>
                        {cancelButtonText||'No'}
                    </button>
                }
                
            </div>
        </div>
    </Modal>
    </>
  )
}

export default ConfirmModal

const css = `
p{
    white-space: pre-line;
}
.modal-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 80px;
    color: #000000;
    font-size: 1rem;
    font-weight: 500;
    border:solid 2.5px #3873AE;
    border-radius: 30px;
}
.modal-btn.cancel{
    color: #ffffff;
    background-color: #2F659C;
}

.divide-line{
    border-color: #2F659C;
    margin-bottom: 1rem;
}
`