/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { getCountriesAndConfig, getFotaList, updateCountryConfiguration } from 'api/admin-api'
import { useDispatch, useSelector } from 'react-redux'
import { setCountries, setFotaList } from 'store/actions'

function Countries() {
    const { countries, appConfigurations: configs, fotaList, versions } = useSelector(state => state)
    const [highestGeneralFota, setHighestGeneralFota] = useState()
    const [mapVersions, setMapVersions] = useState({})
    const dispatch = useDispatch()

    const fetchData = async () => {
        if(!countries.length) await getCountriesAndConfig()
        if(!fotaList.length) await getFotaList()
    }

    useEffect(() => {
        if(!fotaList.length) return
        const generalHighFota = getDistributedFotas().filter(f => f.countries.includes(null))[0]
        console.log('generalHighFota', generalHighFota)
        setHighestGeneralFota(generalHighFota)       
    }, [fotaList])

    useEffect(() => {
        if(!versions.length) return
        const tempMapVersions = {}
        versions.filter(v => v.active).forEach(row => {
            let { type, os, country } = row;
            if(!country) country = 'all'
      
            tempMapVersions[type] = tempMapVersions[type] || {};
            tempMapVersions[type][os] = tempMapVersions[type][os] || {};
            tempMapVersions[type][os][country] = tempMapVersions[type][os][country] || row;
            tempMapVersions[type][os][country] = getHighestVersion([row, tempMapVersions[type][os][country]], 'release');
          });
        setMapVersions(tempMapVersions)       
    }, [versions])

    useEffect(() => {
        fetchData()
    }, [])

    const getDistributedFotas = () => {
        const distributedFotas = fotaList.filter(f => f.fota_distribue_id)
        return distributedFotas
    }

    const getHighestVersion = (versions, sortField) => {
        return versions?.sort((a, b) => b[sortField].localeCompare(a[sortField], undefined, { numeric: true, sensitivity: 'base' }))?.[0]
    }

    const getCountryFota = (countryCode) => {
        const allFotaOfCountry = getDistributedFotas().filter(f => f.countries.includes(countryCode))

        const higestFotaCountry = getHighestVersion(allFotaOfCountry, 'fw_version')
        let fw_version = higestFotaCountry?.fw_version 
        if(!fw_version){
            fw_version = highestGeneralFota?.fw_version
        }
        return fw_version
    }

    const getConfigsOptions = (countryCode, selectedConfigId) => (
        <select onChange={(e) => updateCountryConfig(countryCode, e.target.value)} className='form-control cursor-pointer' value={selectedConfigId}>
            {configs.map(co => <option key={co.id} value={co.id}>{co.name}</option>)}
        </select>
    )

    const getFotaOptions = (countryCode) => (
        <select onChange={(e) => updateCountryFota(countryCode, e.target.value)} className='form-control cursor-pointer' value={getCountryFota(countryCode)}>
            {fotaList.map(f => <option disabled key={`${f.id}${f.country}`} value={f.fw_version}>{f.fw_version}</option>)}
        </select>
    )

    const getVersions = (countryCode) => {
        if(!mapVersions.app) return
        const appIos = mapVersions?.['app']?.['ios']?.[countryCode] || mapVersions?.['app']?.['ios']?.['all']
        const appAndroid = mapVersions?.['app']?.['android']?.[countryCode] || mapVersions?.['app']?.['android']?.['all']
        const researchIos = mapVersions?.['research_purposes']?.['ios']?.[countryCode] || mapVersions?.['research_purposes']?.['ios']?.['all']
        const researchAndroid = mapVersions?.['research_purposes']?.['android']?.[countryCode] || mapVersions?.['research_purposes']?.['android']?.['all']
        const termsIos = mapVersions?.['terms_and_conditions']?.['ios']?.[countryCode] || mapVersions?.['terms_and_conditions']?.['ios']?.['all']
        const termsAndroid = mapVersions?.['terms_and_conditions']?.['android']?.[countryCode] || mapVersions?.['terms_and_conditions']?.['android']?.['all']
        return(
            <select className='form-control' placeholder='Versions...'>
                <option>Versions...</option>
                {[appIos, appAndroid, researchIos, researchAndroid, termsIos, termsAndroid].map(v => <option disabled key={`${v?.id}${v?.country}`} value={v?.release}>{v?.type} - {v?.os} - {v?.release} {!v?.country && '(all countries)'}</option>)}
            </select>
        )
    }

    const updateCountryConfig = async (countryCode, selectedConfigId) => {
        const countryName = countries.find(c => c.code === countryCode).name
        const selectedConfigName = configs.find(c => c.id === +selectedConfigId).name
        const confirmChange = window.confirm(`Are you shure you whant to change ${countryName} country to ${selectedConfigName} configuration?`)
        if( !confirmChange ) return
        await updateCountryConfiguration(countryCode, selectedConfigId, true)
        const updatedCountries = countries.map(c => c.code === countryCode ? {...c, app_configuration_id: selectedConfigId} : c)
        dispatch( setCountries(updatedCountries) )
    }

    const updateCountryFota = async (countryCode, selectedFotaVersion) => {
        const countryName = countries.find(c => c.code === countryCode).name
        const selectedFota = fotaList.find(f => f.fw_version === selectedFotaVersion)
        const confirmChange = window.confirm(`Are you shure you whant to change ${countryName} country to ${selectedFotaVersion} fota version?`)
        if( !confirmChange ) return
        // await updateCountryFota(countryCode, selectedFota.id, true)
        const updatedFotaList = [...fotaList, {...selectedFota, countries: [...selectedFota.countries, countryCode]}]
        dispatch( setFotaList(updatedFotaList) )
    }

    return (
        <div className='card-body'>
            <div className='wrap-config'>
                <div className='list-group'>
                    {
                        countries?.sort((a, b) => a.name.localeCompare(b.name)).map(c => (
                            <div key={c.id} className='list-group-item d-flex justify-content-between countries-row'>
                                <p className='col-2 mb-0 row align-items-center'><strong className='pr-2'>Name:</strong><span>{c.name}</span></p>
                                <p className='col-2 mb-0 row align-items-center'><strong className='pr-2'>Code:</strong><span>{c.code}</span></p>
                                <p className='col-2 mb-0 row align-items-center'><strong className='pr-2'>Configuration:</strong><span className='w-75'>{getConfigsOptions(c.code, c.app_configuration_id)}</span></p>
                                <p className='col-2 mb-0 row align-items-center'><strong className='pr-2'>Fota:</strong><span className='w-75'>{getFotaOptions(c.code)}</span></p>
                                <p className='col-2 mb-0 row align-items-center'><span className='w-75'>{getVersions(c.code)}</span></p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>

    )
}

export default Countries