import React, { useEffect, useState } from 'react'
import { Row, Col } from "reactstrap"
import './realtime-Measure-box.css'
import { useSelector } from 'react-redux'
import { REALTIME_MEASUREMENTS, setupMesutments } from 'consts'

export default function RealtimeMeasureBox({ patient, setAlert, alert, configurations }) {
    const patientMeasurments = useSelector(state => state?.socketPatientsData?.[patient.id])
    const measurments = setupMesutments(patientMeasurments)

    useEffect(() => {

        if (patientMeasurments?.message) {
            setAlert(patientMeasurments.message)
        } else {
            setAlert()
        }
    }, [patientMeasurments])

    const showBoxValues = (measurement) => {
        return (
            <div className='realtime-box'>
                <Col>
                    <Col className='box-value'>
                        <Row>
                            {measurement.title === 'Pulse Rate' ? 'Heart Rate' : measurement.title}
                        </Row>
                        <Row >
                            {`${measurments[measurement.title]?.value || '---'} ${measurement.unit}`}
                        </Row>
                        <Row>
                            <div className='realtime-line' />
                        </Row>
                    </Col>

                </Col>

            </div>
        )
    }

    const displayMeasurements = (measurement) => {
        switch (measurement.title) {
            case 'Pulse Rate':

                if (configurations.configuration.measurements['pulse_rate'] !== 'off') {
                    return showBoxValues(measurement)
                }
                break;
            case 'Respiratory Rate':
                if (configurations.configuration.measurements['respiratory_rate'] !== 'off') {
                    return showBoxValues(measurement)
                }

                break;
            case 'SpO2':
                if (configurations.configuration.measurements['spo2'] !== 'off') {
                    return showBoxValues(measurement)
                }

                break;
            default:
                break;
        }
    }

    return (

        <Col className='measure-box-container'>
            {
                REALTIME_MEASUREMENTS.map((measurement) =>
                    displayMeasurements(measurement))
            }
            <Row className='alert'>{alert}</Row>
        </Col>
    )


}
