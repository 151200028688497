import React, { useState, useEffect } from 'react'
import { Modal, Button } from "reactstrap"
import { Range, getTrackBackground } from 'react-range';
import { DEFAULT_THRESHOLDS } from 'consts';
import { unitsConvert } from 'utils/formaters';

const trackColors = ['#9F2829', '#3AA9E5', '#9F2829']

export default function Threshold({ toggleModal, data, onSave }) {
    const { threshold, values: userValues, unit } = data || {}
    const [values, setValues] = useState([50, 75])
    const { max, min, defaultValues } = threshold === 'Blood pressure' ? DEFAULT_THRESHOLDS[threshold].sys : DEFAULT_THRESHOLDS[threshold || 'SpO2']
    const { dia } = DEFAULT_THRESHOLDS['Blood pressure']
    const [isDataStored, setIsDataStored] = useState(false)
    const [secValues, setSecValues] = useState(dia.defaultValues)
    const step = threshold === 'Body temperature' ? 0.1 : 1

    useEffect(() => {
        if (userValues) {
            if (threshold === 'Blood pressure') {
                setValues([userValues.low_SYS, userValues.high_SYS])
                setSecValues([userValues.low_DIA, userValues.high_DIA])
            }
            else {
                setValues([Number(userValues.low), Number(userValues.high)])
            }
            setIsDataStored(true)
        }
    }, [userValues])

    const renderThresholdRange = (values, min, max, setValues) =>
        <div style={{ marginBottom: '40px' }} >
            {threshold === 'Blood pressure' ? min === dia.min ? 'DIA' : 'SYS' : ''}
            <Range values={values} step={step} min={min} max={max} onChange={(values) => setValues(values)}
                renderThumb={({ props }) => {
                    return (
                        <div className="slider-thumb" {...props} style={{ ...props.style }} >
                            <div style={{ position: 'absolute', top: '10px', left: props.key ? '10px' : '-20px' }}>
                                {unitsConvert(unit)(values[props.key])}
                            </div>
                        </div>
                    )
                }}
                renderTrack={({ props, children }) => (
                    <div onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        style={{ ...props.style, height: '36px', display: 'flex', width: '90%', margin: 'auto' }}>
                        <div ref={props.ref}
                            style={{
                                height: '3px', width: '100%', borderRadius: '4px',
                                background: getTrackBackground({ values, colors: trackColors, min, max }),
                                alignSelf: 'center'
                            }}>
                            {children}
                        </div>
                    </div>
                )} />
        </div>

    const handleRestore = () => {
        setValues(defaultValues)
        if (threshold === 'Blood pressure') {
            setSecValues(dia.defaultValues)
        }
    }

    const onBeforeSave = (threshold, values, secValues) =>{
   let isChanged = false
   if(threshold === 'Blood pressure'){
        if(userValues.low_SYS !== values[0] ||userValues.high_SYS !== values[1] || userValues.low_SYS !== secValues[0] || userValues.high_SYS !== secValues[1]){
            isChanged = true
        }
   }else {
    if(userValues.low !== values[0] || userValues.high !== values[1]){
        isChanged = true
    }
   }
   isChanged ? onSave(threshold,values,secValues) : toggleModal()
    }
    return (
        <div>
            {isDataStored &&

                <Modal className="modal-dialog-centered" size="md" isOpen={data && true} toggle={() => toggleModal()}>

                    <div className="modal-header" style={{ paddingBottom: '5px', fontSize: '18px' }} >
                        {threshold}
                    </div>
                    <div className="bg-blue" style={{ height: '1px', width: '92%', margin: 'auto' }} />
                    <div className="modal-body" style={{ padding: '50px 20px', fontSize: '18px' }}>
                        {renderThresholdRange(values, min, max, setValues)}
                        {threshold === 'Blood pressure' && renderThresholdRange(secValues, dia.min, dia.max, setSecValues)}
                        <div onClick={handleRestore} style={{ color: '#1CAAFC', cursor: 'pointer' }} >
                            Restore default
                        </div>
                    </div>
                    <div className="modal-footer" style={{ paddingTop: 0 }} >
                        <div className="bg-blue" style={{ height: '1px', width: '99%', margin: 'auto' }} />
                        <div style={{ marginTop: '25px' }} >
                            <Button outline color="3873AE" type="button" onClick={() => toggleModal()}
                                style={{ padding: '0.36rem 1.05rem' }}>
                                Cancel
                            </Button>
                            <Button className="text-white" style={{ padding: '0.425rem 1.05rem', backgroundColor: '#3873AE' }}
                                data-dismiss="modal" type="button" onClick={() => onBeforeSave(threshold, values, secValues)}>
                                Save
                            </Button>
                        </div>
                    </div>
                </Modal>
            }
        </div>

    )
}
