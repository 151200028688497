import React, { useState,useEffect } from 'react'
import { Modal,Button } from "reactstrap"
import {MEDICAL_QUESTION_ANSWERS as answers, AF_MEDICAL_QUESTION_ANSWERS as afAnswers,EPILEPSY_MEDICAL_QUESTION_ANSWERS as epilepsyAnswers} from 'consts'
import Checkbox from "react-custom-checkbox";
import { renderMedicalQuestionAnswer as renderAnswer } from 'utils/formaters'

export default function MedicalQuestion({toggleModal,data, onSave}) {
    const [answer, setAnswer] = useState()
    useEffect(() => { data && setAnswer(data?.value?.answer)}, [data])

    const handleChange = (key)=>{
        const oldAnswer = answer.split('.')
        oldAnswer[oldAnswer.length-1]=key
        const newAnswer = oldAnswer.join('.')
        setAnswer(newAnswer)
    }
    
    const css = `
      .medical-input-box div{
          background-color: #707070 !important;
          padding: 6px !important;
          border-radius: 50px !important;
      }`
      return (
        <Modal className="modal-dialog-centered" size="md" isOpen={data&&true} toggle={()=>toggleModal()}>
          <style scoped>{css}</style>

            <div className="modal-header" style={{paddingBottom:'5px',fontSize:'18px'}} >Medical condition</div>
            <div className="bg-blue" style={{height:'1px', width:'92%', margin:'auto'}} />
            <div className="modal-body"  style={{padding:'20px',fontSize:'18px'}}>
              <div className="text-black" >
                  Do you have {data?.condition}?
                  <div style={{marginTop:'10px'}} >
                  {Object.entries(data?.condition === 'A-Fib'? afAnswers : data?.condition === 'Epilepsy'?epilepsyAnswers: answers).map(([key,label])=>
                     <Checkbox 
                          className='medical-input-box'
                          checked={renderAnswer(data?.condition,answer)===label}
                          disabled={renderAnswer(data?.condition,answer)===label}
                          onChange={() => handleChange(key)}
                          borderColor="#707070"
                          borderWidth={2}
                          borderRadius={100}
                          size={20}
                          label={label}
                          key={key}/>
                      )}
                  </div>
              </div>
          </div>
          <div className="modal-footer" style={{paddingTop:0}} >
            <div className="bg-blue" style={{height:'1px', width:'99%', margin:'auto'}} />
            <div style={{marginTop:'25px'}} >
            <Button outline color="3873AE" type="button" onClick={()=>toggleModal()}  
                    style={{padding:'0.36rem 1.05rem'}}>
              Cancel
            </Button>
            <Button className="text-white" style={{padding:'0.425rem 1.05rem',backgroundColor:'#3873AE'}} 
                    data-dismiss="modal" type="button" onClick={()=>onSave(data.condition,answer)}>
              Save
            </Button>
            </div>          
          </div>
        </Modal>
      )
}
