import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Button, Input, InputGroupAddon, InputGroupText, InputGroup, Row, Col } from "reactstrap";
import { VALID_EMAIL_REGEX, VALID_PASSWORD_REGEX } from 'consts';
import Show from '../../assets/img/icons/Show.svg'
import Hide from '../../assets/img/icons/Hide.svg'
import PasswordRestrictions from './password-restrictions';
import CaptchaComponent from './captcha-component';

export default function CredentialsScreen({ onSubmit, errMessage, isRegistesr, displayCaptcha }) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState(null)
  const [captchaValue, setCaptchaValue] = useState('')
  const [errorConfirm, setErrorConfirm] = useState('')
  const [showPassword, setShowPasssword] = useState(false)
  const [showConfirmPassword, setShowConfirmPasssword] = useState(false)

  const handleEnter = (event) => {
    event.preventDefault();
    if (event.key === 'Enter' && !isDisabled()) {
      handleLogin()
    }
  }
  useEffect(() => {
    document.addEventListener("keyup", handleEnter);
    return () => document.removeEventListener("keyup", handleEnter);
  }, [])

  useEffect(() => {
    if (email && !VALID_EMAIL_REGEX.test(email)) {
      return setErrorConfirm('invalid email')
    }
    if (password && !VALID_PASSWORD_REGEX.test(password)) {
      return setErrorConfirm('invalid password please follow the Password policy')
    }
    if (confirmPassword && confirmPassword !== password) {
      return setErrorConfirm('confirm password dose not match')
    }
    setErrorConfirm('')
  }, [password, confirmPassword, email])

  const css = `
  .label-wrap{
    display: flex;
    align-items: center;
  }
  .label-text{
    font-size: ${isRegistesr ? '15px' : '28px'};
  }
  .show-password{
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 2%;
  }
  `
  const handleLogin = async () => {
    onSubmit(email, password, captchaValue)
  }

  const handeShowPassword = () => {
    setShowPasssword(!showPassword)
  }

  const handeShowConfirmPassword = () => {
    setShowConfirmPasssword(!showConfirmPassword)
  }

  const isDisabled = () => {
    if(!email || !password || errorConfirm) return true
    if(isRegistesr && !confirmPassword) return true
    if(!isRegistesr && displayCaptcha && !captchaValue) return true
    return false
  }
  return (
    <>
      <style scoped>{css}</style>
      <Row className="mb-3">
        <Col className='label-wrap' xs="4">
          <div className="label-text" >
            Email
          </div>
        </Col>
        <Col className='ps-1'>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-email-83" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              id="input-email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Email"
              type="email"
              autoFocus
              autoComplete="new-email"
            />
          </InputGroup>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className='label-wrap' xs="4">
          <div className="label-text">
            {isRegistesr && 'Create'}  Password
          </div>

        </Col>
        <Col className='ps-1'>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-lock-circle-open" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              id="input-password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              autoComplete="new-password"
            />
            <div className='show-password' onClick={handeShowPassword}>
              <img alt='show' src={showPassword ? Hide : Show} width={25} />
            </div>
          </InputGroup>
        </Col>
      </Row>
      {isRegistesr && <>
        <Row className="mb-3">
          <Col className='label-wrap' xs="4">
            <div className="label-text">
              Confirm Password
            </div>

          </Col>
          <Col className='ps-1'>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-lock-circle-open" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                id="confirm-password"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                placeholder="Confirm password"
                type={showConfirmPassword ? "text" : "password"}
                autoComplete="new-password"
              />
              <div className='show-password' onClick={handeShowConfirmPassword}>
                <img alt='confirm show password' src={showConfirmPassword ? Hide : Show} width={25} />
              </div>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col className='label-wrap' xs="4"></Col>
          <Col><PasswordRestrictions /></Col>
        </Row>
        <hr />
      </>}
      {(!isRegistesr && displayCaptcha) && <div className='pb-2 d-flex justify-content-end'><CaptchaComponent onCaptchaChange={setCaptchaValue} /></div>}
      {!isRegistesr &&
        <Row className="mt-3">
          <Col xs="4"></Col>
          <Col>
            <Link to="/auth/forgot password" className="text-link text-center">
              <small>Forgot password?</small>
            </Link>
          </Col>
        </Row>
      }
      <Row>
        <Col>
          <small className="text-danger">{errMessage || errorConfirm}</small>
        </Col>
        <Col xl="auto" md="auto" sm="auto" xs="auto">
          <Button disabled={isDisabled()} onClick={handleLogin} className="bg-blue text-white" type="button">
            {isRegistesr ? 'Create new' : 'Sign in'}
          </Button>
        </Col>
      </Row>
    </>
  )
}
