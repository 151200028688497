import axios from 'axios'
import { VALID_PASSWORD_REGEX } from 'consts'
import React,{useState} from 'react'
import { useLocation } from 'react-router-dom'
import { Container,Row,Col, Button, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap'

export default function Passwords({user,jwtToken}) {
    const location = useLocation()
    const [toggleAccountPassword, setToggleAccountPassword] = useState(location?.state?.isOpen)
    const [togglePolicy, setTogglePolicy] = useState(0)
    const [currentPassword, setCurrentPassword] = useState({value:'',show:0})
    const [newPassword, setNewPassword] = useState({value:'',show:0})
    const [confirmPassword, setConfirmPassword] = useState({value:'',show:0})
    const [statusMsg, setStatusMsg] = useState()
    const errorMsg = (label)=>{
      if(label==='Confirm'){
        if(confirmPassword.value && newPassword.value){
          return confirmPassword.value !== newPassword.value && 'Passwords unmatched'
        }
        return null
      }
      if(label==='New'){
        if(newPassword.value && !VALID_PASSWORD_REGEX.test(newPassword.value)) 
          return 'Invalid password, read policy for further information'
      }
    }

    const handleSave= async ()=>{
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/app/user/change-password`,
        {userId:jwtToken? user.userId:user.data.userId, currentPassword:currentPassword.value, newPassword:newPassword.value},
        { headers: {'Authorization': `Bearer ${jwtToken? jwtToken : user.jwtToken}` } }
      ).catch(err=>{
        const {data,status} = err.response
        const {message} = data
        setStatusMsg({message,status})
        }); 
      if(res){
        setStatusMsg({message:'Your password has been changed successfully!',status:200})
      } 
    }

    const isFormValid=
      currentPassword.value && newPassword.value && confirmPassword.value 
      && newPassword.value === confirmPassword.value
      && VALID_PASSWORD_REGEX.test(newPassword.value)

    const renderPasswordField = (label,passwordValue,setPasswordValue)=>
    <Row className="align-items-center" style={{width:'50%',marginTop:'20px'}}>
      <Col>
        <label>{`${label} password`}</label>
        <small style={{display:'block',color:'#f5365c'}} >{errorMsg(label)}</small>
      </Col>
      <Col xl="auto" style={{padding:0}} >
        <InputGroup>
          <Input 
            value={passwordValue.value}
            onChange ={e=>setPasswordValue(Object.assign({},passwordValue,{value:e.target.value}))}
            type={passwordValue.show?"text":'password'}
          />
          <InputGroupAddon addonType="append">
            <InputGroupText>
            <img alt="eye" src={require(`../../../assets/img/icons/Eye-${passwordValue.show?"hide":'show'}.svg`).default}
                  onClick={()=>setPasswordValue(Object.assign({},passwordValue,{show:1-passwordValue.show}))}
                  style={{height:'14px', cursor:'pointer'}} /> 
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </Col>
    </Row> 

    const EditButton = ({onClick})=>
    <img alt="edit" 
        src={require(`../../../assets/img/icons/Edit.svg`).default}
        onClick={onClick} 
        width="20px" 
        style={{cursor:'pointer',marginLeft:'10px',marginBottom:'5px'}}
      /> 

    return (
        <Container fluid>
            <div className="profile-content-container">
                <div style={{marginLeft:'55px',marginTop:'40px',marginBottom:'40px',color:'black',fontWeight:200}}>
                  <Row className="info-row-header" style={{width:'50%'}} >
                    <Col style={{padding:0}} >Account password</Col>
                    <Col xl="auto"> 
                    ****** 
                    <EditButton onClick={()=>setToggleAccountPassword(!toggleAccountPassword)}/> 
                    </Col>
                  </Row>
                  <div style={{display:toggleAccountPassword?'block':'none'}} >
                      {renderPasswordField('Current',currentPassword,setCurrentPassword)}
                      {renderPasswordField('New',newPassword,setNewPassword)}
                      {renderPasswordField('Confirm',confirmPassword,setConfirmPassword)}
                  
                      <div style={{width:'50%',marginTop:'30px',borderBottom:'2px solid #DCDCDC',paddingBottom:'20px'}} >
                        <div onClick={()=>setTogglePolicy(1-togglePolicy)} style={{cursor:'pointer'}} >
                          Password policy 
                          <img alt="expand" 
                               src={require(`../../../assets/img/icons/Expand.svg`).default} 
                               style={{height:'15px',marginLeft:'5px',transform:togglePolicy?'rotate(180deg)':undefined}} />
                        </div>
                        <div style={{display:togglePolicy?'block':'none', marginLeft:'5px',fontSize:'smaller'}} >
                          <div>At least 9 characters</div>
                          <div>At least one capital letter [A-Z]</div>
                          <div>At least and one small letters [a-z]</div>
                          <div>At least one alphanumeric character [0-9]</div>
                          <div>At least one special character [!@#$%^&*}]</div>
                          </div>
                      </div>

                      <div style={{display:'flex',width:'49.4%',marginTop:'20px'}}>
                        {statusMsg&& <div style={{color:statusMsg.status===200?'#2dce89':'#f5365c',fontWeight:400}} >
                          {statusMsg.message}
                        </div>}
                        <Button onClick={handleSave} disabled={!isFormValid} className="text-white" style={{padding:'0.425rem 1.05rem',backgroundColor:'#3873AE',marginLeft:'auto'}}>
                          Save
                        </Button>
                      </div>

                    </div> 
{/*                     <Row className="info-row-header mt-3" style={{width:'50%'}} >
                          <Col style={{padding:0}} >PDF Report password</Col>
                          <Col xl="auto"> 
                          ****** 
                          <EditButton/>
                          </Col>
                      </Row> */}

            </div>
        </div>
        </Container>
    )
}

/* defaultValue={currentPassword.value.split('').map(c=>'*').join('')} */

    /* <input className="input-sigi" 
            type="text" 
            value={currentPassword.value}
            onChange={e=>setCurrentPassword(Object.assign({},currentPassword,{value:e.target.value})) } /> */

            /* 
            
<Row className="align-items-center" style={{width:'30%',marginTop:'20px'}}>
<Col>
    <label for="confirm-password">Confirm your password</label>

</Col>
<Col xl="auto" style={{padding:0}}>
<input type="password" className="input-sigi" required="true" id="confirm-password"
      pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*}])[A-Za-z\d!@#$%^&*}]{9,}$" name="confirm-password"
      onkeyup="checkValidation()" />
    <div class="hide-show-confirm-password password-show" onclick="toggleConfirmPassword()"></div>
</Col>
</Row>
            */