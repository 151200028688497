import { setUserToState } from 'store/actions'
import axios from 'axios'
import { DOCTOR_INFO_FIELDS, DOCTOR_PREFIX, DOCTOR_TYPE } from 'consts'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Col } from 'reactstrap'
import { isValidPhoneNumber, isValidPostalCode } from 'utils/validations'
import ReactSelect from 'react-select'
import RadioButtonComponent from 'components/Inputs/radio-button.component'
import { convertCamelKeysToSnakeCase } from 'utils'

function RegisterPersonalInfo({ user, setUser }) {
    const [countries, setCountries] = useState([])
    const defaultUserValues = {type: DOCTOR_TYPE[0], prefix: DOCTOR_PREFIX[0]}
    const [userInfo, setUserInfo] = useState(defaultUserValues)
    const [userInfoErrors, setUserInfoErrors] = useState({})
    const [isFormValid, setIsFormValid] = useState(false)
    const [formError, setFormError] = useState('')

    const dispatch = useDispatch()

    const formFields = DOCTOR_INFO_FIELDS
    delete formFields.username
    delete formFields.uniqueId

    const ERROR_MESSAGES = {
        country: {
            blank: 'Please choose your country of residence to comply with local regulatory requirements and for new feature releases.'
        },
        postalCode: {
            invalid: 'Please enter a valid postal code'
        },
        phoneNumber: {
            invalid: 'Please enter a valid phone number'
        }
    }

    function order(a, b) {
        return a.name < b.name ? -1 : (a.name > b.name ? 1 : 0);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/app/country/get-all-countries`)
                setCountries(res.data)
            } catch (error) {
                console.log('error', error)
            }
        }
        fetchData()
    }, [])

    const inputer = (e) => {
        validations(e.target.name, e.target.value)
        setUserInfo(prevInfo => ({ ...prevInfo, [e.target.name]: e.target.value}))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsFormValid(false)
        let approvalState = 'verified'
        const {registerAttempts} = user.data
        let doctor = { userId: user.data.userId, approvalState, ...userInfo, registerAttempts}
        doctor = convertCamelKeysToSnakeCase(doctor)
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/app/doctor/create`, { ...doctor }, { headers: { 'Authorization': `Bearer ${user.jwtToken}` } })
            if(res?.data?.message){
                setFormError(res?.data?.message)
                setIsFormValid(true)
                return
            }
            setUser({ ...user, data: { ...user.data, ...res.data } })
            dispatch(setUserToState({ ...user, data: { ...user.data, ...res.data } }))
        } catch (error) {
            console.log('error', error)
            setFormError(error?.response?.data?.message || 'Somthing Went Wrong')
            setIsFormValid(true)
        }
    }

    const checkFormValidation = (isError) => { 
        if(!isError && (Object.values(userInfo).length > Object.keys(formFields).length-1 || (Object.values(userInfo).length === 9 && !Object.keys(userInfo).includes('nickname') ))){
            return true
        } else return false
    }

    useEffect(() => {
        let isErrors = false;
        Object.values(userInfoErrors).forEach(val => { if (val) isErrors = true })
        const isFormValid = checkFormValidation(isErrors)
        setIsFormValid(isFormValid)

    }, [userInfoErrors, userInfo])

    const validations = ( key, value ) => {
        const {title, isOptional} = formFields[key]
        let error = '';
        if ((!value || !value.length) && !isOptional) {
            error = ERROR_MESSAGES[key]?.blank || `${title} cannot be blank`
        } else {
            switch (key) {
                case 'postalCode':
                    if (!isValidPostalCode(value)) error = ERROR_MESSAGES[key].invalid;
                    break;
                case 'phoneNumber':
                    if (!isValidPhoneNumber(value)) error = ERROR_MESSAGES[key].invalid;
                    break;
                case 'professionalCode':
                    // what to check???')
                    break;
                default: break
            }
        }
        setUserInfoErrors(prevErrors => ({ ...prevErrors, [key]: error }))
    }
    
    const countriesInput = (key) => {
        const options = countries?.sort(order).map((c) => ({ value: c.countryCode, label: c.name }) )
        const selectedCountries = options?.filter(c => userInfo[key] === c.value)
        return <div key={key} className='form-group row'>
            <Col className='align-items-center d-flex p-0' xs="4"><label className='col-xs-6 m-0' htmlFor={key}>{formFields[key].title}</label></Col>
            <Col xs="8">
                <ReactSelect
                    options={options}
                    isClearable={false}
                    placeholder={'Choose a country'}
                    defaultValue={selectedCountries[0]}
                    onMenuClose={() => {
                        validations(key, userInfo[key])
                    }}
                    onChange={({value}) => {
                        const e = {target: {name: key, value}}
                        inputer(e)
                    }}
                />
                {userInfoErrors[key] && <span className='text-red' sm="12">{userInfoErrors[key]}</span>} 
            </Col>

        </div>
    }

    const renderRadioInput = (key) => {
        let options = {
            type: DOCTOR_TYPE,
            prefix: DOCTOR_PREFIX
        }
        const updateVal = (value) => inputer({target: {name: key, value}})
        return <div key={key} className='prefix form-group row mt-5'>
            <Col className='align-items-center d-flex p-0' xs="4"><label className='col-xs-6 m-0' htmlFor={key}>{formFields[key]?.title}</label></Col>
            <Col xs="4 p-0">
                <RadioButtonComponent name={key} options={options[key]} checkedOption={userInfo[key]} onChange={updateVal} />
            </Col>
        </div>
    }

    const getFields = () => {
        return Object.keys(formFields).map(fieldKey => {
            let {title, type, isOptional} = formFields[fieldKey]
            if(isOptional) title += ' (optional)'

            if (fieldKey === 'country') return countriesInput(fieldKey)
            if (type === 'radio') return renderRadioInput(fieldKey)

            return <div key={fieldKey} className='form-group row'>
                <Col className='align-items-center d-flex p-0' xs="4">
                    <label className='col-xs-4 m-0' htmlFor={fieldKey}>{title}</label>
                </Col>
                <Col xs="8">
                    <input className='form-control' onInput={inputer} onBlur={(e) => validations(fieldKey, e.target.value)} name={fieldKey} value={userInfo?.[fieldKey]||''} id={fieldKey} />
                    {userInfoErrors[fieldKey] && <span className='text-red' sm="12">{userInfoErrors[fieldKey]}</span>}
                </Col>

            </div>
        })
    }

    return (
        <>
            <div className='register-info p-5 card m-3 flex-nowrap'>
                <div className='wrap-page-title'><span className='page-title'>Fill in all fields. Press Next when done.</span></div>
                <div className='wrap-info'>
                    <Col>
                        <form className='personal-info-form' onSubmit={handleSubmit}>
                            {getFields()}
                            <div className='text-right'><Button disabled={!isFormValid} className="bg-blue text-white mt-7">Submit</Button></div>
                            {formError && <div className='text-right'><span className='text-red'>{formError}</span></div>}
                        </form>
                    </Col>                   
                </div>
            </div>
        </>
    )
}

export default RegisterPersonalInfo