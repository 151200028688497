import { getPatientByEmailOrPIdOrUId } from 'api/admin-api'
import React, { useState } from 'react'
import PatientEdit from './patientEdit'

function Patients() {

    const [patientId, setPatientId] = useState('')
    const [userId, setUserId] = useState('')
    const [patientEmail, setPatientEmail] = useState('')
    const [watchName, setWatchName] = useState('')
    const [data, setData] = useState()

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log('patientId', patientId)
        console.log('patientEmail', patientEmail)
        console.log('userId', userId)
        console.log('watchName', watchName)
        const {patient, user, doctor} = await getPatientByEmailOrPIdOrUId(userId, patientEmail?.toLowerCase(), patientId, watchName)
        setData({patient, user, doctor})
        console.log('details', {...patient, ...user, patientId: patient?.id})
    }
  return (
    <div className='card'>
        <div className='card-header'>
            <h4 className='card-title'>Get Patient</h4>
            <form onSubmit={handleSubmit} className='d-flex justify-content-around align-items-center'>
                <div className='form-group'>
                    <label htmlFor='patient-id'>Patient ID: </label>
                    <input value={patientId} onInput={(e) => setPatientId(e.target.value)} type='number' className='form-control' id='patient-id' placeholder='Patient ID' />  
                </div>
                <div className='form-group pl-2'>
                    <label htmlFor='patient-email'>Patient Email: </label>
                    <input value={patientEmail} onInput={(e) => setPatientEmail(e.target.value)} type='text' className='form-control' id='patient-email' placeholder='Patient Email' />  
                </div>
                <div className='form-group pl-2'>
                    <label htmlFor='user-id'>User ID: </label>
                    <input value={userId} onInput={(e) => setUserId(e.target.value)} type='text' className='form-control' id='user-id' placeholder='User ID' />  
                </div>
                <div className='form-group pl-2'>
                    <label htmlFor='watch-name'>Watch Name: </label>
                    <input value={watchName} onInput={(e) => setWatchName(e.target.value)} type='text' className='form-control' id='watch-name' placeholder='CS 0000000' />  
                </div>
                <button disabled={!patientEmail && !patientId && !userId && !watchName} type='submit' className='btn btn-primary h-25'>Get User</button>
            </form>
        </div>
        {data && <div className='card-body'>
            <PatientEdit data={data} />
        </div>}
        
    </div>
  )
}

export default Patients