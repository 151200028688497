import axios from 'axios'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody } from 'reactstrap'
import envelopeSvg from "../../assets/img/theme/envelope.svg"

function ActivateAccount({ email,setEmailActivateScreen }) {
  useEffect(() => {
    resendEmail()
  }, [])

  const resendEmail = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/app/user/resend-verification-email`, { username: email })
      console.log('activate email sent')
    } catch (err) {
      console.log('err', err)
    }
  }
  const css = `
  .activate-content{
    margin-top: -120px;
  }
    .activate-text p{
      font-size: 1.2rem;
      
    }
    .pointer{
      cursor:pointer;
    }
    .activate-btns{
      display: flex;
      justify-content: flex-end;
    }
  `
  return (
    <div>
      <style scoped>{css}</style>
      <Card className="bg-secondary activate-content shadow border-0">
        <CardBody className="px-lg-5 py-lg-5 activate-text">
          <img className='pb-3' width={70} src={envelopeSvg} />
          <p className=''>
            An email with an activation link was sent to:
            <strong className='mt-2 mb-2 d-block'>{email}</strong>
          </p>
          <p>
            To proceed, check your email and click the link <br />to activate your account
          </p>
          <span className='mt-4 d-block'>Did not receive the email? Check your spam filter <br />You can also try to <span className='text-blue pointer' onClick={resendEmail}>resend email</span>.</span>
          <hr />
          <div className='activate-btns'>
            <Link to={'/login'}><Button className='bg-blue text-white'>Continue to Login</Button></Link>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default ActivateAccount