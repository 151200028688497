import SubNavbar from 'components/Navbars/SubNavbar'
import React, { useState, useEffect } from 'react'
import { Col, Container, Input, Row } from 'reactstrap'
import _ from 'lodash'
import { PATIENT_STATUS_COLORS as statusColors } from 'consts'
import { CSSTransition } from 'react-transition-group'
import axios from 'axios'
import ConfirmModal from 'components/Modals/confirm-modal'
import { useDispatch, useSelector } from 'react-redux'
import { setPhysicians } from 'store/actions'
import { getSelectedDoctors } from 'api/api-manager'
const MAX_DOCTORS = 2

export default function Physicians({user, patientId, jwtToken, role = 'patient' }) {
    const isPatient = role === 'patient'
    if(isPatient){
        patientId = user?.data?.id
        jwtToken = user?.jwtToken
    }
    const controllerRoute = isPatient ? 'doctor' : 'testing'
    const [doctorsList, setDoctorsList] = useState([])
    const [selectedDoctors, setSelectedDoctors] = useState([])
    const [doctorToRemove, setDoctorToRemove] = useState()
    const [doctorsDeclined, setDoctorDeclined] = useState([])
    const [removedPatients, setRemovedPatients] = useState([])
    const [uniqueId, setUniqueId] = useState()
    const { physicians } = useSelector((state) => state)
    const dispatch = useDispatch()
    const filterData = () => {
        const fixSelectedDoctorsData = fixDoctorData(physicians)
        const declined = []
        fixSelectedDoctorsData.map((doctor) => {
            if (doctor.status === 'denied') {
                declined.push(doctor)
            } else {
                setSelectedDoctors(fixSelectedDoctorsData.filter((doc) => doc.status === 'approved' || doc.status === 'pending' || doc.status === 'removed-by-doctor'))
            }
        })
        setDoctorDeclined(declined)
    }

    useEffect(() => {
        filterData()
    }, [physicians])

    const getDocWithUncheckedStatus = (doctors) => {
       const uncheckedDoctors = doctors?.filter((doctor) => (['removed-by-doctor', 'approved', 'denied'].includes(doctor.status)) && !doctor.status_checked)
       const uncheckedDoctorsId = uncheckedDoctors.map(d => d.id)
       return uncheckedDoctorsId
    }

    const updatedPhyiscians = (updatedSelectedDoctors) => {
        const updatedPhyiscians = []
        updatedSelectedDoctors.forEach((doc) => {
            updatedPhyiscians.push({ ...doc, status_checked: true })
        })
        if (updatedPhyiscians.length) dispatch(setPhysicians(updatedPhyiscians))
    }

    const updateStatusChecked = async (selectedDocs) => {
        const uncheckedDoctorListId = getDocWithUncheckedStatus(selectedDocs)
        await axios.post(`${process.env.REACT_APP_API_URL}/app/${controllerRoute}/update-relation-status-checked-by-patient`,
            { doctor_removed: uncheckedDoctorListId, patient_id: patientId, status_checked: true },
            { headers: { 'Authorization': `Bearer ${jwtToken}` } })
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const updatedSelectedDoctors = await getSelectedDoctors(controllerRoute, patientId)
                if(isPatient) await updateStatusChecked(updatedSelectedDoctors)
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/app/${controllerRoute}/getAllDoctors`, { headers: { 'Authorization': `Bearer ${jwtToken}` } })
                const doctorsData = fixDoctorData(res.data)
                setDoctorsList(doctorsData)
                updatedPhyiscians(updatedSelectedDoctors)
            } catch (error) {
                console.log('error', error)
            }
        }
        fetchData()
    }, [])

    function fixDoctorData(data) {
        const doctors = data.map(d => ({ id: d.id, name: `${d.nickname ? d.first_name + '  (' + d.nickname + ') ' + d.last_name : d.first_name + ' ' + d.last_name} `, address: `${d.city}`, status: d.status ? d.status : null, expertise: d.type, uniqueId: d.unique_id }))
        return doctors
    }

    const headerStyle = { backgroundColor: '#EFEFEF', padding: '0.8rem 0rem', margin: '0px 30px', paddingLeft: '15px', color: '#053f7f', fontSize: 'larger' }
    const linkStyle = (doctor, i, arr) => ({ color: doctor.status === 'removed-by-doctor' ? 'red' : 'black', fontWeight: 200, padding: '0.4rem 0rem', margin: '0px 30px', display: 'block', borderBottom: i === arr?.length - 1 ? 'none' : '1px #a3a3a3 solid' })

    const requestDisabled = (id) => {
        return selectedDoctors[0]?.id === id || selectedDoctors[1]?.id === id || selectedDoctors.length > MAX_DOCTORS - 1
    }

    const handleRequest = async (newDoctor) => {
        if (requestDisabled(newDoctor.id)) {
            return
        }
        doctorsDeclined.filter((d) => newDoctor.id !== d.id)
        setDoctorDeclined(doctorsDeclined.filter((d) => newDoctor.id !== d.id))
        const status = isPatient ? 'pending' : 'approved'
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/app/${controllerRoute}/request-doctor`, { doctor_id: newDoctor.id, patient_id: patientId, status }, { headers: { 'Authorization': `Bearer ${jwtToken}` } })
            setSelectedDoctors([...selectedDoctors, { ...newDoctor, status }])
        } catch (error) {
            console.log('error', error)
        }
    }

    const handleRemove = async (id, index) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/app/${controllerRoute}/update-relation-status`, { doctor_id: id, patient_id: patientId, status: 'removed-by-patient' }, { headers: { 'Authorization': `Bearer ${jwtToken}` } })
            const newArr = [...selectedDoctors]
            newArr.splice(index, 1)
            setSelectedDoctors(newArr)
            setDoctorDeclined(doctorsDeclined.filter((d) => d.id !== id))
            setDoctorToRemove()
            dispatch(setPhysicians(physicians.filter((item) => item.id !== id)))
        } catch (error) {
            console.log('error', error)
        }
    }

    const renderDoctorStatus = (status) => {
        return <div className='text-capitalize' style={{ color: statusColors[status] }}> {status === 'denied' ? 'Decline' : status} </div>
    }

    const filteredDoctors = () => {
        if(!isPatient) {
            if(uniqueId) return doctorsList.filter((doc) => doc.name.toLowerCase().includes(uniqueId.toLowerCase()))
            return doctorsList
        }
        return doctorsList.filter((doc) => uniqueId && doc.uniqueId === uniqueId)
    }

    return (
        <div>
            {doctorToRemove &&
                <ConfirmModal
                    text={`Are you sure you want to remove ${doctorToRemove.name} from your physician list ?`}
                    onConfirm={() => handleRemove(doctorToRemove.id, doctorToRemove.index)}
                    onCancel={setDoctorToRemove}
                />
            }
            <SubNavbar title={'Physicians'} subPages={['Physicians']} />
            <Container fluid>
                <CSSTransition in={selectedDoctors.length > 0 || doctorsDeclined.length > 0} timeout={500} classNames="chart-card" unmountOnExit>
                    <div className="reports-content-container" style={{ marginTop: '30px', display: selectedDoctors.length || doctorsDeclined.length ? 'block' : 'none' }} >
                        <div style={{ ...headerStyle, marginTop: '30px' }}>
                            <Row>
                                <Col xl="4">Physician Name</Col>
                                <Col xl="2" style={{ paddingLeft: 0 }}> Expertise</Col>
                                <Col style={{ paddingLeft: 0 }}> Address</Col>
                                <Col xl="1" style={{ paddingLeft: 0, textAlign: 'center', marginRight: '15px' }}> Status </Col>
                                <Col xl="1" style={{ paddingLeft: 0 }}> Remove </Col>
                            </Row>
                        </div>
                        {[...selectedDoctors, ...doctorsDeclined, ...removedPatients].map((doctor, i, arr) =>
                            <div style={linkStyle(doctor, i, arr)} key={doctor.id} >
                                <Row style={{ alignItems: 'center', margin: 0 }} >
                                    <Col xl="4" style={{ color: doctor.status === 'removed-by-doctor' ? 'red' : '#053F7F', fontWeight: '500' }} >
                                        {doctor.nickname ? doctor.nickname : doctor.name}
                                    </Col>
                                    <Col xl="2" style={{ paddingLeft: '5px' }} >
                                        {doctor.expertise}
                                    </Col>
                                    <Col style={{ paddingLeft: '5px' }}>
                                        {doctor.address}
                                    </Col>
                                    <Col xl="1" style={{ paddingLeft: 0, textAlign: 'center' }}>
                                        {renderDoctorStatus(doctor.status)}
                                    </Col>
                                    <Col xl="1" style={{ paddingLeft: 0, display: 'flex' }}>
                                        <img
                                            alt="icon"
                                            src={require(`../../assets/img/icons/remove-doctor.svg`).default}
                                            style={{ margin: 'auto', height: '2.5rem', cursor: 'pointer' }}
                                            onClick={() => setDoctorToRemove({ ...doctor, index: i })}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        )}
                        <br />
                    </div>
                </CSSTransition>


                <div style={{ color: '#003E7E', fontSize: 'large', padding: '30px 5px' }} >
                    {`Please type a physician ${isPatient ? 'unique id' : 'name'}`}         
                </div>

                <div className="reports-content-container mb-5">

                    <Row style={{ margin: '30px', marginBottom: '10px', alignItems: 'center' }} >
                        <Col xl="4" style={{ paddingLeft: 0, marginRight: '5px' }} >
                            <Input
                                placeholder={`Type physician ${isPatient ? 'unique ID' : 'name'}`}
                                value={uniqueId}
                                onChange={(e) => setUniqueId(e.target.value)}
                            />
                        </Col>
                    </Row>
                    { filteredDoctors().length > 0 &&
                        <div style={headerStyle}>
                            <Row>
                                <Col xl="4">Physician Name</Col>
                                <Col xl="2" style={{ paddingLeft: 0 }}> Expertise </Col>
                                <Col style={{ paddingLeft: 0 }}> Address </Col>
                                <Col className='text-center' style={{ paddingLeft: 0, maxWidth: '160px', opacity: selectedDoctors.length > MAX_DOCTORS - 1 ? 0.3 : 1 }}>{isPatient ? 'Send Request' : 'Assign'}</Col>
                            </Row>
                        </div>
                    }

                    {filteredDoctors().map((doctor, i, arr) =>
                        <div style={linkStyle(i, arr)} key={doctor.id} >
                            <Row style={{ alignItems: 'center', margin: 0 }} >
                                <Col xl="4" style={{ color: '#053F7F', fontWeight: '500' }} >
                                    {doctor.nickname ? doctor.nickname : doctor.name}
                                </Col>
                                <Col xl="2" style={{ paddingLeft: '5px' }} >
                                    {doctor.expertise}
                                </Col>
                                <Col style={{ paddingLeft: '5px' }}>
                                    {doctor.address}
                                </Col>
                                <Col style={{ paddingLeft: 0, maxWidth: '140px', display: 'flex' }}>
                                    <img
                                        alt="icon"
                                        src={require(`../../assets/img/icons/send-circle.svg`).default}
                                        style={{ margin: 'auto', height: '3rem', cursor: requestDisabled(doctor.id) ? 'default' : 'pointer', opacity: requestDisabled(doctor.id) ? 0.3 : 1 }}
                                        onClick={() => handleRequest(doctor)}
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}
                    <br />
                </div>

            </Container>
        </div>
    )
}
