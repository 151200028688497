import { Link } from "react-router-dom";
import Cookies from 'js-cookie'
import { UncontrolledCollapse, NavbarBrand, Navbar, NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

const PatientNavbar = (props) => {

  const homePage = 'https://www.cardiacsense.com'

  return props.display ? (
      <>
        <Navbar color="white" light expand="md" className="fixed-top border-bottom" >
          <Container fluid>
            <NavbarBrand to="/" tag={Link}>
              <img alt="brand" src={ require("../../assets/img/brand/cs_logo.png").default } height="59px" />
            </NavbarBrand>
            <button className="navbar-toggler" id="navbar-collapse-main">
              <span className="navbar-toggler-icon" />
            </button>
            <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
              <div className="navbar-collapse-header d-md-none">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link to="/">
                      <img alt="brand" src={ require("../../assets/img/brand/logo.png").default} />
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <button className="navbar-toggler" id="navbar-collapse-main">
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <a className="nav-link nav-link-icon" href={homePage} tag={Link}>
                    <span className="nav-link-inner--text">Back to Homepage</span>
                  </a>
                </NavItem>
                <NavItem>
                  <a className="nav-link nav-link-icon" href={`https://cardiacsense.zendesk.com/hc/en-us/requests/new`} tag={Link} >
                    <span className="nav-link-inner--text">Contact support</span>
                  </a>
                </NavItem>
                <NavItem>
                  <a className="nav-link nav-link-icon" href={`${homePage}/about`} tag={Link}>
                    <span className="nav-link-inner--text">About</span>
                  </a>
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link-icon" tag={Link} disabled to="#">
                    <span className="nav-link-inner--text">English</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link-icon" to="#" tag={Link} onClick={(e) => {Cookies.remove('user') ;props.setUser(); localStorage.removeItem('jwtToken'); window.location.reload(false);}}>
                    <span className="nav-link-inner--text">Logout</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
            </Container>
        </Navbar>
      </>
    ) :'';
  };

export default PatientNavbar;


/* 
<DropdownItem onClick={(e) => props.setUser()}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
*/