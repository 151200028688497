import React from 'react'
import deniedCompleteImage from "../../../assets/img/theme/denied-img.svg"

function DoctorDeniedCompleteScreen({user, setUser}) {

  return (
    <div className='denied-section card row'>
    <div className='denied-content text-center'>
      <h1 className='denied-title mr-0'>Your registration request has been rejected</h1>
      <hr className='w-50 m-auto text-center' />
      <div className='text-center mt-3 mb-5 size-3'>Please contact our <a className='register-link mr-0' href='https://cardiacsense.zendesk.com/hc/en-us/requests/new'>customer service</a></div>
      <img src={deniedCompleteImage} alt='denied-img'/>
    </div>
  </div>
  )
}

export default DoctorDeniedCompleteScreen