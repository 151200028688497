
import React,{useEffect} from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import PatientNavbar from "components/Navbars/PatientNavbar.js";
import PatientFooter from "components/Footers/PatientFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import axios from "axios";
import RegistrationStatusPage from "pages/doctor's patient/doctor-status/registration-status";
import { setMeasurementsFromDB, setPatientsToRedux } from "store/actions";
import { useDispatch } from "react-redux";
import WebsocketDoctor from "services/WebsocketDoctor";

const Doctor = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const isStaticPage = location.pathname.split('/')[1]==='static'
  const dispatch = useDispatch()

  useEffect( () => {
    if(isStaticPage || props.user.data.approvalState !== 'verified') return 
    fetchPatientsData()
    const fetchDataInterval = setInterval(fetchPatientsData, 60000)
    return () => clearInterval(fetchDataInterval)
  }, []);

  const fetchPatientsData = async() => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/app/doctor/get-all-doctor-patients`,{ headers: {'Authorization': `Bearer ${props.user.jwtToken}`, accessToken:props.user.data.accessToken} }) 
      dispatch( setPatientsToRedux(res.data?.patients) )
      const measurementsFromDB = await axios.get(`${process.env.REACT_APP_API_URL}/app/doctor/get-measurement-from-db`,{ headers: {'Authorization': `Bearer ${props.user.jwtToken}`, accessToken:props.user.data.accessToken} }) 
      dispatch( setMeasurementsFromDB(measurementsFromDB.data?.patientsLastEvents) )
    } catch (error) {
      console.log('error',error)
    }
  }

  
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const isRegisteredDoctor = () => (props.user.data.approvalState === 'verified' && !!props.user.data.uniqueId)

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {

      const [upperLayout, innerLayout] = prop.layout.split('/').filter(layout=>layout)
      if(upperLayout === "doctor" && innerLayout){
        return (
          <Route
            path={prop.path}
            component={()=> <prop.component user={props.user.data} setUser={props.setUser} jwtToken={props.user.jwtToken} />}//<ProfileWrapper/>}
            key={key}
          />
        );
      } 
      
       if (prop.layout === "/doctor") {

        return (
          <Route
            exact path={prop.path}
            component={()=> <prop.component setUser={props.setUser} user={props.user} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <PatientNavbar {...props} display={!isStaticPage} />
      <Sidebar {...props} routes={props.user.data.approvalState === 'verified' ? routes : []} display={!isStaticPage} />
      <div className="main-content" ref={mainContent}>
        {!isStaticPage &&<Container fluid>
        <div style={{height:'93px'}} />
        </Container>}
        <Switch>
          {!isRegisteredDoctor() && 
          <Route
            path="*"
            component={()=><RegistrationStatusPage setUser={props.setUser} user={props.user} /> }
            key={0}
          />}
          {getRoutes(routes)}
          <Redirect from="*" to="/dashboard" />
        </Switch>
        <Container fluid>
          <PatientFooter />
        </Container>
      </div>

      {!isStaticPage && <WebsocketDoctor user={props.user} />}
      </>
  );
};
export default Doctor;