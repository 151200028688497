import { Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, Collapse } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import Thresholds from "pages/profile/patient/Thresholds";
import MarketPlace from "pages/reports/MarketPlace";
import MedicalInfo from "pages/profile/patient/Medical";
import Archive from "pages/reports/archive";
import { PersonalInfo } from "pages/profile/patient/PersonalInfo";
import Medications from "pages/profile/patient/medications";
import MeasuremetReminders from "pages/profile/patient/measurement-reminders";
import { useEffect } from "react";
import { getPatientAppConfig } from "api/api-manager";

export default function SubNavbarDoctor({ patient, subLocation, jwtToken , appConfigs }) {

    useEffect(() => {
        if(appConfigs) return 
            getPatientAppConfig(patient.id)
    }, [])

    const getSubPages = () => {
        const subPages = ['Dashboard', 'Personal data', 'Medical data', 'Medications', 'Measurements', 'Thresholds', 'Reports']
        const showTrhesholds = appConfigs?.configuration?.alerts?.thresholds === 'on'
        const subPagesFilteredByThresholdsConfig = subPages.filter(s => s !== 'Thresholds' || showTrhesholds)
        return subPagesFilteredByThresholdsConfig
    }
    
    const history = useHistory()
    const setSubRouteParam = (route, dates) => {
        history.push({ pathname: `/patient=${patient.id}/${route}`, state: { dates } })
    }

    const subMenu = () => (

        <Navbar color="white" light expand="md" style={{ paddingLeft: '38px' }} /*   className="border" */ >
            <NavbarToggler />
            <Collapse isOpen navbar>
                <Nav className="mr-auto w-100" navbar>
                    {getSubPages().map(page => page === subLocation ?
                        <NavbarBrand key={page} style={{ cursor: 'pointer' }} >{subLocation}</NavbarBrand>
                        :
                        <NavItem style={{ cursor: 'pointer' }} key={page}>
                            <div className="nav-link" onClick={() => setSubRouteParam(page)} tag={Link}>{page}</div>
                        </NavItem>
                    )}
                </Nav>
            </Collapse>
        </Navbar>
    )

    const renderSubPage = () => {
        if (subLocation === 'Personal data') return <PersonalInfo user={patient} role='doctor' />
        if (subLocation === 'Medical data') return <MedicalInfo patient={patient} jwtToken={jwtToken} role={'doctor'} />
        if (subLocation === 'Medications') return <Medications user={patient} role={'doctor'} />
        if (subLocation === 'Measurements') return <MeasuremetReminders user={patient} role={'doctor'} />
        if (subLocation === 'Thresholds') return <Thresholds patient={patient} jwtToken={jwtToken} role={'doctor'} />
        if (subLocation === 'Reports') return <MarketPlace patient={patient} setSubRouteParam={setSubRouteParam} appConfigs={appConfigs} role={'doctor'} />
        if (subLocation === 'Archive') return <Archive user={patient} jwtToken={jwtToken} role={'doctor'} />
    }

    return (
        <>
            {subMenu()}
            {renderSubPage()}
        </>
    )
}