import React,{useEffect, useState} from 'react'
import { connect, useSelector } from 'react-redux'
import ReactDatePicker from 'react-datepicker'
import { Button, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupAddon, InputGroupText, Row, UncontrolledDropdown } from 'reactstrap'
import { setUnsavedChanges } from 'store/actions'
import axios from 'axios'
import Cookies from 'js-cookie'
import { PERSONAL_INFO_FIELDS as fields } from 'consts'
import { fixNumAndRemoveDecimalZero } from 'utils'
import { getAllCountries, getRefreshedUserData } from 'api/api-manager'
import moment from 'moment'

const MAX_CHARS_LIMITS = 24

export function PersonalInfo({user,setUser,unsavedChanges,setUnsavedChanges,jwtToken, role}) {

    const { countries } = useSelector(state => state)
    const [data, setData] = useState(user)
    const [isOpen, setIsOpen] = useState(false)
    const [inputError, setInputError] = useState(null)
    const [saveError, setSaveError] = useState()
    const isDoctor = role === 'doctor'

    useEffect( () => {
        // i think this function is unnecessary but it was asked by Menni - it will reload patient data because maybe the doctor changed patients data 
        getRefreshedUserData()
        getAllCountries()
    },[])

    useEffect(() => {
        if(!countries.length) return
        setData({...data, country: getCountryNameByCode(user.country)})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countries])

    useEffect( () => {
        if(inputError === null) return
        (inputError && inputError !== '') ? setUnsavedChanges(null,false) : setUnsavedChanges(handleSave,true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[data])

    const getCountryCodeByName = (countryName) => {
        const countryCode = countries?.find(c => c.name.toLowerCase() === countryName.toLowerCase()).countryCode
        return countryCode
    }

    const getCountryNameByCode = (countryCode) => {
        const countryName = countries?.find(c => c.countryCode === countryCode)?.name
        return countryName
    }
    
    const handleSave = ()=>{
        const countryCode = getCountryCodeByName(data.country)
        const newData = {
            ...data,
            patientId: data.id,
            weight: Number(data.weight),
            height: Number(data.height),
            birthDate: moment(data.dateOfBirth).format('YYYY-MM-DD'),
            country: countryCode
        }
        if(data['timeZone'] || data['timeZone'] === 0) newData.timeZone = Number(data['timeZone'])
        axios.post(`${process.env.REACT_APP_API_URL}/app/patient/update-patient-information`,
            newData,{ headers: {'Authorization': `Bearer ${jwtToken}` } })
            .then(()=>{
                const updatedUser = Object.assign({},user,newData)
                setUser({data:updatedUser,type:'patient', jwtToken})
                Cookies.set('user', JSON.stringify({data:updatedUser,type:'patient', jwtToken}), {expires:1/24})
                setUnsavedChanges()
            })
            .catch(err=>{
                const {data} = err.response
                const {message} = data
                setSaveError(message)
            })  
    }

    const getSelectOptions = (action) => {
        let {options} = fields[action]
        if(action === 'country'){
            options = countries?.map(c => c.name)
        }
        return options?.map(op => {
            const key = `${op}`.toLowerCase()
            return <DropdownItem key={op} active={key == data[action]} onClick={() => handleChange(key, action)}>{op}</DropdownItem>
        })
    }

    const isEditble = (field) => {
        return !isDoctor && field.editble
    }

    /**
     * This function only has to perform client side validation. Actual
     * data conversions take place in the server.
     */
    const handleChange = (value,key) => {
        if(key==="dateOfBirth") setIsOpen(!isOpen);
        setInputError('')
        if (key === 'weight') {
            let errMsg = ''
            if (user.weightUnit === 'lb') {
                if (value > 550) {errMsg = 'weight cant exceed 550 lb'}
                if (value < 88)  {errMsg = 'weight cant be less than 88 lb'}
            } else {
                if (value > 250) {errMsg = 'weight cant exceed 250 kg'}
                if (value < 40)  {errMsg = 'weight cant be less than 40 kg'}
            }

            setData(prevData => ({...prevData,[key]:value}))
            setInputError(errMsg)
            return
        }

        if (key === 'height') {
            let errMsg = ''
            if (user.heightUnit === 'ft') {
                if (value > 7.2) {errMsg = 'height cant exceed 7.2 ft'}
                if (value < 3.2)  {errMsg = 'height cant be less than 3.2 ft'}
            } else {
                if (value > 220) {errMsg = 'height cant exceed 220 cm'}
                if (value < 100) {errMsg = 'height cant be less than 100 cm'}
            }

            setData(prevData => ({...prevData,[key]:value}))
            setInputError(errMsg)
            return
        }

        if (key!=="dateOfBirth" && String(value).length>MAX_CHARS_LIMITS){
            setInputError('max characters exceed')
            return
        } 
        setData(prevData => ({...prevData,[key]:value}))
    }

    const handleEditButtonClick = (action,e) => {
        e.preventDefault();
        inputError && setInputError()
        action ==="dateOfBirth" && setIsOpen(!isOpen);
    }

    const renderField = (key)=>{
        if(key==="dateOfBirth"){
            return new Date(data[key]).toLocaleDateString('en-GB')
        }
        if(key==="weight"){
            return `${fixNumAndRemoveDecimalZero(data[key], 1)} ${user.weightUnit}`
        }
        if(key==="height"){
            return `${fixNumAndRemoveDecimalZero(data[key], 1)} ${user.heightUnit}`
        }
        return data[key]
    }

    const editButton = (action) => isEditble(fields[action]) &&
        <UncontrolledDropdown>

            <DropdownToggle style={{padding:0, boxShadow:'none',backgroundColor:'transparent'}} onClick={e=>handleEditButtonClick(action,e)} >
                <img alt="edit" src={require(`../../../assets/img/icons/Edit.svg`).default} width="25px" />
            </DropdownToggle>

            {action !== "dateOfBirth" && 
            <DropdownMenu boundary='scrollParent' className={`dropdown-menu-arrow ${action === 'gender' ? 'mt-0' : 'mt-7'}`} right>
                {fields[action].type === "select" ?
                    getSelectOptions(action )
                :
                <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="fas fa-save"/>
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input type={fields[action].type} 
                           value={data[action]}
                           onChange={e=>handleChange(e.target.value,action)} />
                </InputGroup>
                }
                <small style={{color:'#EF3054',padding:'2px 5px'}}>{inputError}</small>
                
            </DropdownMenu>
            }

            {isOpen && action ==="dateOfBirth" && 
            <div className="info-date-picker">
                <ReactDatePicker 
                    selected={new Date(data["dateOfBirth"])}
                    onChange={val=>handleChange(val,action)} 
                    filterDate={date=>date<=new Date(new Date().getTime()-18*365*24*60*60*1000)}
                    showYearDropdown
                    dropdownMode="select"
                    inline />
            </div> }

        </UncontrolledDropdown> 
            
    return (
        <Container fluid>
            <div className="profile-content-container">
                <div style={{marginLeft:'55px',marginTop:'40px',marginBottom:'40px'}} >
                    {!isDoctor && <div style={{fontSize:'large',paddingBottom:'10px',color:'#053f7f'}} >Edit your personal info</div>}
                    {Object.keys(fields).map(key=>{
                        return(
                            <Row key={key} className={`personal-info-row ${isEditble(fields[key]) ? 'row-editble' : '' }`}>
                                <Col style={{color:'#000',fontWeight:200}} > {fields[key].title} </Col>
                                <Col className={fields[key].type === 'select' ? 'text-capitalize' : ''}> {renderField(key)} </Col>
                                <Col xl="1" > 
                                    {editButton(key)}
                                </Col>
                            </Row>
                        )
                    })}
                    <div style={{display:'flex',width:'50%',marginTop:'20px'}}>
                        <small style={{color:'#EF3054',padding:'2px 5px'}}>{saveError?.join(', ')}</small>
                        <Button className="text-white"
                                onClick={handleSave} 
                                style={{display:unsavedChanges?'block':'none',padding:'0.425rem 1.05rem',backgroundColor:'#3873AE',marginLeft:'auto'}}>
                            Save
                        </Button>
                    </div>
                </div>
                
            </div>
        </Container>
    )
}


const mapStateToProps = ({unsavedChanges}) => ({ unsavedChanges })
const mapDispatchToProps = { setUnsavedChanges }
export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo)
