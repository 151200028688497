import { Modal,Button} from "reactstrap"

export default function SaveChangesGuard({isOpen, toggleModal,continueLink, onConfirm, onLeave}) {

    return (
        <Modal
            className="modal-dialog-centered" size="md"
            /* contentClassName="bg-gradient-danger" */
            isOpen={isOpen}
            toggle={()=>toggleModal(undefined)}
            >

              <div className="modal-body" style={{padding:'40px 30px',fontSize:'large'}}>
                <div className="text-black" >
                    You about to leave the page without saving
                </div>
              </div>
              <div className="modal-footer" style={{paddingTop:0}} >
                <div className="bg-blue" style={{height:'1px', width:'99%', margin:'auto'}} />
                <div style={{marginTop:'25px'}} >
                <Button outline color="3873AE" type="button" onClick={onLeave} style={{padding:'0.36rem 1.05rem'}}>
                    Leave without saving
                </Button>
                <Button
                  className="text-white"
                  style={{padding:'0.425rem 1.05rem',backgroundColor:'#3873AE'}}
                  data-dismiss="modal"
                  type="button"
                  onClick={onConfirm}
                >
                  Save changes
                </Button>
                </div>
                
              </div>
        </Modal>
    )
}

