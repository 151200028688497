import { RealtimeTempReportComponent } from "@cardiacsense/report-templates-npm";
import React from "react";

export default function RealtimeReportComponent(){
    const data = localStorage.getItem("realtimeData")
return (
    <div style={{paddingTop: '5%'}}>
        <RealtimeTempReportComponent data={JSON.parse(data)} isPdf={false} />
    </div>
    )
}