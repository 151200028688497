import ToggleSwitch from 'components/Inputs/switch-toggle/switch-toggle'
import React from 'react'
import Checkbox from 'react-custom-checkbox'
import TrashIcon from '../../../assets/img/icons/trash.svg'
import { daysName } from 'consts'
const DAYS = [1, 2, 3, 4, 5, 6, 7]

function ReminderForm({ reminder, reminderIndex, updateReminder, deleteReminders }) {
    const handleChoseTime = (time) => {
        updateReminder(reminderIndex, { time });
    };

    const getSortedDays = (days) => {
        const sortedDays = days?.split('')?.sort((a, b) => +a - +b);
        return sortedDays?.join('');
    };

    const handleClickDay = (day) => {
        let days = reminder?.days;
        days?.includes(day) ? (days = days.replace(day, '')) : (days += day);
        updateReminder(reminderIndex, { days: getSortedDays(days) })
    };

    const updateEveryDay = () => {
        const reminderDays = reminder?.days;
        const days = reminderDays?.length === 7 ? '' : '1234567';
        updateReminder(reminderIndex, { days })
    };

    const toggleAlarm = (is_alarm_on) => {
        updateReminder(reminderIndex, { is_alarm_on })
    };

    if (reminder.deleted) {
        return <></>
    }

    return (
        <div key={reminderIndex} className={`reminder ${reminder.is_alarm_on ? 'alarm-on' : 'alarm-off'}`}>
            <div className='d-flex col-9 p-0'>
                <input className='me-3' onInput={(e) => handleChoseTime(e.target.value)} type='time' value={reminder.time} />
                <Checkbox
                    containerClassName='m-0 wrap-every-radio'
                    className='check-every-radio'
                    checked={reminder.days?.length === 7}
                    onChange={() => updateEveryDay()}
                    borderColor="#707070"
                    borderWidth={2}
                    borderRadius={100}
                    size={20}
                    label={'Every day'}
                    key={reminderIndex} />
            </div>
            <div className='d-flex align-items-center'>
                <ToggleSwitch onChange={(active) => toggleAlarm(active)} active={reminder.is_alarm_on} />
                <span onClick={() => deleteReminders([reminderIndex])} className='cursor-pointer ml-4'><img width={20} alt='delete' src={TrashIcon} /></span>
            </div>
            <div className='reminder-days'>
                {DAYS.map((d) =>
                    <span key={d} onClick={() => handleClickDay(d)} className={`reminder-day ${reminder?.days?.includes(d) && 'selected'}`}>{daysName[d]}</span>
                )}
            </div>
        </div>
    )
}

export default ReminderForm