import { renderMedicalQuestionAnswer as renderAnswer } from "./formaters"

export const questionsScore = (user) => {
        const question2Answer = renderAnswer('question2',user.question2)
        const question4Answer = renderAnswer('question4',user.question4)
        const question6Answer = renderAnswer('question6',user.question6)
        const question9Answer = renderAnswer('question9',user.question9)
        const question11Answer = renderAnswer('question11',user.question11)
        
        const getUserAge = (dob) => {
            if (!dob) {
              return 0;
            }
            let yearOfBirth = dob.split('-')[0];
            let currYear = new Date().getFullYear();
            if (yearOfBirth) {
              return currYear - Number(yearOfBirth);
            }
            return 0;
          };
        const { gender, dateOfBirth } = user;
        let age = getUserAge(dateOfBirth)
        let score = 0;
        if (user.gender === 'female') {
          score += 1;
        }
        if (question2Answer === 'Yes') {
          // CHF
          score += 1;
        }
        if (question4Answer === 'Yes') {
          // Hypertension
          score += 1;
        }
        if (question6Answer === 'Yes') {
          // Diabetes
          score += 1;
        }
        if (question9Answer === 'Yes') {
          // Stroke
          score += 1;
        }
        if (question11Answer === 'Yes') {
          // Vascular Disease
          score += 1;
        }
        if (age > 64 && age <= 74) {
          score += 1;
        }
        if (age > 74) {
          score += 2;
        }
        return score;
} 