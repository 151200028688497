import React from 'react'

function FormEdit({ formFields, data, onSubmit, onInput, onDelete, setIsEdit, isEdit }) {
    const handleInput = (e) => {
        const value = e.target.type === 'checkbox' ? !e.target.checked : e.target.value
        onInput(prevData => ({ ...prevData, [e.target.name]: value }))
    }

    const handleSubmit = async (e) => {
        console.log('submited')
        e.preventDefault()
        onSubmit()
    }

    const cancelEdit = () => {
        setIsEdit(false)
        // setNewData(data)
    }

    const renderField = (fieldKey) => {
        const { editble, title, type } = formFields[fieldKey]
        return (
            <div className='form-group'>
                <label htmlFor={fieldKey}>{title}: </label>
                <input disabled={!isEdit || !editble} name={fieldKey} checked={data[fieldKey] === true} type={type} value={data[fieldKey]} onInput={(e) => handleInput(e)} className={type === 'checkbox' ? '' : 'form-control'} id={fieldKey} placeholder={title} />
            </div>
        )

    }

    const handleDelete = () => {
        const toDelete = window.confirm('are you shure you want to delete this configuration?')
        if (toDelete) alert('TODO: create delete func')
    }

    return (
        <div className='wrap-form-edit'>
            <div className=''>
                <div className='config-actions row mb-3'>
                    <button onClick={() => setIsEdit(!isEdit)} className='btn btn-secondary mr-2'>
                        Edit
                    </button>
                    <button onClick={() => handleDelete()} className='btn btn-danger mr-2'>
                        Delete
                    </button>
                    {isEdit &&
                        <div className='edit-form-actions'>
                            <button onClick={handleSubmit} disabled={!isEdit} className='btn btn-primary'>Save</button>
                            <button onClick={() => cancelEdit()} disabled={!isEdit} type='button' className='btn btn-dark'>Cancel</button>
                        </div>
                    }
                </div>

                <form className='form w-50'>
                    {Object.keys(formFields).map(fieldKey => renderField(fieldKey))}
                </form>
            </div>
        </div>
    )
}

export default FormEdit