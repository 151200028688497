
import React,{useEffect, useRef, useState} from "react";
import ReactDOM from "react-dom/client";
import Cookies from "js-cookie";
import { BrowserRouter, Route, Switch,useHistory } from "react-router-dom";
import {Provider, useDispatch} from 'react-redux'

import "react-datepicker/dist/react-datepicker.css";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/css/argon-dashboard-react.scss";
import "assets/css/general.css";

import PatientLayout from "layouts/Patient.js";
import AuthLayout from "layouts/Auth.js";
import DoctorLayout from "layouts/Doctor"
import axios from "axios";
import { setUserToState } from "store/actions";
import moment from "moment";
import AdminLayout from "layouts/Admin";
import { store } from "store";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {

  const [user, setUser] = useState()
  const isAutoLogout = useRef(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const signoutTime = {doctor: 60*60*1000, patient: 15*60*1000, admin: 15*60*1000}
  const warningTime = 15000;
  let warnTimeout;
  let logoutTimeout;
  const jwToken = extractJwtTokenFromUrl() || localStorage.getItem('jwtToken')

  console.log('CardiacSense Portal '+process.env.REACT_APP_VERSION)
  
  const onStartSession = () => {
    // this func checks if we should clean the jwt if exist, for example if the user closed the browser
    if(!jwToken) return
    const last_session_event = localStorage.getItem('last_session_event') || new Date()
    const secconds = 20
    const seccondsDiff = (new Date(last_session_event).getTime() + (secconds * 1000) - new Date().getTime()) / 1000
    if(seccondsDiff < 0) logout()
  }

  const updateLastSessionEvent = () => {
      localStorage.setItem('last_session_event', new Date())
  }

  const updateLastSessionEventInterval = () => {
    return setInterval(() => {
      updateLastSessionEvent()
    }, 5000)
  }

  useEffect( () => {
    if (document.readyState === "complete") {
      onStartSession();
    } else {
      window.addEventListener('load', onStartSession);
    }
    window.addEventListener('beforeunload', updateLastSessionEvent)

    const fetchUserData = async() => {
      if(!jwToken) return;
      try{
        const result = await axios.post(`${process.env.REACT_APP_API_URL}/app/auth/login-by-jwt`,{jwtoken:jwToken},{ headers: {'Authorization': `Bearer ${jwToken}`} })
        const {role, doctor, patient, username, id: userId, token:jwtToken,accessToken} = result.data.data;
        localStorage.setItem('jwtToken', jwtToken)
        const appConfigs = (role === 'patient') ? await axios.get(`${process.env.REACT_APP_API_URL}/app/patient/app-configuration-jwt`,{ headers: {'Authorization': `Bearer ${jwtToken}`} }) : {}//its may just in patient case
        const patientOrDoctorData = doctor || patient || {}
        if(patientOrDoctorData){
          const getUser = {data:{...patientOrDoctorData,userId,username ,accessToken,appConfigs:appConfigs?.data}, type:role||'patient', jwtToken}
          delete getUser.data.peripheralHistory
          Cookies.set('user', JSON.stringify(getUser) , {expires:1} )
          localStorage.setItem('lastEntry',moment().utc().format('YYYY-MM-DD HH:mm'))
          setUser(getUser) //add return of role on api-server-login
          dispatch ( setUserToState(getUser) )
        }
      }
      catch (err){
        console.log('err',err) 
        console.log('err data',err?.response?.data) 
        if(err?.response?.data?.objectOrError?.action === 1) logout()// incorrrect jwtoken from jwt guard
      }
    }
    fetchUserData()
  },[])

  useEffect(() => {
    if(!user?.type || isAutoLogout.current) return
    isAutoLogout.current = true // for blocking duplicate listeners
    initAutoLogout()
  }, [user])
  
  const getLayout = (props) => {
    if(!user && jwToken) return;
    if(!user){
       return <AuthLayout {...props} setUser={setUser} />
    }
    if(user.type === 'patient'){
      return <PatientLayout {...props} user={user} setUser={setUser} />
    }
    if(user.type === 'doctor'){
      return <DoctorLayout {...props} user={user} setUser={setUser} />
    }  
    if(user.type === 'admin'){
      return <AdminLayout {...props} user={user} setUser={setUser} />
    }  
  }

  function extractJwtTokenFromUrl() {
    //token from url is when an integrator request direct link to portal
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const jwtFromUrl = urlParams.get('token');
    console.log('jwtFromUrl', jwtFromUrl)
    removeJwtTokenFromUrl()
    return jwtFromUrl
  }

  function removeJwtTokenFromUrl() {
    // Get the current URL parts
    const origin = window.location.origin;
    const pathname = window.location.pathname;
    // Construct the new URL without query parameters
    const newUrl = `${origin}${pathname}`;
    //replace url without refresh page
    window.history.replaceState({}, '', newUrl);
  }
  
  const warn = () => {
    console.log('Warning');
};

function logout() {
    updateLastSessionEvent()
    clearInterval(updateLastSessionEventInterval)
    window.location.reload(false)
    Cookies.remove('user') ;
    setUser()
    localStorage.removeItem('jwtToken')
    history.push('/auth/login')
}

const setTimeouts = () => {
    warnTimeout = setTimeout(warn, warningTime);
    logoutTimeout = setTimeout(logout, signoutTime[user.type]);
};

const clearTimeouts = () => {
    if (warnTimeout) clearTimeout(warnTimeout);
    if (logoutTimeout) clearTimeout(logoutTimeout);
};

const resetTimeout = () => {
    clearTimeouts();
    setTimeouts();
};

const initAutoLogout = () => {
  updateLastSessionEventInterval()
  const events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress'
  ];
  for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
  }
};

  return (
    <BrowserRouter>
    <ToastContainer />
    <Switch>
      <Route path="/" render={(props) => getLayout(props)} />
   </Switch>
  </BrowserRouter>
  )
}


const root = ReactDOM.createRoot(
 document.getElementById('root')
);
root.render(
    <Provider store = {store}>
      <App />
    </Provider>);

