import {MEDICAL_QUESTION_ANSWERS, AF_MEDICAL_QUESTION_ANSWERS,EPILEPSY_MEDICAL_QUESTION_ANSWERS, dashboardKeys} from 'consts'
import { store } from '../store'
import { setPatientMeasurments } from 'store/actions'
import { isPassedFromMidnight } from './validations'
const dispatch = store.dispatch

export const timeFormatter = (n) =>{
    return n? new Date(n).toLocaleString('en-GB', {hour:'2-digit', minute:'2-digit' }) : undefined
}

export const renderMedicalQuestionAnswer = (condition,rawAnswer)=>{
    if (!(condition&&rawAnswer)) return null
    const ansNumber = rawAnswer.split('.').slice(-1)[0]
    if(condition ==='A-Fib'){
        return AF_MEDICAL_QUESTION_ANSWERS[ansNumber]
    }
    else if(condition === 'Epilepsy'){
      return EPILEPSY_MEDICAL_QUESTION_ANSWERS[ansNumber]
    }
    else {
       return MEDICAL_QUESTION_ANSWERS[ansNumber] 
    }
    
}

export const getMedicalAnswers = (patient) => {
  const questionMap = {
    'sign_in.phase_3.all_medical_questions.question_1.answers.ans_1': 'no',
    'sign_in.phase_3.all_medical_questions.question_1.answers.ans_2': 'yes',
    'sign_in.phase_3.all_medical_questions.question_1.answers.ans_3': "dont_know",
    'sign_in.phase_3.all_medical_questions.question_1.question': 'Do you have COPD?',
    'sign_in.phase_3.all_medical_questions.question_1.question_short': 'COPD',
    'sign_in.phase_3.all_medical_questions.question_10.answers.ans_1': 'no',
    'sign_in.phase_3.all_medical_questions.question_10.answers.ans_2': 'yes',
    'sign_in.phase_3.all_medical_questions.question_10.answers.ans_3': "dont_know",
    'sign_in.phase_3.all_medical_questions.question_10.question': 'Have you ever been diagnosed with tachycardia?',
    'sign_in.phase_3.all_medical_questions.question_10.question_short': 'Tachycardia',
    'sign_in.phase_3.all_medical_questions.question_11.answers.ans_1': 'no',
    'sign_in.phase_3.all_medical_questions.question_11.answers.ans_2': 'yes',
    'sign_in.phase_3.all_medical_questions.question_11.answers.ans_3': "dont_know",
    'sign_in.phase_3.all_medical_questions.question_11.question':
      'Have you ever been diagnosed with PVCs? (premature ventricular contractions)',
    'sign_in.phase_3.all_medical_questions.question_11.question_short': 'PVC',
    'sign_in.phase_3.all_medical_questions.question_12.answers.ans_1': 'no',
    'sign_in.phase_3.all_medical_questions.question_12.answers.ans_2': 'yes',
    'sign_in.phase_3.all_medical_questions.question_12.answers.ans_3': "dont_know",
    'sign_in.phase_3.all_medical_questions.question_12.question':
      'Have you ever been diagnosed with PACs? (premature atrial contractions)',
    'sign_in.phase_3.all_medical_questions.question_12.question_short': 'PAC',
    'sign_in.phase_3.all_medical_questions.question_2.answers.ans_1': 'no',
    'sign_in.phase_3.all_medical_questions.question_2.answers.ans_2': 'yes',
    'sign_in.phase_3.all_medical_questions.question_2.answers.ans_3': "dont_know",
    'sign_in.phase_3.all_medical_questions.question_2.question': 'Have you ever had CHF?',
    'sign_in.phase_3.all_medical_questions.question_2.question_short': 'CHF',
    'sign_in.phase_3.all_medical_questions.question_3.answers.ans_1': 'paroxysmal',
    'sign_in.phase_3.all_medical_questions.question_3.answers.ans_2': 'persistent',
    'sign_in.phase_3.all_medical_questions.question_3.answers.ans_3': 'long_stand_persist',
    'sign_in.phase_3.all_medical_questions.question_3.answers.ans_4': 'permanent',
    'sign_in.phase_3.all_medical_questions.question_3.answers.ans_5': 'yes_unknown',
    'sign_in.phase_3.all_medical_questions.question_3.answers.ans_6': 'no',
    'sign_in.phase_3.all_medical_questions.question_3.answers.ans_7': "dont_know",
    'sign_in.phase_3.all_medical_questions.question_3.question': 'Have you ever had A-Fib?',
    'sign_in.phase_3.all_medical_questions.question_3.question_short': 'A-Fib',
    'sign_in.phase_3.all_medical_questions.question_4.answers.ans_1': 'no',
    'sign_in.phase_3.all_medical_questions.question_4.answers.ans_2': 'yes',
    'sign_in.phase_3.all_medical_questions.question_4.answers.ans_3': "dont_know",
    'sign_in.phase_3.all_medical_questions.question_4.question': 'Do you have hypertension?',
    'sign_in.phase_3.all_medical_questions.question_4.question_short': 'Hypertension',
    'sign_in.phase_3.all_medical_questions.question_5.answers.ans_1': 'no',
    'sign_in.phase_3.all_medical_questions.question_5.answers.ans_2': 'yes',
    'sign_in.phase_3.all_medical_questions.question_5.answers.ans_3': "dont_know",
    'sign_in.phase_3.all_medical_questions.question_5.question': 'Have you been diagnosed with sleep apnea?',
    'sign_in.phase_3.all_medical_questions.question_5.question_short': 'Sleep apnea',
    'sign_in.phase_3.all_medical_questions.question_6.answers.ans_1': 'no',
    'sign_in.phase_3.all_medical_questions.question_6.answers.ans_2': 'yes',
    'sign_in.phase_3.all_medical_questions.question_6.answers.ans_3': "dont_know",
    'sign_in.phase_3.all_medical_questions.question_6.question': 'Do you have diabetes?',
    'sign_in.phase_3.all_medical_questions.question_6.question_short': 'Diabetes',
    'sign_in.phase_3.all_medical_questions.question_7.answers.ans_1': 'no',
    'sign_in.phase_3.all_medical_questions.question_7.answers.ans_2': 'yes',
    'sign_in.phase_3.all_medical_questions.question_7.answers.ans_3': "dont_know",
    'sign_in.phase_3.all_medical_questions.question_7.question': 'Do you have asthma?',
    'sign_in.phase_3.all_medical_questions.question_7.question_short': 'Asthma',
    'sign_in.phase_3.all_medical_questions.question_8.answers.ans_1': 'no',
    'sign_in.phase_3.all_medical_questions.question_8.answers.ans_2': 'yes',
    'sign_in.phase_3.all_medical_questions.question_8.question': 'Do you have epilepsy?',
    'sign_in.phase_3.all_medical_questions.question_8.question_short': 'Epilepsy',
    'sign_in.phase_3.all_medical_questions.question_9.answers.ans_1': 'no',
    'sign_in.phase_3.all_medical_questions.question_9.answers.ans_2': 'yes',
    'sign_in.phase_3.all_medical_questions.question_9.answers.ans_3': "dont_know",
    'sign_in.phase_3.all_medical_questions.question_9.question': 'Have you ever been diagnosed with bradycardia?',
    'sign_in.phase_3.all_medical_questions.question_9.question_short': 'Bradycardia',
  };
  return {
    question1: questionMap[patient.question1],
    question2: questionMap[patient.question2],
    question3: questionMap[patient.question3],
    question4: questionMap[patient.question4],
    question5: questionMap[patient.question5],
    question6: questionMap[patient.question6],
    question7: questionMap[patient.question7],
    question8: questionMap[patient.question8],
    question9: questionMap[patient.question9],
    question10: questionMap[patient.question10],
    question11: questionMap[patient.question11],
    question12: questionMap[patient.question12],
  };
};

export const unitsConvert = (unit) =>{
    if(unit === 'lb'){
        return (value) => value? (value*2.2).toFixed(0) : null
    }
    if(unit==='mmol/L'){
       return (value) => value? (value/18).toFixed(1)  : null 
    } 
    if (unit === '°F'){
        return (value) => value?  (value * (9 / 5) + 32).toFixed(1) : null 
    } 
    return (value)=>value
}

export const renderThreshold = (threshold,values,unit)=>{
    const {low,high} = values
    switch (threshold) {
        case 'Blood pressure':
            const {low_SYS,high_SYS,low_DIA,high_DIA} = values
            return `SYS ${low_SYS}-${high_SYS} DIA ${low_DIA}-${high_DIA} ${unit}`
        default:
            return `${unitsConvert(unit)(low)}-${unitsConvert(unit)(high)} ${unit}`
    }
}

export const capitalizeWords = (str) => {
    return str.split(' ').map(element => {
      return element.charAt(0).toUpperCase() + element.substring(1).toLowerCase();
    }).join(' ');
  }

export const convertUnderScoreToSpace = (key) => {
    if(!key) return key
    return key.replaceAll('_', ' ')
}

export const convertSpaceToUnderScore = (key) => {
    if(!key) return key
    return key.replaceAll(' ', '_')
}

export const parseBoolean = (val) => {
  return val === true || val === 'true';
}


const shouldDisplayDbVal = (key, dbItem, patient) => {
  if (key === 'weight' && !patient?.question2?.includes('.ans_2')) {
    //case of the user dosn't have CHF we show the weight even if midnight past
    return true
  }
  return !isPassedFromMidnight(dbItem?.timestamp)
}

export const getDbMeasureVal = (key, dbItem, patient) => {
  if(shouldDisplayDbVal(key, dbItem, patient)){
    return dbItem?.value
  }
  return null
}

export const getDbMeasureTimestamp = (key, dbItem, patient) => {
  if(shouldDisplayDbVal(key, dbItem, patient)){
    return dbItem?.timestamp
  }
  return null
}

export const setupMeasurementsFromServerToDashboard = (dbMeasurements, patient, units) => {
  if(!dbMeasurements) return null
  const dashboardMeasurements = {}
  Object.keys(dbMeasurements)?.map(m => {
    const dashKey = dashboardKeys[m]
    if(!dashKey || !shouldDisplayDbVal(m, dbMeasurements[m], patient)) return null
    const unit = units[dashKey]
    dashboardMeasurements[dashKey] = {...dbMeasurements[m], unit, value: unitsConvert(unit)(dbMeasurements[m]?.value)}
  })
  dispatch( setPatientMeasurments(dashboardMeasurements) )
  return dashboardMeasurements
}
