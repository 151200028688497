import {Card, CardBody, CardTitle} from "reactstrap"

export default function NotificationsBox({alerts,timestamp}) {
    const alertsText = [alerts?.['AF']&&'A-Fib', alerts?.['Pulse Rate']==='High PR'?'High PR': '', alerts?.['Pulse Rate']==='Low PR'?'Low PR' :''].filter(alert=>alert)
    return (
        <Card className="card-stats mb-4 mb-xl-0" style={alertsText.length?{border:'3px solid #f5365c'}:{}}>
            <CardBody style={{padding:'3px 16px'}} >
            <CardTitle className="text-center" style={{marginBottom:'5px'}} >
                Notifications
            </CardTitle>
            <div className="row justify-content-center align-items-center" style={{ height:'100px'}} >
               <div className="col col-auto lead" style={{fontSize:alertsText.length>1?'32px':'40px',marginTop:'0px', color:"#f5365c"}} > 
                {alertsText.map(alert=><div key={alert} style={{textAlign:'center'}} >{alert}</div>)}  
                </div>
            </div>
            <div className="text-center text-muted">
               <small>{'‎'}</small> {/* empty string for same heigt as meaurments */}
            </div> 
            </CardBody>
        </Card>
    )
}