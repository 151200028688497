/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react'
import ArFilterIcon from '../../assets/img/icons/arrythmia-filter.svg'
import { ARRYTHMIA_EVENTS, ARRYTHMIA_EVENT_TYPES } from 'consts'
import './arrythmia-filter.scss'
import ArrowDown from '../../assets/img/icons/arrow-down.svg'
import useOnClickOutside from 'hooks/useOnClickOutside'

const defaultArrythmiaToDisplay = {
    [ARRYTHMIA_EVENT_TYPES.SINUS_TACHYCARDIA]: true,
    [ARRYTHMIA_EVENT_TYPES.SINUS_BRADYCARDIA]: true,
    [ARRYTHMIA_EVENT_TYPES.ATRIAL_FIBRILLATION]: true,
    [ARRYTHMIA_EVENT_TYPES.ATRIAL_FLUTTER]: true,
    [ARRYTHMIA_EVENT_TYPES.CARDIO_PAUSE]: true,
}

function ArrythmiaFilter({ selectedArrythmias, setSelectedArrythmias, isArrythFilterOpen, setIsArrythFilterOpen }) {

    useEffect(() => {
        if (!Object.keys(selectedArrythmias).length) {
            let initArrythmias = JSON.parse(localStorage.getItem('selectedArrythmias') || '{}')
            if (!Object.keys(initArrythmias).length) {
                initArrythmias = defaultArrythmiaToDisplay
            }
            handleSelectArrythmias(getMapFilteredArrythmias(false, initArrythmias))
        }
    }, [selectedArrythmias])

    const handleSelectArrythmias = (tagsObj) => {
        localStorage.setItem('selectedArrythmias', JSON.stringify(tagsObj))
        setSelectedArrythmias(tagsObj)
    }

    const refSortMenu = useRef()
    useOnClickOutside(refSortMenu, setIsArrythFilterOpen);

    const handleClickAr = (ar) => {
        const newStatus = !selectedArrythmias[ar]
        handleSelectArrythmias({ ...selectedArrythmias, [ar]: newStatus })
    }

    const isSelectedAllTags = () => Object.values(selectedArrythmias).length && Object.values(selectedArrythmias)?.filter(ar => !ar).length === 0

    const handleClickAll = () => {
        const isSelectedAll = isSelectedAllTags()
        handleSelectArrythmias(getMapFilteredArrythmias(!isSelectedAll))
    }

    const shouldDisplayAr = (arEvent) => {
        const ar = ARRYTHMIA_EVENTS[arEvent]
        if (ar?.src === 'cardiacsense') {
            //we display just cardiolyse alerts
            return false
        }
        return true
    }

    function getMapFilteredArrythmias(status, selectedFromStorage) {
        selectedFromStorage = selectedFromStorage || {}
        const mapArryth = {}
        for (const ar in ARRYTHMIA_EVENTS) {
            if (shouldDisplayAr(ar)) {
                mapArryth[ar] = status
            }
        }
        return { ...mapArryth, ...selectedFromStorage }
    }

    function getSortedArrythmiaTags() {
        //NORMAL_SINUS_RHYTHM has a different button
        const filtered = Object.keys(selectedArrythmias).filter(ar => +ar !== +ARRYTHMIA_EVENT_TYPES.NORMAL_SINUS_RHYTHM)
        const sorted = filtered.map(ar => ARRYTHMIA_EVENTS[ar]).sort((a, b) => {
            return a.short.localeCompare(b.short)
        })
        return sorted
    }

    const getSelectedTagsText = () => {
        const selectedTags = Object.keys(selectedArrythmias).filter(key => selectedArrythmias[key]).map(et => ARRYTHMIA_EVENTS[et]?.short)
        let text = selectedTags.join(', ')
        if (isSelectedAllTags()) {
            text = 'All'
        }
        else if (selectedTags.length > 4) {
            text = text.substring(0, 21) + '...'
        }
        return text
    }

    return (
        <div ref={refSortMenu} className='arrythmia-filter'>
            <div className='wrap-filter-input' onClick={() => setIsArrythFilterOpen(!isArrythFilterOpen)}>
                <img src={ArFilterIcon} alt='ar-filter' />
                <div className='px-2'>Arrhythmias</div>
                <span className='filter-input'>{getSelectedTagsText()}</span>
                <img width={15} src={ArrowDown} alt='search' />
            </div>

            {isArrythFilterOpen && <div className='wrap-ar-tags'>
                <div className='ar-tags-title'>
                    <img src={ArFilterIcon} alt='ar-filter' />
                    <span className='ps-2'>Arrhythmias</span>
                </div>
                <hr />
                <div className='ar-tags'>
                    {getSortedArrythmiaTags().map((ar) =>
                        <span
                            key={ar.type}
                            onClick={() => handleClickAr(ar.event_type)}
                            className={`ar-tag ${selectedArrythmias[ar.event_type] && 'selected'}`}
                        >
                            <span className="tag-hover">{ARRYTHMIA_EVENTS[ar.event_type]?.desc}</span>
                            {ar.short}
                        </span>
                    )}
                    <hr />
                    <span
                        onClick={() => handleClickAr(ARRYTHMIA_EVENT_TYPES.NORMAL_SINUS_RHYTHM)}
                        className={`ar-tag ${selectedArrythmias[ARRYTHMIA_EVENT_TYPES.NORMAL_SINUS_RHYTHM] && 'selected'}`}
                    >
                        {ARRYTHMIA_EVENTS[ARRYTHMIA_EVENT_TYPES.NORMAL_SINUS_RHYTHM]?.desc}
                    </span>
                    <span className='ar-divider'>|</span>
                    <span
                        key={'all'}
                        onClick={handleClickAll}
                        className={`ar-tag ${isSelectedAllTags() && 'selected'}`}
                    >Show all</span>
                </div>
            </div>}
        </div>
    )
}

export default ArrythmiaFilter