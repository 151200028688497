
import { Row } from "reactstrap";

const Footer = () => {
  return (
    <footer className="footer">
      <Row className="align-items-center justify-content-xl-between">

      </Row>
    </footer>
  );
};

export default Footer;
