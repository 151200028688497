const createEmptyData = (startDay,keys)=>{
  const nullValues = Object.fromEntries(keys.map(key=>[key,null]))
  const datesRange = Array.from(Array(30).keys(), i => new Date(startDay.getTime() + i*24*60*60*1000))
  return datesRange.map(date=>({ date, ...nullValues }))
}

const CANDLE_KEYS = ['high','low','avg']
const SPOT_KEYS = ['value']

export const emptyMonthlyData =(day)=> {
  const date = new Date(day)
  return {
    onLoad: true,
    pulse_data: createEmptyData(date,CANDLE_KEYS),
    systolic_data: createEmptyData(date,CANDLE_KEYS),
    systolic_manual_data: createEmptyData(date,CANDLE_KEYS),
    systolic_external_data: createEmptyData(date,CANDLE_KEYS),
    diastolic_data: createEmptyData(date,CANDLE_KEYS),
    diastolic_manual_data: createEmptyData(date,CANDLE_KEYS),
    diastolic_external_data: createEmptyData(date,CANDLE_KEYS),
    glucose_data: createEmptyData(date, SPOT_KEYS),
    glucose_manual_data: createEmptyData(date, SPOT_KEYS),
    hrv_data:{
      lfhf: createEmptyData(date, SPOT_KEYS),
      rmsdd_pulse: createEmptyData(date, SPOT_KEYS)
    },
    night_respiratory_data: createEmptyData(date, SPOT_KEYS),
    night_rsi_data: createEmptyData(date, SPOT_KEYS),
    oxygenSaturation_data: createEmptyData(date, CANDLE_KEYS),
    oxygenSaturation_manual_data: createEmptyData(date, CANDLE_KEYS),
    oxygenSaturation_external_data: createEmptyData(date, CANDLE_KEYS),
    pwv_data: createEmptyData(date, CANDLE_KEYS),
    respiratory_data: createEmptyData(date, CANDLE_KEYS),
    sleep_data: createEmptyData(date, SPOT_KEYS),
    steps_data: createEmptyData(date, SPOT_KEYS),
    calories_data: createEmptyData(date, SPOT_KEYS),
    temperature_data: createEmptyData(date, SPOT_KEYS),
    temperature_external_data: createEmptyData(date, SPOT_KEYS),
    temperature_manual_data: createEmptyData(date, SPOT_KEYS),
    weight_data: createEmptyData(date, SPOT_KEYS),
    weight_manual_data: createEmptyData(date, SPOT_KEYS),
  }
}

/* 

const createEmptyData = (selectedDate,keys)=>{
  const startDay = new Date(selectedDate.getTime() + -30*24*60*60*1000)
  const nullValues = Object.fromEntries(keys.map(key=>[key,null]))
  const datesRange = Array.from(Array(30).keys(), i => new Date(startDay.getTime() + i*24*60*60*1000))
  return datesRange.map(date=>({ date, ...nullValues }))
}

export const emptyMonthlyData = {
  onLoad:true,
  2:createEmptyData(new Date(),CANDLE_KEYS),
  3:createEmptyData(new Date(),CANDLE_KEYS),
  4:createEmptyData(new Date(),CANDLE_KEYS),
  5:createEmptyData(new Date(),CANDLE_KEYS),
  6:createEmptyData(new Date(),CANDLE_KEYS),
  51:createEmptyData(new Date(),CANDLE_KEYS),
  52:createEmptyData(new Date(),CANDLE_KEYS),
  53:createEmptyData(new Date(),CANDLE_KEYS),
  54:createEmptyData(new Date(),CANDLE_KEYS),

  7:createEmptyData(new Date(),SPOT_KEYS),
  29:createEmptyData(new Date(),SPOT_KEYS),
  30:createEmptyData(new Date(),SPOT_KEYS),
  55:createEmptyData(new Date(),SPOT_KEYS),
  56:createEmptyData(new Date(),SPOT_KEYS),
  57:createEmptyData(new Date(),SPOT_KEYS),
}
*/