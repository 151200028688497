import { daysName, initialMedication } from 'consts'
import React, { useEffect, useState } from 'react'
import TrashIcon from '../../../assets/img/icons/trash.svg'
import ArrowDown from '../../../assets/img/icons/arrow-down.svg'
import Clock from '../../../assets/img/icons/clock.svg'
import EditIcon from '../../../assets/img/icons/Edit.svg'
import CheckedIcon from '../../../assets/img/icons/Check.svg'
import MedicationReminderIcon from '../../../assets/img/icons/medcine_reminder.svg'
import { useSelector } from 'react-redux'
import { deleteMedications, getPatientMedications } from 'api/api-manager'
import './reminders.scss'
import ConfirmModal from 'components/Modals/confirm-modal'
import { Modal } from 'reactstrap'
import MedicationForm from './medication-form'

function Medications({ user, role = 'patient' }) {
    const [editMedication, setEditMedication] = useState()
    const [showDeleteWarn, setShowDeleteWarn] = useState()
    const [isDescSort, setIsDescSort] = useState()
    const [selectedMedications, setSelectedMedications] = useState([])
    const { patientsMedication } = useSelector(state => state)
    const medications = patientsMedication?.[user.id] || []
    const isDoctor = role === 'doctor'

    useEffect(() => {
        if (!patientsMedication?.[user.id]) {
            getPatientMedications(user.id)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const isSelected = (id) => selectedMedications.includes(id)
    const isSelectedAll = () => selectedMedications.length > 0 && selectedMedications.length === medications.length

    const toggleSelect = (id) => {
        const newSelected = isSelected(id) ? selectedMedications.filter(m => m !== id) : [...selectedMedications, id]
        setSelectedMedications(newSelected)
    }

    const toggleSelectAll = () => {
        const newSelected = isSelectedAll() ? [] : medications.map(m => m.id)
        setSelectedMedications(newSelected)
    }

    const handleDelete = async () => {
        const stringIdList = selectedMedications.join(',');
        await deleteMedications(user.id, stringIdList)
        setShowDeleteWarn(false)
        setSelectedMedications([])
    }

    const sortedMedications = () => {
        return patientsMedication?.[user.id]?.sort((a, b) => isDescSort ? b?.name.localeCompare(a?.name) : a?.name.localeCompare(b?.name))
    }

    const getSelectedMedicationsNames = () => {
        const selectedMedicationsNames = medications
            .filter((m) => isSelected(m.id))
            .map((m) => m.name)
            .join('\n');
        return selectedMedicationsNames
    }

    const getMedicationRow = (m, reminderIndex) => {
        const reminder = m.reminders[reminderIndex]
        const isFirstMedReminder = reminderIndex === 0;
        const isLastMedReminder = m.reminders.length === 0 || reminderIndex === m.reminders.length-1;
        return (<tr key={reminder?.id} className={`medication-row ${isSelected(m.id) && 'selected'} ${isLastMedReminder && 'last-row'}`}>
            {!isDoctor && <td className='td-checkbox'>{isFirstMedReminder && <span onClick={() => toggleSelect(m.id)} className='custom-checkbox'><img src={CheckedIcon} alt='checked' /></span>}</td>}
            <td className='td-name'>{isFirstMedReminder && m.name}</td>
            <td className='td-dosage'>{isFirstMedReminder && m.dosage}</td>
            <td className='td-hours'>{reminder?.time}</td>
            <td className='td-frequency'><span className='days'>{reminder?.days?.length === 7 ? 'Every day' : reminder?.days?.split('')?.map(d => daysName[d]).join(' | ')}</span></td>
            {!isDoctor && <td className='td-edit'>{isFirstMedReminder &&<img className='cursor-pointer' onClick={() => setEditMedication(m)} src={EditIcon} alt='Edit' />}</td>}
        </tr>)
    }

    return (
        <div className='wrap-reminders-page'>
            {showDeleteWarn && <ConfirmModal
                text={`These medications will permanently delete:`}
                onConfirm={handleDelete}
                onCancel={() => setShowDeleteWarn(false)}
                displayTopLine
                cancelButtonText={'Cancel'}
                confirmButtonText={'Delete'}
                subText={getSelectedMedicationsNames()}
                contentAlign='center'
                titleWheight='light'
            />}
            <Modal
                className="modal-dialog-centered"
                contentClassName=""
                isOpen={!!editMedication}
                toggle={() => setEditMedication()}
            >
                <MedicationForm patient={user} editMedication={editMedication} closeModal={setEditMedication} />
            </Modal>
            <div className='top-section'>
                <span className='page-title'>
                    {!isDoctor && 'Edit your '}medications list
                    {!isDoctor && <span>Editing medications list requires sync to mobile app</span>}
                </span>
                {!isDoctor && <div className='reminders-actions'>
                    <button onClick={() => setEditMedication(initialMedication)} className='btn add-medicine'>+ Add medication</button>
                    <button disabled={!selectedMedications?.length} onClick={() => setShowDeleteWarn(true)} className='btn delete-medicine'>
                        Delete
                        <span className='pl-3'><img alt='delete' src={TrashIcon} /></span>
                    </button>
                </div>}
                <div className='reminders-table-wrap'>
                    <table className='table reminders-table'>
                        <thead>
                            <tr>
                                {!isDoctor && <th className='td-checkbox'><span onClick={toggleSelectAll} className='custom-checkbox'>{isSelectedAll() && <img src={CheckedIcon} alt='checked' />}</span></th>}
                                <th className='td-name'>Medication name <img className='cursor-pointer' onClick={() => setIsDescSort(!isDescSort)} src={ArrowDown} alt='arrow down' /></th>
                                <th className='td-dosage'>Dosage</th>
                                <th className='td-reminder'>Hours<span className='info-flag mx-1'>?<span className='info-text'>Reminder will show in<br />mobile app only</span></span></th>
                                <th className='td-frequency'>Frequency</th>
                                {!isDoctor && <th className='td-edit'>Edit</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {patientsMedication?.[user.id]?.length ?
                                sortedMedications()?.map((m) =>
                                    m.reminders.length ? m.reminders.map((_r, i) => getMedicationRow(m, i)) : getMedicationRow(m, 0)
                                )
                                :
                                <tr>
                                    <td colSpan={5} className='empty-reminders-section'>
                                        <img className='mb-2' src={MedicationReminderIcon} alt='clock reminder' />
                                        {!isDoctor ?
                                            <p>Put your mediciations in the list,<br /> you can add a reminder.</p>
                                            :
                                            <p>No medications have been added to the list</p>
                                        }
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

export default Medications