import React from 'react'
import { Container } from 'reactstrap'

export default function Subscription() {
    return (
        <Container fluid>
            <div className="profile-content-container">
            Subscription
            </div>
        </Container>
    )
}
