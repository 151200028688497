import { getDbMeasureTimestamp, getDbMeasureVal } from "utils/formaters"

export const EVENT_TYPES = {
  PULSE_RATE: 2,
  RESPIRATORY_RATE: 3,
  BLOOD_PRESSURE_SYS: 4,
  BLOOD_PRESSURE_DIA: 5,
  SPO2: 6,
  BODY_TEMP: 7,
  GLUCOSE: 30
}

export const DOCTOR_TYPE = [
  'Cardiology',
  'Other'
]

export const DOCTOR_PREFIX = [
  'Dr',
  'Prof'
]

export const PATIENT_STATUS_COLORS = {
  approved: '#54C964',
  denied: '#EF3054',
  pending: '#FAA632'
}

export const PATIENT_DOCTOR_STATUS = {
  APPROVED: 'approved',
  DENIED: 'denied',
  PENDING: 'pending',
  REMOVED_BY_DOCTOR: 'removed-by-doctor',
  REMOVED_BY_PATIENT: 'removed-by-patient',
}

export const PATIENT_DOCTOR_STATUS_TEXT = {
  approved: 'Accept',
  denied: 'Declined',
  pending: 'Pending',
  "removed-by-doctor": 'Removed',
  "removed-by-patient": 'Removed by patient',
}
export const DOCTOR_TABLE_COLUMNS = [
  { label: 'Status', value: 'status' },
  { label: 'Name', value: 'name' },
  { label: 'Patient ID', value: 'idNumber' },
  { label: 'Phone number', value: 'phoneNumber' },
  { label: 'Detections', value: 'detection_alerts' },
  { label: 'Temp', value: 'Body Temp' },
  { label: 'SPO2', value: 'SpO2' },
  { label: 'BP', value: 'Blood Pressure' },
  // { label: 'RR', value: 'Respiratory Rate' },
  { label: 'PR', value: 'Pulse Rate' },
  { label: 'pin', value: 'Pin' }
]

export const UNIT_KEYS = {
  "Body Temp": 'temperatureUnit',
  "Weight": "weightUnit",
  "Glucose": "glucoseUnit"
}

export const DASHBOARD_FIRST_ROW = [
  'Pulse Rate',
  'Respiration Analysis',
  'Blood Pressure',
]

export const DASHBOARD_SECOND_ROW = [
  'SpO2',
  'Body Temp',
  'Weight',
  'Glucose'
]

export const initialMedication = {
  name: '',
  dosage: '',
  reminders: [],
};

export const daysName = {
  "1": "Sun",
  "2": "Mon",
  "3": "Tue",
  "4": "Wed",
  "5": "Thu",
  "6": "Fri",
  "7": "Sat"
}

export const MONTHLY_CHARTS_TITLES = {
  'Respiration Analysis': 'Respiratory Rate During Night'
}

export function initialMesutments(data){ 
  return ({
    isFromApp: false,
    "Pulse Rate": {
      title: 'Pulse Rate',
      unit: 'bpm'
    },
    "Respiration Analysis": {
      key: 'nightRespiratory',
      configKey: 'night_respiratory',
      title: 'Respiration Analysis',
      unit: 'rpm',
      bottomText: 'Night average',
      innerContent: {
        key: 'nightRsi',
        title: 'Night RRV',
        unit: '1/sec',
      },
    },
    "Blood Pressure": {
      title: 'Blood Pressure',
      unit: 'mmHg'
    },
    SpO2: {
      title: 'SpO2',
    },
    "Body Temp": {
      title: "Body Temp",
      unit: data.temperatureUnit || "°C"
    },
    Weight: {
      key: 'weight',
      title: 'Weight',
      unit: data.weightUnit || 'Kg'
    },
    Glucose: {
      title: 'Glucose',
      unit: data.glucoseUnit || 'mg/dL'
    },
    Steps: {
      title: 'Steps',
    },
    Calories: {
      title: 'Calories',
      unit: 'Cal'
    },
    Sleep: {
      title: 'Sleep',
      unit: 'sleeping hrs'
    }
  })
}

export const REALTIME_MEASUREMENTS = [
  {
  
    title: 'Pulse Rate',
    unit: 'bpm'
  },
  {
  
     title: 'SpO2',
     unit: '%'
  },
  {
  
   title: 'Respiratory Rate',
   unit: 'rpm'
  }
]

export const setupMesutments = (data, dataFromDb, patient) => ({
  "Pulse Rate": {
    title: 'Pulse Rate',
    unit: 'bpm',
    value: data?.['Pulse Rate']?.value,
    timestamp: data?.['Pulse Rate']?.timestamp,
    alert: data?.onAlert?.['Pulse Rate']
  },
  "Respiratory Rate": {
    title: 'Respiratory Rate',
    unit: 'rpm',
    value: data?.['Respiratory Rate']?.value,
    timestamp: data?.['Respiratory Rate']?.timestamp,
    alert: data?.onAlert?.['Respiratory Rate']
  },
  "Respiration Analysis": {
    key: 'nightRespiratory',
    configKey: 'night_respiratory',
    title: 'Respiration Analysis',
    unit: 'rpm',
    value: dataFromDb?.nightRespiratory,
    alert: false,
    bottomText: 'Night average',
    innerContent: {
      key: 'nightRsi',
      title: 'Night RRV',
      unit: '1/sec',
    },
  },
  "Blood Pressure": {
    title: 'Blood Pressure',
    unit: 'mmHg',
    value: data?.['Blood Pressure']?.value || getDbMeasureVal('blood_pressure', dataFromDb?.['blood_pressure'], patient),
    timestamp: data?.['Blood Pressure']?.timestamp || getDbMeasureTimestamp('blood_pressure', dataFromDb?.['blood_pressure'], patient),
    alert: data?.onAlert?.['Blood Pressure']
  },
  SpO2: {
    title: 'SpO2',
    value: data?.['SpO2']?.value || getDbMeasureVal('spo2', dataFromDb?.['spo2'], patient),
    timestamp: data?.['SpO2']?.timestamp || getDbMeasureTimestamp('spo2', dataFromDb?.['spo2'], patient),
    alert: data?.onAlert?.['SpO2']
  },
  "Body Temp": {
    title: "Body Temp",
    unit: data?.['Body Temp']?.unit || "°C",
    value: data?.['Body Temp']?.value || getDbMeasureVal('body_temprature', dataFromDb?.['body_temprature'], patient),
    timestamp: data?.['Body Temp']?.timestamp || getDbMeasureTimestamp('body_temprature', dataFromDb?.['body_temprature'], patient),
    alert: data?.onAlert?.['Body Temp']
  },
  Weight: {
    title: 'Weight',
    unit: data?.['Weight']?.unit || 'Kg',
    value: data?.['Weight']?.value || getDbMeasureVal('weight', dataFromDb?.['weight'], patient),
    timestamp: data?.['Weight']?.timestamp || getDbMeasureTimestamp('weight', dataFromDb?.['weight'], patient),
    alert: data?.onAlert?.['Weight']
  },
  Glucose: {
    title: 'Glucose',
    unit: data?.['Glucose']?.unit || 'mg/dL',
    value: data?.['Glucose']?.value || getDbMeasureVal('glucose', dataFromDb?.['glucose'], patient),
    timestamp: data?.['Glucose']?.timestamp || getDbMeasureTimestamp('glucose', dataFromDb?.['glucose'], patient),
    alert: data?.onAlert?.['Glucose']
  },
  Steps: {
    title: 'Steps',
    value: Number(data?.['Steps']?.value) || getDbMeasureVal('steps', dataFromDb?.['steps'], patient),
    timestamp: data?.['Steps']?.timestamp || getDbMeasureTimestamp('steps', dataFromDb?.['steps'], patient),
  },
  Calories: {
    title: 'Calories',
    unit: 'Cal',
    value: Number(data?.['Calories']?.value) || getDbMeasureVal('calories', dataFromDb?.['calories'], patient),
    timestamp: data?.['Calories']?.timestamp || getDbMeasureTimestamp('calories', dataFromDb?.['calories'], patient),
  },
  Sleep: {
    title: 'Sleep',
    unit: 'Sleeping hrs',
    value: data?.['Sleep']?.value,
    timestamp: data?.['Sleep']?.timestamp,
  },
  onAlert: data?.onAlert || {}
})

export const MEDICAL_QUESTIONS_CONDITAIONS = [
  'COPD',
  'CHF',
  'A-Fib',
  'Hypertension',
  'Sleep Apnea',
  'Diabetes',
  'Asthma',
  'Epilepsy',
  'Stroke',
  'Heart attack - MI',
  'Vascular Disease'
]

export const MEDICAL_QUESTION_ANSWERS = {
  "ans_1": "No",
  "ans_2": "Yes",
  "ans_3": "Don't Know",
}

export const EPILEPSY_MEDICAL_QUESTION_ANSWERS = {
  "ans_1": "No",
  "ans_2": "Yes",
}

export const AF_MEDICAL_QUESTION_ANSWERS = {
  "ans_1": "Paroxysmal",
  "ans_2": "Persistent",
  "ans_3": "Long-standing persistent",
  "ans_4": "Permanent",
  "ans_5": "Yes, unknown type",
  "ans_6": "No",
  "ans_7": "Don't Know",
}

const getOrderedMedicalQOptions = (question) => {
  let answers = MEDICAL_QUESTION_ANSWERS;
  if(question === 'question_3') answers = AF_MEDICAL_QUESTION_ANSWERS
  if(question === 'question_8') answers = EPILEPSY_MEDICAL_QUESTION_ANSWERS
  const orderedAnswers = Object.keys(answers).map(an => ({value: `sign_in.phase_3.all_medical_questions.${question}.answers.${an}`, label: answers[an]}))
  return orderedAnswers
}

export const profileSections = [
  { title: 'Personal Info', link: 'personal-info' },
  { title: 'Medical Info', link: 'medical-info' },
  { title: 'Medications', link: 'medications' },
  { title: 'Measurements', link: 'measurements' },
  { title: 'Thresholds', link: 'thresholds' },
  { title: 'Passwords', link: 'passwords' },
  { title: 'Subscription', link: 'subscription' },
  { title: 'Email Preferences', link: 'email-preferences' },
  { title: 'Account Deletion', link: 'account-deletion' }
]

export const reportsSections = [
  { title: 'Ask for report', link: 'marketplace' },
  { title: 'Reports archive', link: 'archive' },
]

export const genderOptions = [
  'Male',
  'Female'
]

export const timezoneOptions = Array(23).fill(0).map((e, i) => i - 11)

export const PERSONAL_INFO_FIELDS ={
  username:{title: 'Email', editble:false},
  firstName:{title: 'First Name', editble:true},
  lastName:{title: 'Last Name', editble:true},
  idNumber:{title: 'ID', editble:true},
  dateOfBirth:{title: 'Date of birth', editble:true},
  gender:{title: 'Biological gender', editble:true, type: 'select', options: genderOptions},
  weight:{title: 'Weight', editble:false, type: 'number'},
  height:{title: 'Height', editble:true, type: 'number'},
  country:{title: 'Country of residence', editble: true, type: 'select'},
  phoneNumber:{title: 'Phone number', editble:true},
  // 'Dial phone':{editble:true},
  timeZone: {title: 'Time Zone', editble:true, type: 'select', options: timezoneOptions},
  city: {title: 'City', editble:true},
  streetAddress:{title: 'Street Address', editble:true},
  postalCode: {title: 'Postal Code', editble:true},
  physicianName: {title: 'Physician name', editble:true}
}

export const DOCTOR_INFO_FIELDS = {
  username: {title: 'Email', editble:false},
  firstName: {title: 'First name', editble:true},
  nickname: {title: 'Nickname', editble: true, isOptional: true },
  lastName: {title: 'Last name', editble:true},
  prefix: {title: 'Prefix', editble:true, type: 'radio'},
  type: {title: 'Physician type', editble:true, type: 'radio'},
  postalCode: {title: 'Postal code', editble: true, type: 'number' },
  country: {title: 'Country of residence', editble: false },
  city: {title: 'City', editble: true },
  phoneNumber: {title: 'Phone number', editble: true, type: 'number' },
  professionalCode: {title: 'Unique Physician code', editble: false },
  uniqueId: {title: 'Unique user ID', editble: false },
}

export const USER_SPECIAL_FIELDS = {
  userId: {blockEdit: true},
  id: {blockEdit: true},
  gender: {options: genderOptions},
  country: {options: []},
  question1: {title: MEDICAL_QUESTIONS_CONDITAIONS[0], options: getOrderedMedicalQOptions('question_1')},
  question2: {title: MEDICAL_QUESTIONS_CONDITAIONS[1], options: getOrderedMedicalQOptions('question_2')},
  question3: {title: MEDICAL_QUESTIONS_CONDITAIONS[2], options: getOrderedMedicalQOptions('question_3')},
  question4: {title: MEDICAL_QUESTIONS_CONDITAIONS[3], options: getOrderedMedicalQOptions('question_4')},
  question5: {title: MEDICAL_QUESTIONS_CONDITAIONS[4], options: getOrderedMedicalQOptions('question_5')},
  question6: {title: MEDICAL_QUESTIONS_CONDITAIONS[5], options: getOrderedMedicalQOptions('question_6')},
  question7: {title: MEDICAL_QUESTIONS_CONDITAIONS[6], options: getOrderedMedicalQOptions('question_7')},
  question8: {title: MEDICAL_QUESTIONS_CONDITAIONS[7], options: getOrderedMedicalQOptions('question_8')},
  question9: {title: MEDICAL_QUESTIONS_CONDITAIONS[8], options: getOrderedMedicalQOptions('question_9')},
  question10: {title: MEDICAL_QUESTIONS_CONDITAIONS[9], options: getOrderedMedicalQOptions('question_10')},
  question11: {title: MEDICAL_QUESTIONS_CONDITAIONS[10], options: getOrderedMedicalQOptions('question_11')},
  // email: true,
}

export const CONFIG_FIELDS = {
  'id': {title: 'ID', editble: false },
  'name': { title: 'Name', editble: true },
  'patient_portal_enabled': { title: 'Patient portal enabled', editble: true, type: 'checkbox' },
  'physician_portal_enabled': { title: 'Physician portal enabled', editble: true, type: 'checkbox' },
}

export const CONFIG_OPTIONS = ['watch', 'manual', 'off', 'on']

export const DEFAULT_THRESHOLDS = {
  'Weight': {
    max: 220,
    min: 40,
    defaultValues: [50, 110]
  },
  'glucose': {
    max: 600,
    min: 20,
    defaultValues: [70, 140]
  },
  'SpO2': {
    max: 100,
    min: 70,
    defaultValues: [94, 100]
  },
  'Respiratory rate': {
    max: 40,
    min: 6,
    defaultValues: [8, 20]
  },
  'Pulse rate': {
    max: 220,
    min: 30,
    defaultValues: [50, 120]
  },
  'Body temperature': {
    max: 42,
    min: 35,
    defaultValues: [36, 38]
  },
  'Blood pressure': {
    sys: {
      max: 300,
      min: 70,
      defaultValues: [100, 160]
    },
    dia: {
      max: 120,
      min: 30,
      defaultValues: [40, 90]
    }
  },
}

export const YEARS = [2021, 2022, 2023,2024] //TODO make it dynamic
export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const VALID_PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*}])[A-Za-z\d!@#$%^&*.\\_\\+\\={}-]{9,}$/
export const VALID_EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i

export const REPORTS_TITLES = {
  'monthly-report': {
    title: 'Monthly report',
    subtitle: 'The report includes data from 30 days before the selected day',
    'select-date-attention': 'Reports can be generated up to six months back'
  },
  'daily-report': { title: 'Daily report','select-date-attention': 'This information can be displayed up to six month back' },
  'blood-pressure-report': { title: 'Blood pressure report' },
  'annotations-report': { title: 'Annotations table report' },
  'events-report': { title: 'Reports Archive', 'select-date-attention': 'Reports can be generated up to six months back' },
  'saturation-report': { title: 'Saturation report' },
  'af-report': { title: 'Monthly A-Fib report' },
  'sleep-apnea-report': { title: 'Sleep apnea report' },
  'pwv-report': { title: 'Pulse Wave Velocity report' },
}

export const MESSAGE_TYPE = {
  REALTIME: 'realtime',
  NOTIFICATION: 'notification',
  ALERT: 'alert',
  CHANNEL: 'channel',
  FRAMES: 'frames',
  SOCKET: 'socket',
  CONNECTION: 'connection'
};

export const CHANNEL_MESSAGE_ACTION = {
  JOIN: 'join',
  BULK_INVITE: 'bulk-invite',
  ACCEPT_BULK_INVITE: 'accept-bulk-invite',
  ACCEPT: 'accept',
  DECLINE: 'decline',
  LEAVE: 'leave',
  BULK_JOIN: 'bulk-join',
  ERROR: 'error'
};

export const SOCKET_MESSAGE_ACTION = {
  CONNECT: 'connect',
  ACCEPT: 'accept',
  REJECT: 'reject',
  DISCONNECT: 'disconnect'
}

export const REALTIME_MESSAGE_ACTION = {
  START: 'start',
  END: 'end',
  REQUEST_START: 'request-start'
}

export const NOTIFICATION_MESSAGE_ACTION = {
  PUSH: 'push',
  ACCEPT: 'accept',
  DECLINE: 'decline'
};

export const ALERT_MESSAGE_ACTION = {
  DISMISS: 'dismiss',
  SEND: 'send'
};

export const FRAMES_MESSAGE_ACTION = {
  SEND: 'send'
};

export const MESSAGE_ACTION = {
  CHANNEL_MESSAGE_ACTION,
  REALTIME_MESSAGE_ACTION,
  NOTIFICATION_MESSAGE_ACTION
};

export const MESSAGE_PLATFORM = {
  PORTAL: 'portal',
  MOBILE: 'mobile'
};

export const USER_TYPES = {
  PATIENT: 'patient',
  DOCTOR: 'doctor',
  ADMIN: 'admin'
};


export const DIAL_CODE =
  [
    {
      "name": "Afghanistan",
      "dial_code": "+93",
      "code": "AF",
      "emoji": "🇦🇫"
    },
    {
      "name": "Aland Islands",
      "dial_code": "+358",
      "code": "AX",
      "emoji": "🇦🇽"
    },
    {
      "name": "Albania",
      "dial_code": "+355",
      "code": "AL",
      "emoji": "🇦🇱"
    },
    {
      "name": "Algeria",
      "dial_code": "+213",
      "code": "DZ",
      "emoji": "🇩🇿"
    },
    {
      "name": "AmericanSamoa",
      "dial_code": "+1684",
      "code": "AS",
      "emoji": "🇦🇸"
    },
    {
      "name": "Andorra",
      "dial_code": "+376",
      "code": "AD",
      "emoji": "🇦🇩"
    },
    {
      "name": "Angola",
      "dial_code": "+244",
      "code": "AO",
      "emoji": "🇦🇴"
    },
    {
      "name": "Anguilla",
      "dial_code": "+1264",
      "code": "AI",
      "emoji": "🇦🇮"
    },
    {
      "name": "Antarctica",
      "dial_code": "+672",
      "code": "AQ",
      "emoji": "🇦🇶"
    },
    {
      "name": "Antigua and Barbuda",
      "dial_code": "+1268",
      "code": "AG",
      "emoji": "🇦🇬"
    },
    {
      "name": "Argentina",
      "dial_code": "+54",
      "code": "AR",
      "emoji": "🇦🇷"
    },
    {
      "name": "Armenia",
      "dial_code": "+374",
      "code": "AM",
      "emoji": "🇦🇲"
    },
    {
      "name": "Aruba",
      "dial_code": "+297",
      "code": "AW",
      "emoji": "🇦🇼"
    },
    {
      "name": "Australia",
      "dial_code": "+61",
      "code": "AU",
      "emoji": "🇦🇺"
    },
    {
      "name": "Austria",
      "dial_code": "+43",
      "code": "AT",
      "emoji": "🇦🇹"
    },
    {
      "name": "Azerbaijan",
      "dial_code": "+994",
      "code": "AZ",
      "emoji": "🇦🇿"
    },
    {
      "name": "Bahamas",
      "dial_code": "+1242",
      "code": "BS",
      "emoji": "🇧🇸"
    },
    {
      "name": "Bahrain",
      "dial_code": "+973",
      "code": "BH",
      "emoji": "🇧🇭"
    },
    {
      "name": "Bangladesh",
      "dial_code": "+880",
      "code": "BD",
      "emoji": "🇧🇩"
    },
    {
      "name": "Barbados",
      "dial_code": "+1246",
      "code": "BB",
      "emoji": "🇧🇧"
    },
    {
      "name": "Belarus",
      "dial_code": "+375",
      "code": "BY",
      "emoji": "🇧🇾"
    },
    {
      "name": "Belgium",
      "dial_code": "+32",
      "code": "BE",
      "emoji": "🇧🇪"
    },
    {
      "name": "Belize",
      "dial_code": "+501",
      "code": "BZ",
      "emoji": "🇧🇿"
    },
    {
      "name": "Benin",
      "dial_code": "+229",
      "code": "BJ",
      "emoji": "🇧🇯"
    },
    {
      "name": "Bermuda",
      "dial_code": "+1441",
      "code": "BM",
      "emoji": "🇧🇲"
    },
    {
      "name": "Bhutan",
      "dial_code": "+975",
      "code": "BT",
      "emoji": "🇧🇹"
    },
    {
      "name": "Bolivia, Plurinational State of",
      "dial_code": "+591",
      "code": "BO",
      "emoji": "🇧🇴"
    },
    {
      "name": "Bosnia and Herzegovina",
      "dial_code": "+387",
      "code": "BA",
      "emoji": "🇧🇦"
    },
    {
      "name": "Botswana",
      "dial_code": "+267",
      "code": "BW",
      "emoji": "🇧🇼"
    },
    {
      "name": "Brazil",
      "dial_code": "+55",
      "code": "BR",
      "emoji": "🇧🇷"
    },
    {
      "name": "British Indian Ocean Territory",
      "dial_code": "+246",
      "code": "IO",
      "emoji": "🇮🇴"
    },
    {
      "name": "Brunei Darussalam",
      "dial_code": "+673",
      "code": "BN",
      "emoji": "🇧🇳"
    },
    {
      "name": "Bulgaria",
      "dial_code": "+359",
      "code": "BG",
      "emoji": "🇧🇬"
    },
    {
      "name": "Burkina Faso",
      "dial_code": "+226",
      "code": "BF",
      "emoji": "🇧🇫"
    },
    {
      "name": "Burundi",
      "dial_code": "+257",
      "code": "BI",
      "emoji": "🇧🇮"
    },
    {
      "name": "Cambodia",
      "dial_code": "+855",
      "code": "KH",
      "emoji": "🇰🇭"
    },
    {
      "name": "Cameroon",
      "dial_code": "+237",
      "code": "CM",
      "emoji": "🇨🇲"
    },
    {
      "name": "Canada",
      "dial_code": "+1",
      "code": "CA",
      "emoji": "🇨🇦"
    },
    {
      "name": "Cape Verde",
      "dial_code": "+238",
      "code": "CV",
      "emoji": "🇨🇻"
    },
    {
      "name": "Cayman Islands",
      "dial_code": "+345",
      "code": "KY",
      "emoji": "🇰🇾"
    },
    {
      "name": "Central African Republic",
      "dial_code": "+236",
      "code": "CF",
      "emoji": "🇨🇫"
    },
    {
      "name": "Chad",
      "dial_code": "+235",
      "code": "TD",
      "emoji": "🇹🇩"
    },
    {
      "name": "Chile",
      "dial_code": "+56",
      "code": "CL",
      "emoji": "🇨🇱"
    },
    {
      "name": "China",
      "dial_code": "+86",
      "code": "CN",
      "emoji": "🇨🇳"
    },
    {
      "name": "Christmas Island",
      "dial_code": "+61",
      "code": "CX",
      "emoji": "🇨🇽"
    },
    {
      "name": "Cocos (Keeling) Islands",
      "dial_code": "+61",
      "code": "CC",
      "emoji": "🇨🇨"
    },
    {
      "name": "Colombia",
      "dial_code": "+57",
      "code": "CO",
      "emoji": "🇨🇴"
    },
    {
      "name": "Comoros",
      "dial_code": "+269",
      "code": "KM",
      "emoji": "🇰🇲"
    },
    {
      "name": "Congo",
      "dial_code": "+242",
      "code": "CG",
      "emoji": "🇨🇬"
    },
    {
      "name": "Congo, The Democratic Republic of the Congo",
      "dial_code": "+243",
      "code": "CD",
      "emoji": "🇨🇩"
    },
    {
      "name": "Cook Islands",
      "dial_code": "+682",
      "code": "CK",
      "emoji": "🇨🇰"
    },
    {
      "name": "Costa Rica",
      "dial_code": "+506",
      "code": "CR",
      "emoji": "🇨🇷"
    },
    {
      "name": "Cote d'Ivoire",
      "dial_code": "+225",
      "code": "CI",
      "emoji": "🇨🇮"
    },
    {
      "name": "Croatia",
      "dial_code": "+385",
      "code": "HR",
      "emoji": "🇭🇷"
    },
    {
      "name": "Cuba",
      "dial_code": "+53",
      "code": "CU",
      "emoji": "🇨🇺"
    },
    {
      "name": "Cyprus",
      "dial_code": "+357",
      "code": "CY",
      "emoji": "🇨🇾"
    },
    {
      "name": "Czech Republic",
      "dial_code": "+420",
      "code": "CZ",
      "emoji": "🇨🇿"
    },
    {
      "name": "Denmark",
      "dial_code": "+45",
      "code": "DK",
      "emoji": "🇩🇰"
    },
    {
      "name": "Djibouti",
      "dial_code": "+253",
      "code": "DJ",
      "emoji": "🇩🇯"
    },
    {
      "name": "Dominica",
      "dial_code": "+1767",
      "code": "DM",
      "emoji": "🇩🇲"
    },
    {
      "name": "Dominican Republic",
      "dial_code": "+1849",
      "code": "DO",
      "emoji": "🇩🇴"
    },
    {
      "name": "Ecuador",
      "dial_code": "+593",
      "code": "EC",
      "emoji": "🇪🇨"
    },
    {
      "name": "Egypt",
      "dial_code": "+20",
      "code": "EG",
      "emoji": "🇪🇬"
    },
    {
      "name": "El Salvador",
      "dial_code": "+503",
      "code": "SV",
      "emoji": "🇸🇻"
    },
    {
      "name": "Equatorial Guinea",
      "dial_code": "+240",
      "code": "GQ",
      "emoji": "🇬🇶"
    },
    {
      "name": "Eritrea",
      "dial_code": "+291",
      "code": "ER",
      "emoji": "🇪🇷"
    },
    {
      "name": "Estonia",
      "dial_code": "+372",
      "code": "EE",
      "emoji": "🇪🇪"
    },
    {
      "name": "Ethiopia",
      "dial_code": "+251",
      "code": "ET",
      "emoji": "🇪🇹"
    },
    {
      "name": "Falkland Islands (Malvinas)",
      "dial_code": "+500",
      "code": "FK",
      "emoji": "🇫🇰"
    },
    {
      "name": "Faroe Islands",
      "dial_code": "+298",
      "code": "FO",
      "emoji": "🇫🇴"
    },
    {
      "name": "Fiji",
      "dial_code": "+679",
      "code": "FJ",
      "emoji": "🇫🇯"
    },
    {
      "name": "Finland",
      "dial_code": "+358",
      "code": "FI",
      "emoji": "🇫🇮"
    },
    {
      "name": "France",
      "dial_code": "+33",
      "code": "FR",
      "emoji": "🇫🇷"
    },
    {
      "name": "French Guiana",
      "dial_code": "+594",
      "code": "GF",
      "emoji": "🇬🇫"
    },
    {
      "name": "French Polynesia",
      "dial_code": "+689",
      "code": "PF",
      "emoji": "🇵🇫"
    },
    {
      "name": "Gabon",
      "dial_code": "+241",
      "code": "GA",
      "emoji": "🇬🇦"
    },
    {
      "name": "Gambia",
      "dial_code": "+220",
      "code": "GM",
      "emoji": "🇬🇲"
    },
    {
      "name": "Georgia",
      "dial_code": "+995",
      "code": "GE",
      "emoji": "🇬🇪"
    },
    {
      "name": "Germany",
      "dial_code": "+49",
      "code": "DE",
      "emoji": "🇩🇪"
    },
    {
      "name": "Ghana",
      "dial_code": "+233",
      "code": "GH",
      "emoji": "🇬🇭"
    },
    {
      "name": "Gibraltar",
      "dial_code": "+350",
      "code": "GI",
      "emoji": "🇬🇮"
    },
    {
      "name": "Greece",
      "dial_code": "+30",
      "code": "GR",
      "emoji": "🇬🇷"
    },
    {
      "name": "Greenland",
      "dial_code": "+299",
      "code": "GL",
      "emoji": "🇬🇱"
    },
    {
      "name": "Grenada",
      "dial_code": "+1473",
      "code": "GD",
      "emoji": "🇬🇩"
    },
    {
      "name": "Guadeloupe",
      "dial_code": "+590",
      "code": "GP",
      "emoji": "🇬🇵"
    },
    {
      "name": "Guam",
      "dial_code": "+1671",
      "code": "GU",
      "emoji": "🇬🇺"
    },
    {
      "name": "Guatemala",
      "dial_code": "+502",
      "code": "GT",
      "emoji": "🇬🇹"
    },
    {
      "name": "Guernsey",
      "dial_code": "+44",
      "code": "GG",
      "emoji": "🇬🇬"
    },
    {
      "name": "Guinea",
      "dial_code": "+224",
      "code": "GN",
      "emoji": "🇬🇳"
    },
    {
      "name": "Guinea-Bissau",
      "dial_code": "+245",
      "code": "GW",
      "emoji": "🇬🇼"
    },
    {
      "name": "Guyana",
      "dial_code": "+595",
      "code": "GY",
      "emoji": "🇬🇾"
    },
    {
      "name": "Haiti",
      "dial_code": "+509",
      "code": "HT",
      "emoji": "🇭🇹"
    },
    {
      "name": "Holy See (Vatican City State)",
      "dial_code": "+379",
      "code": "VA",
      "emoji": "🇻🇦"
    },
    {
      "name": "Honduras",
      "dial_code": "+504",
      "code": "HN",
      "emoji": "🇭🇳"
    },
    {
      "name": "Hong Kong",
      "dial_code": "+852",
      "code": "HK",
      "emoji": "🇭🇰"
    },
    {
      "name": "Hungary",
      "dial_code": "+36",
      "code": "HU",
      "emoji": "🇭🇺"
    },
    {
      "name": "Iceland",
      "dial_code": "+354",
      "code": "IS",
      "emoji": "🇮🇸"
    },
    {
      "name": "India",
      "dial_code": "+91",
      "code": "IN",
      "emoji": "🇮🇳"
    },
    {
      "name": "Indonesia",
      "dial_code": "+62",
      "code": "ID",
      "emoji": "🇮🇩"
    },
    {
      "name": "Iran, Islamic Republic of Persian Gulf",
      "dial_code": "+98",
      "code": "IR",
      "emoji": "🇮🇷"
    },
    {
      "name": "Iraq",
      "dial_code": "+964",
      "code": "IQ",
      "emoji": "🇮🇶"
    },
    {
      "name": "Ireland",
      "dial_code": "+353",
      "code": "IE",
      "emoji": "🇮🇪"
    },
    {
      "name": "Isle of Man",
      "dial_code": "+44",
      "code": "IM",
      "emoji": "🇮🇲"
    },
    {
      "name": "Israel",
      "dial_code": "+972",
      "code": "IL",
      "emoji": "🇮🇱"
    },
    {
      "name": "Italy",
      "dial_code": "+39",
      "code": "IT",
      "emoji": "🇮🇹"
    },
    {
      "name": "Jamaica",
      "dial_code": "+1876",
      "code": "JM",
      "emoji": "🇯🇲"
    },
    {
      "name": "Japan",
      "dial_code": "+81",
      "code": "JP",
      "emoji": "🇯🇵"
    },
    {
      "name": "Jersey",
      "dial_code": "+44",
      "code": "JE",
      "emoji": "🇯🇪"
    },
    {
      "name": "Jordan",
      "dial_code": "+962",
      "code": "JO",
      "emoji": "🇯🇴"
    },
    {
      "name": "Kazakhstan",
      "dial_code": "+77",
      "code": "KZ",
      "emoji": "🇰🇿"
    },
    {
      "name": "Kenya",
      "dial_code": "+254",
      "code": "KE",
      "emoji": "🇰🇪"
    },
    {
      "name": "Kiribati",
      "dial_code": "+686",
      "code": "KI",
      "emoji": "🇰🇮"
    },
    {
      "name": "Korea, Democratic People's Republic of Korea",
      "dial_code": "+850",
      "code": "KP",
      "emoji": "🇰🇵"
    },
    {
      "name": "Korea, Republic of South Korea",
      "dial_code": "+82",
      "code": "KR",
      "emoji": "🇰🇷"
    },
    {
      "name": "Kuwait",
      "dial_code": "+965",
      "code": "KW",
      "emoji": "🇰🇼"
    },
    {
      "name": "Kyrgyzstan",
      "dial_code": "+996",
      "code": "KG",
      "emoji": "🇰🇬"
    },
    {
      "name": "Laos",
      "dial_code": "+856",
      "code": "LA",
      "emoji": "🇱🇦"
    },
    {
      "name": "Latvia",
      "dial_code": "+371",
      "code": "LV",
      "emoji": "🇱🇻"
    },
    {
      "name": "Lebanon",
      "dial_code": "+961",
      "code": "LB",
      "emoji": "🇱🇧"
    },
    {
      "name": "Lesotho",
      "dial_code": "+266",
      "code": "LS",
      "emoji": "🇱🇸"
    },
    {
      "name": "Liberia",
      "dial_code": "+231",
      "code": "LR",
      "emoji": "🇱🇷"
    },
    {
      "name": "Libyan Arab Jamahiriya",
      "dial_code": "+218",
      "code": "LY",
      "emoji": "🇱🇾"
    },
    {
      "name": "Liechtenstein",
      "dial_code": "+423",
      "code": "LI",
      "emoji": "🇱🇮"
    },
    {
      "name": "Lithuania",
      "dial_code": "+370",
      "code": "LT",
      "emoji": "🇱🇹"
    },
    {
      "name": "Luxembourg",
      "dial_code": "+352",
      "code": "LU",
      "emoji": "🇱🇺"
    },
    {
      "name": "Macao",
      "dial_code": "+853",
      "code": "MO",
      "emoji": "🇲🇴"
    },
    {
      "name": "Macedonia",
      "dial_code": "+389",
      "code": "MK",
      "emoji": "🇲🇰"
    },
    {
      "name": "Madagascar",
      "dial_code": "+261",
      "code": "MG",
      "emoji": "🇲🇬"
    },
    {
      "name": "Malawi",
      "dial_code": "+265",
      "code": "MW",
      "emoji": "🇲🇼"
    },
    {
      "name": "Malaysia",
      "dial_code": "+60",
      "code": "MY",
      "emoji": "🇲🇾"
    },
    {
      "name": "Maldives",
      "dial_code": "+960",
      "code": "MV",
      "emoji": "🇲🇻"
    },
    {
      "name": "Mali",
      "dial_code": "+223",
      "code": "ML",
      "emoji": "🇲🇱"
    },
    {
      "name": "Malta",
      "dial_code": "+356",
      "code": "MT",
      "emoji": "🇲🇹"
    },
    {
      "name": "Marshall Islands",
      "dial_code": "+692",
      "code": "MH",
      "emoji": "🇲🇭"
    },
    {
      "name": "Martinique",
      "dial_code": "+596",
      "code": "MQ",
      "emoji": "🇲🇶"
    },
    {
      "name": "Mauritania",
      "dial_code": "+222",
      "code": "MR",
      "emoji": "🇲🇷"
    },
    {
      "name": "Mauritius",
      "dial_code": "+230",
      "code": "🇲🇺"
    },
    {
      "name": "Mayotte",
      "dial_code": "+262",
      "code": "YT",
      "emoji": "🇾🇹"
    },
    {
      "name": "Mexico",
      "dial_code": "+52",
      "code": "MX",
      "emoji": "🇲🇽"
    },
    {
      "name": "Micronesia, Federated States of Micronesia",
      "dial_code": "+691",
      "code": "FM",
      "emoji": "🇫🇲"
    },
    {
      "name": "Moldova",
      "dial_code": "+373",
      "code": "MD",
      "emoji": "🇲🇩"
    },
    {
      "name": "Monaco",
      "dial_code": "+377",
      "code": "MC",
      "emoji": "🇲🇨"
    },
    {
      "name": "Mongolia",
      "dial_code": "+976",
      "code": "MN",
      "emoji": "🇲🇳"
    },
    {
      "name": "Montenegro",
      "dial_code": "+382",
      "code": "ME",
      "emoji": "🇲🇪"
    },
    {
      "name": "Montserrat",
      "dial_code": "+1664",
      "code": "MS",
      "emoji": "🇲🇸"
    },
    {
      "name": "Morocco",
      "dial_code": "+212",
      "code": "MA",
      "emoji": "🇲🇦"
    },
    {
      "name": "Mozambique",
      "dial_code": "+258",
      "code": "MZ",
      "emoji": "🇲🇿"
    },
    {
      "name": "Myanmar",
      "dial_code": "+95",
      "code": "MM",
      "emoji": "🇲🇲"
    },
    {
      "name": "Namibia",
      "dial_code": "+264",
      "code": "NA",
      "emoji": "🇳🇦"
    },
    {
      "name": "Nauru",
      "dial_code": "+674",
      "code": "NR",
      "emoji": "🇳🇷"
    },
    {
      "name": "Nepal",
      "dial_code": "+977",
      "code": "NP",
      "emoji": "🇳🇵"
    },
    {
      "name": "Netherlands",
      "dial_code": "+31",
      "code": "NL",
      "emoji": "🇳🇱"
    },
    {
      "name": "Netherlands Antilles",
      "dial_code": "+599",
      "code": "AN",
      "emoji": "🇳🇱"
    },
    {
      "name": "New Caledonia",
      "dial_code": "+687",
      "code": "NC",
      "emoji": "🇳🇨"
    },
    {
      "name": "New Zealand",
      "dial_code": "+64",
      "code": "NZ",
      "emoji": "🇳🇿"
    },
    {
      "name": "Nicaragua",
      "dial_code": "+505",
      "code": "NI",
      "emoji": "🇳🇮"
    },
    {
      "name": "Niger",
      "dial_code": "+227",
      "code": "NE",
      "emoji": "🇳🇪"
    },
    {
      "name": "Nigeria",
      "dial_code": "+234",
      "code": "NG",
      "emoji": "🇳🇬"
    },
    {
      "name": "Niue",
      "dial_code": "+683",
      "code": "NU",
      "emoji": "🇳🇺"
    },
    {
      "name": "Norfolk Island",
      "dial_code": "+672",
      "code": "NF",
      "emoji": "🇳🇫"
    },
    {
      "name": "Northern Mariana Islands",
      "dial_code": "+1670",
      "code": "MP",
      "emoji": "🏳"
    },
    {
      "name": "Norway",
      "dial_code": "+47",
      "code": "NO",
      "emoji": "🇳🇴"
    },
    {
      "name": "Oman",
      "dial_code": "+968",
      "code": "OM",
      "emoji": "🇴🇲"
    },
    {
      "name": "Pakistan",
      "dial_code": "+92",
      "code": "PK",
      "emoji": "🇵🇰"
    },
    {
      "name": "Palau",
      "dial_code": "+680",
      "code": "PW",
      "emoji": "🇵🇼"
    },
    {
      "name": "Palestinian Territory, Occupied",
      "dial_code": "+970",
      "code": "PS",
      "emoji": "🇵🇸"
    },
    {
      "name": "Panama",
      "dial_code": "+507",
      "code": "PA",
      "emoji": "🇵🇦"
    },
    {
      "name": "Papua New Guinea",
      "dial_code": "+675",
      "code": "PG",
      "emoji": "🇵🇬"
    },
    {
      "name": "Paraguay",
      "dial_code": "+595",
      "code": "PY",
      "emoji": "🇵🇾"
    },
    {
      "name": "Peru",
      "dial_code": "+51",
      "code": "PE",
      "emoji": "🇵🇪"
    },
    {
      "name": "Philippines",
      "dial_code": "+63",
      "code": "PH",
      "emoji": "🇵🇭"
    },
    {
      "name": "Pitcairn",
      "dial_code": "+872",
      "code": "PN",
      "emoji": "🇵🇳"
    },
    {
      "name": "Poland",
      "dial_code": "+48",
      "code": "PL",
      "emoji": "🇵🇱"
    },
    {
      "name": "Portugal",
      "dial_code": "+351",
      "code": "PT",
      "emoji": "🇵🇹"
    },
    {
      "name": "Puerto Rico",
      "dial_code": "+1939",
      "code": "PR",
      "emoji": "🇵🇷"
    },
    {
      "name": "Qatar",
      "dial_code": "+974",
      "code": "QA",
      "emoji": "🇶🇦"
    },
    {
      "name": "Romania",
      "dial_code": "+40",
      "code": "RO",
      "emoji": "🇷🇴"
    },
    {
      "name": "Russia",
      "dial_code": "+7",
      "code": "RU",
      "emoji": "🇷🇺"
    },
    {
      "name": "Rwanda",
      "dial_code": "+250",
      "code": "RW",
      "emoji": "🇷🇼"
    },
    {
      "name": "Reunion",
      "dial_code": "+262",
      "code": "RE",
      "emoji": "🇷🇪"
    },
    {
      "name": "Saint Barthelemy",
      "dial_code": "+590",
      "code": "BL",
      "emoji": "🇧🇱"
    },
    {
      "name": "Saint Helena, Ascension and Tristan Da Cunha",
      "dial_code": "+290",
      "code": "SH",
      "emoji": "🇸🇭"
    },
    {
      "name": "Saint Kitts and Nevis",
      "dial_code": "+1869",
      "code": "KN",
      "emoji": "🇰🇳"
    },
    {
      "name": "Saint Lucia",
      "dial_code": "+1758",
      "code": "LC",
      "emoji": "🇱🇨"
    },
    {
      "name": "Saint Martin",
      "dial_code": "+590",
      "code": "MF",
      "emoji": "🇲🇫"
    },
    {
      "name": "Saint Pierre and Miquelon",
      "dial_code": "+508",
      "code": "PM",
      "emoji": "🇵🇲"
    },
    {
      "name": "Saint Vincent and the Grenadines",
      "dial_code": "+1784",
      "code": "VC",
      "emoji": "🇻🇨"
    },
    {
      "name": "Samoa",
      "dial_code": "+685",
      "code": "WS",
      "emoji": "🇼🇸"
    },
    {
      "name": "San Marino",
      "dial_code": "+378",
      "code": "SM",
      "emoji": "🇸🇲"
    },
    {
      "name": "Sao Tome and Principe",
      "dial_code": "+239",
      "code": "ST",
      "emoji": "🇸🇹"
    },
    {
      "name": "Saudi Arabia",
      "dial_code": "+966",
      "code": "SA",
      "emoji": "🇸🇩"
    },
    {
      "name": "Senegal",
      "dial_code": "+221",
      "code": "SN",
      "emoji": "🇸🇳"
    },
    {
      "name": "Serbia",
      "dial_code": "+381",
      "code": "RS",
      "emoji": "🇷🇸"
    },
    {
      "name": "Seychelles",
      "dial_code": "+248",
      "code": "SC",
      "emoji": "🇸🇨"
    },
    {
      "name": "Sierra Leone",
      "dial_code": "+232",
      "code": "SL",
      "emoji": "🇸🇱"
    },
    {
      "name": "Singapore",
      "dial_code": "+65",
      "code": "SG",
      "emoji": "🇸🇬"
    },
    {
      "name": "Slovakia",
      "dial_code": "+421",
      "code": "SK",
      "emoji": "🇸🇰"
    },
    {
      "name": "Slovenia",
      "dial_code": "+386",
      "code": "SI",
      "emoji": "🇸🇮"
    },
    {
      "name": "Solomon Islands",
      "dial_code": "+677",
      "code": "SB",
      "emoji": "🇸🇧"
    },
    {
      "name": "Somalia",
      "dial_code": "+252",
      "code": "SO",
      "emoji": "🇸🇴"
    },
    {
      "name": "South Africa",
      "dial_code": "+27",
      "code": "ZA",
      "emoji": "🇿🇦"
    },
    {
      "name": "South Sudan",
      "dial_code": "+211",
      "code": "SS",
      "emoji": "🇸🇸"
    },
    {
      "name": "South Georgia and the South Sandwich Islands",
      "dial_code": "+500",
      "code": "GS",
      "emoji": "🇬🇸"
    },
    {
      "name": "Spain",
      "dial_code": "+34",
      "code": "ES",
      "emoji": "🇪🇸"
    },
    {
      "name": "Sri Lanka",
      "dial_code": "+94",
      "code": "LK",
      "emoji": "🇱🇰"
    },
    {
      "name": "Sudan",
      "dial_code": "+249",
      "code": "SD",
      "emoji": "🇸🇩"
    },
    {
      "name": "Suriname",
      "dial_code": "+597",
      "code": "SR",
      "emoji": "🇸🇷"
    },
    {
      "name": "Svalbard and Jan Mayen",
      "dial_code": "+47",
      "code": "SJ",
      "emoji": "🇸🇯"
    },
    {
      "name": "Swaziland",
      "dial_code": "+268",
      "code": "SZ",
      "emoji": "🇸🇿"
    },
    {
      "name": "Sweden",
      "dial_code": "+46",
      "code": "SE",
      "emoji": "🇸🇪"
    },
    {
      "name": "Switzerland",
      "dial_code": "+41",
      "code": "CH",
      "emoji": "🇨🇭"
    },
    {
      "name": "Syrian Arab Republic",
      "dial_code": "+963",
      "code": "SY",
      "emoji": "🇸🇾"
    },
    {
      "name": "Taiwan",
      "dial_code": "+886",
      "code": "TW",
      "emoji": "🇹🇼"
    },
    {
      "name": "Tajikistan",
      "dial_code": "+992",
      "code": "TJ",
      "emoji": "🇹🇯"
    },
    {
      "name": "Tanzania, United Republic of Tanzania",
      "dial_code": "+255",
      "code": "TZ",
      "emoji": "🇹🇿"
    },
    {
      "name": "Thailand",
      "dial_code": "+66",
      "code": "TH",
      "emoji": "🇹🇭"
    },
    {
      "name": "Timor-Leste",
      "dial_code": "+670",
      "code": "TL",
      "emoji": "🇹🇱"
    },
    {
      "name": "Togo",
      "dial_code": "+228",
      "code": "TG",
      "emoji": "🇹🇬"
    },
    {
      "name": "Tokelau",
      "dial_code": "+690",
      "code": "TK",
      "emoji": "🇹🇰"
    },
    {
      "name": "Tonga",
      "dial_code": "+676",
      "code": "TO",
      "emoji": "🇹🇴"
    },
    {
      "name": "Trinidad and Tobago",
      "dial_code": "+1868",
      "code": "TT",
      "emoji": "🇹🇹"
    },
    {
      "name": "Tunisia",
      "dial_code": "+216",
      "code": "TN",
      "emoji": "🇹🇳"
    },
    {
      "name": "Turkey",
      "dial_code": "+90",
      "code": "TR",
      "emoji": "🇹🇷"
    },
    {
      "name": "Turkmenistan",
      "dial_code": "+993",
      "code": "TM",
      "emoji": "🇹🇲"
    },
    {
      "name": "Turks and Caicos Islands",
      "dial_code": "+1649",
      "code": "TC",
      "emoji": "🇹🇨"
    },
    {
      "name": "Tuvalu",
      "dial_code": "+688",
      "code": "TV",
      "emoji": "🇹🇻"
    },
    {
      "name": "Uganda",
      "dial_code": "+256",
      "code": "UG",
      "emoji": "🇺🇬"
    },
    {
      "name": "Ukraine",
      "dial_code": "+380",
      "code": "UA",
      "emoji": "🇺🇦"
    },
    {
      "name": "United Arab Emirates",
      "dial_code": "+971",
      "code": "AE",
      "emoji": "🇦🇪"
    },
    {
      "name": "United Kingdom",
      "dial_code": "+44",
      "code": "GB",
      "emoji": "🇬🇧"
    },
    {
      "name": "United States",
      "dial_code": "+1",
      "code": "US",
      "emoji": "🇺🇸"
    },
    {
      "name": "Uruguay",
      "dial_code": "+598",
      "code": "UY",
      "emoji": "🇺🇾"
    },
    {
      "name": "Uzbekistan",
      "dial_code": "+998",
      "code": "UZ",
      "emoji": "🇺🇿"
    },
    {
      "name": "Vanuatu",
      "dial_code": "+678",
      "code": "VU",
      "emoji": "🇻🇺"
    },
    {
      "name": "Venezuela, Bolivarian Republic of Venezuela",
      "dial_code": "+58",
      "code": "VE",
      "emoji": "🇻🇪"
    },
    {
      "name": "Vietnam",
      "dial_code": "+84",
      "code": "VN",
      "emoji": "🇻🇳"
    },
    {
      "name": "Virgin Islands, British",
      "dial_code": "+1284",
      "code": "VG",
      "emoji": "🇻🇬"
    },
    {
      "name": "Virgin Islands, U.S.",
      "dial_code": "+1340",
      "code": "VI",
      "emoji": "🇻🇮"
    },
    {
      "name": "Wallis and Futuna",
      "dial_code": "+681",
      "code": "WF",
      "emoji": "🇼🇫"
    },
    {
      "name": "Yemen",
      "dial_code": "+967",
      "code": "YE",
      "emoji": "🇾🇪"
    },
    {
      "name": "Zambia",
      "dial_code": "+260",
      "code": "ZM",
      "emoji": "🇿🇲"
    },
    {
      "name": "Zimbabwe",
      "dial_code": "+263",
      "code": "ZW",
      "emoji": "🇿🇼"
    }
  ]

  export const REAL_TIME_DISPLAY_STATUS = {
    NOT_RUNNING: 0,
    STARTING: 1,
    RUNNING: 2,
    STOPPED_EXIT: 3,
    STOPPED_COMPLETED: 4,
    STOPPED_ECG_AF_DETECTED: 5,
    STOPPED_ECG_AF_FAILED: 6,
    STOPPED_BY_DOCTOR: 7,
    DECLINED_BY_PATIENT: 8,
  };


export const ARRYTHMIA_EVENT_TYPES = {
  //arrythmia types it is just a chousen number to represent arrythmia in alerts table
  ECG_AF: 8,
  PPG_AF: 9,
  NORMAL_SINUS_RHYTHM: 1000,
  SINUS_TACHYCARDIA: 1001,
  SINUS_BRADYCARDIA: 1002,
  SINUS_ARRHYTHMIA: 1003,
  CARDIO_PAUSE: 1004,
  AV_BLOCK_1ST_DEGREE_WITH_NSR: 1005,
  AV_BLOCK_1ST_DEGREE_WITH_BRADY: 1006,
  AV_BLOCK_1ST_DEGREE_WITH_TACHY: 1007,
  PREMATURE_VENTRICULAR_CONTRACTION: 1008,
  VENTRICULAR_COUPLET: 1009,
  VENTRICULAR_TRIPLET: 1010,
  VENTRICULAR_BIGEMINY: 1011,
  VENTRICULAR_TRIGEMINY: 1012,
  VENTRICULAR_TACHYCARDIA: 1013,
  SLOW_VENTRICULAR_TACHYCARDIA: 1014,
  PREMATURE_SUPRAVENTRICULAR_CONTRACTION: 1015,
  ATRIAL_COUPLET: 1016,
  ATRIAL_RUN: 1017,
  ATRIAL_BIGEMINY: 1018,
  ATRIAL_TRIGEMINY: 1019,
  ATRIAL_TRIPLET: 1020,
  ATRIAL_FIBRILLATION: 1021,
  ATRIAL_FLUTTER: 1022,
  SUPRAVENTRICULAR_TACHYCARDIA: 1023,
  INTERVENTRICULAR_CONDUCTION_DELAY: 1024,
  NSR_WITH_IVCD: 1025,
  BRADY_WITH_IVCD: 1027,
  TACHY_WITH_IVCD: 1028,
  ARTIFACT: 1029,
  NOISE: 1030,
  UNCLASSIFIED_RHYTHM: 1031,
  UNCLASSIFIED_COMPLEX: 1032,
}

export const ARRYTHMIA_EVENTS = {
  //low priority = 1, high priority = 5

  //this cardiacsense alert
  [ARRYTHMIA_EVENT_TYPES.ECG_AF]: {
    type: 'ECG_AF',
    event_type: 8,
    desc: 'ECG AF',
    short: 'AF',
    priority: 5,
    src: 'cardiacsense'
  },
  //this cardiacsense alert
  [ARRYTHMIA_EVENT_TYPES.PPG_AF]: {
    type: 'PPG_AF',
    event_type: 9,
    desc: 'PPG AF',
    detection_type: 'ppg',
    short: 'AF',
    priority: 5,
    src: 'cardiacsense'
  },
  // from here it is cardiolyse alerts
  [ARRYTHMIA_EVENT_TYPES.NORMAL_SINUS_RHYTHM]: {
    type: 'NORMAL_SINUS_RHYTHM',
    event_type: 1000,
    desc: 'Normal sinus rhythm',
    short: 'NSR',
    priority: 1,
  },
  [ARRYTHMIA_EVENT_TYPES.SINUS_TACHYCARDIA]: {
    type: 'SINUS_TACHYCARDIA',
    event_type: 1001,
    desc: 'Sinus tachycardia',
    short: 'STC',
    priority: 5,
  },
  [ARRYTHMIA_EVENT_TYPES.SINUS_BRADYCARDIA]: {
    type: 'SINUS_BRADYCARDIA',
    event_type: 1002,
    desc: 'Sinus bradycardia',
    short: 'SBC',
    priority: 5,
  },
  [ARRYTHMIA_EVENT_TYPES.SINUS_ARRHYTHMIA]: {
    type: 'SINUS_ARRHYTHMIA',
    event_type: 1003,
    desc: 'Sinus arrhythmia',
    short: 'SAR',
    priority: 1,
  },
  [ARRYTHMIA_EVENT_TYPES.CARDIO_PAUSE]: {
    type: 'PAUSE',
    event_type: 1004,
    desc: 'Pause',
    short: 'PAU',
    priority: 5,
  },
  [ARRYTHMIA_EVENT_TYPES.AV_BLOCK_1ST_DEGREE_WITH_NSR]: {
    type: 'AV_BLOCK_1ST_DEGREE_WITH_NSR',
    event_type: 1005,
    desc: 'First-degree atrioventricular block with normal sinus rhythm',
    short: 'FAB',
    priority: 1,
  },
  [ARRYTHMIA_EVENT_TYPES.AV_BLOCK_1ST_DEGREE_WITH_BRADY]: {
    type: 'AV_BLOCK_1ST_DEGREE_WITH_BRADY',
    event_type: 1006,
    desc: 'First-degree atrioventricular block with sinus bradycardia',
    short: 'FSB',
    priority: 1,
  },
  [ARRYTHMIA_EVENT_TYPES.AV_BLOCK_1ST_DEGREE_WITH_TACHY]: {
    type: 'AV_BLOCK_1ST_DEGREE_WITH_TACHY',
    event_type: 1007,
    desc: 'First-degree atrioventricular block with sinus tachycardia',
    short: 'FST',
    priority: 1,
  },
  [ARRYTHMIA_EVENT_TYPES.PREMATURE_VENTRICULAR_CONTRACTION]: {
    type: 'PREMATURE_VENTRICULAR_CONTRACTION',
    event_type: 1008,
    desc: 'Premature ventricular contraction',
    short: 'PVC',
    priority: 1,
  },
  [ARRYTHMIA_EVENT_TYPES.VENTRICULAR_COUPLET]: {
    type: 'VENTRICULAR_COUPLET',
    event_type: 1009,
    desc: 'Ventricular couplet',
    short: 'VCP',
    priority: 1,
  },
  [ARRYTHMIA_EVENT_TYPES.VENTRICULAR_TRIPLET]: {
    type: 'VENTRICULAR_TRIPLET',
    event_type: 1010,
    desc: 'Ventricular triplet',
    short: 'VTP',
    priority: 1,
  },
  [ARRYTHMIA_EVENT_TYPES.VENTRICULAR_BIGEMINY]: {
    type: 'VENTRICULAR_BIGEMINY',
    event_type: 1011,
    desc: 'Ventricular bigeminy',
    short: 'VBG',
    priority: 1,
  },
  [ARRYTHMIA_EVENT_TYPES.VENTRICULAR_TRIGEMINY]: {
    type: 'VENTRICULAR_TRIGEMINY',
    event_type: 1012,
    desc: 'Ventricular trigeminy',
    short: 'VTG',
    priority: 1,
  },
  [ARRYTHMIA_EVENT_TYPES.VENTRICULAR_TACHYCARDIA]: {
    type: 'VENTRICULAR_TACHYCARDIA',
    event_type: 1013,
    desc: 'Ventricular tachycardia',
    short: 'VTA',
    priority: 1,
  },
  [ARRYTHMIA_EVENT_TYPES.SLOW_VENTRICULAR_TACHYCARDIA]: {
    type: 'SLOW_VENTRICULAR_TACHYCARDIA',
    event_type: 1014,
    desc: 'Slow ventricular tachycardia',
    short: 'LVT',
    priority: 1,
  },
  [ARRYTHMIA_EVENT_TYPES.PREMATURE_SUPRAVENTRICULAR_CONTRACTION]: {
    type: 'PREMATURE_SUPRAVENTRICULAR_CONTRACTION',
    event_type: 1015,
    desc: 'Premature supraventricular contraction',
    short: 'PSC',
    priority: 1,
  },
  [ARRYTHMIA_EVENT_TYPES.ATRIAL_COUPLET]: {
    type: 'ATRIAL_COUPLET',
    event_type: 1016,
    desc: 'Atrial couplet',
    short: 'ATC',
    priority: 1,
  },
  [ARRYTHMIA_EVENT_TYPES.ATRIAL_RUN]: {
    type: 'ATRIAL_RUN',
    event_type: 1017,
    desc: 'Atrial run',
    short: 'ATR',
    priority: 1,
  },
  [ARRYTHMIA_EVENT_TYPES.ATRIAL_BIGEMINY]: {
    type: 'ATRIAL_BIGEMINY',
    event_type: 1018,
    desc: 'Atrial bigeminy',
    short: 'ABB',
    priority: 1,
  },
  [ARRYTHMIA_EVENT_TYPES.ATRIAL_TRIGEMINY]: {
    type: 'ATRIAL_TRIGEMINY',
    event_type: 1019,
    desc: 'Atrial trigeminy',
    short: 'ATG',
    priority: 1,
  },
  [ARRYTHMIA_EVENT_TYPES.ATRIAL_TRIPLET]: {
    type: 'ATRIAL_TRIPLET',
    event_type: 1020,
    desc: 'Atrial triplet',
    short: 'ATP',
    priority: 1,
  },
  [ARRYTHMIA_EVENT_TYPES.ATRIAL_FIBRILLATION]: {
    type: 'ATRIAL_FIBRILLATION',
    event_type: 1021,
    desc: 'Atrial fibrillation',
    short: 'AFI',
    priority: 5,
  },
  [ARRYTHMIA_EVENT_TYPES.ATRIAL_FLUTTER]: {
    type: 'ATRIAL_FLUTTER',
    event_type: 1022,
    desc: 'Atrial flutter',
    short: 'AFL',
    priority: 5,
  },
  [ARRYTHMIA_EVENT_TYPES.SUPRAVENTRICULAR_TACHYCARDIA]: {
    type: 'SUPRAVENTRICULAR_TACHYCARDIA',
    event_type: 1023,
    desc: 'Supraventricular tachycardia',
    short: 'SVT',
    priority: 1,
  },
  [ARRYTHMIA_EVENT_TYPES.INTERVENTRICULAR_CONDUCTION_DELAY]: {
    type: 'INTERVENTRICULAR_CONDUCTION_DELAY',
    event_type: 1024,
    desc: 'Interventricular conduction delay',
    short: 'ICD',
    priority: 1,
  },
  [ARRYTHMIA_EVENT_TYPES.NSR_WITH_IVCD]: {
    type: 'NSR_WITH_IVCD',
    event_type: 1025,
    desc: 'Normal sinus rhythm with interventricular conduction delay',
    short: 'NSI',
    priority: 1,
  },
  [ARRYTHMIA_EVENT_TYPES.BRADY_WITH_IVCD]: {
    type: 'BRADY_WITH_IVCD',
    event_type: 1027,
    desc: 'Sinus bradycardia with interventricular conduction delay',
    short: 'SBI',
    priority: 1,
  },
  [ARRYTHMIA_EVENT_TYPES.TACHY_WITH_IVCD]: {
    type: 'TACHY_WITH_IVCD',
    event_type: 1028,
    desc: 'Sinus tachycardia with interventricular conduction delay',
    short: 'STI',
    priority: 1,
  },
  // ARTIFACT: {
  //   event_type: 1029,
  //   desc: 'Normal sinus rhythm',
  //   short: 'NSR'
  // },
  // NOISE: {
  //   event_type: 1030,
  //   desc: 'Normal sinus rhythm',
  //   short: 'NSR'
  // },
  // UNCLASSIFIED_RHYTHM: {
  //   event_type: 1031,
  //   desc: 'Normal sinus rhythm',
  //   short: 'NSR'
  // },
  // UNCLASSIFIED_COMPLEX: {
  //   event_type: 1032,
  //   desc: 'Normal sinus rhythm',
  //   short: 'NSR'
  // }
}
export const dashboardKeys = {
  spo2: 'SpO2',
  SpO2: 'spo2',
  blood_pressure: 'Blood Pressure',
  'Blood Pressure': 'blood_pressure',
  body_temprature: 'Body Temp',
  'Body Temp': 'body_temprature',
  weight: 'Weight',
  Weight: 'weight',
  glucose: 'Glucose',
  Glucose: 'glucose',
  steps: 'Steps',
  Steps: 'steps',
  calories: 'Calories',
  Calories: 'calories'
}