import React from 'react';
import './switch-toggle.scss';

const ToggleSwitch = ({ onChange, active }) => {

  const toggleSwitch = () => onChange(!active);

  return (
    <div className={`toggle-switch ${active ? 'active' : ''}`} onClick={toggleSwitch}>
      <div className="switch-circle"></div>
    </div>
  );
};

export default ToggleSwitch;
