import axios from 'axios';
import React,{useState} from 'react'
import { useHistory,Link } from 'react-router-dom';
import {Card, CardBody,Form, Button,Input, InputGroupAddon,InputGroupText, InputGroup, Row,Col} from "reactstrap";

export default function ForgotPassword() {
    const [email, setEmail] = useState('')
    const [emailSent, setEmailSent] = useState()
    const [errorMsg, setErrorMsg] = useState()
    const history = useHistory()

    const handleClick =  async ()=>{
        if(emailSent){
            history.push('/auth/login')
        }
        else{
         try{
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/app/user/forgot-password`,{email})
            setEmailSent(true)
            setErrorMsg()
         }
         catch(err){
            setErrorMsg('Please enter a valid email')
         }    
        }
    }

    const renderSentMessage = () =>
    <p>
       {`An email with a reset password link was sent to: ${email}.`}
       <br/>
       If you do not get the reset password email, check your spam folder or contact the support.
    </p>

    const renderForm = ()=>
    <>
    <div className="mb-3">Please enter your account email</div>
    <Row className="mb-3">
        <Col xs="4">
        <div style={{fontSize:'28px'}} > 
            Email
        </div>
        </Col>
        <Col>
            <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                        <i className="ni ni-email-83" />
                    </InputGroupText>
                </InputGroupAddon>
                <Input
                    id = "input-email"
                    value={email}
                    onChange ={e=>setEmail(e.target.value)}
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    />
            </InputGroup>
        </Col>        
    </Row>
    </>

    return (
        <>
        <Col lg="6" md="7">
            <div style={{fontSize:'34px'}} className="text-white">Forgot Password</div>
            <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                    <Form role="form">
                        {emailSent? renderSentMessage() : renderForm()}
                    </Form>
                    <Row>
                        <Col>
                            <small className="text-danger">{errorMsg}</small>
                        </Col>
                        <Col xl="auto">
                            <Button onClick={handleClick} className="bg-blue text-white" type="button">
                                {emailSent? 'Back to login' : 'Send email'}
                            </Button>
                        </Col>
                    </Row>
                    
                </CardBody>
            </Card>
            <Row className="mt-3">
                <Col xs="6">
                    <Link to='/auth/login' className="text-link">
                        {!emailSent && <small>Back to login</small>}
                    </Link>
                </Col>
                </Row>
        </Col>
        </>
    )
}
