import { getPeripheralData } from 'api/admin-api'
import React, { useState } from 'react'

function PeripheralSearch({setPeripheralData}) {
  const [peripheralName, setPeripheralName] = useState('')
  const [peripheralId, setPeripheralId] = useState()
  const [patientId, setPatientId] = useState()

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log('peripheralName', peripheralName)
    const peripheralData = await getPeripheralData(peripheralId, peripheralName, patientId)
    setPeripheralData(peripheralData || {})
    console.log('peripheralData ---->', peripheralData)
}
  return (
    <div className='card'>
    <div className='card-header'>
        <h4 className='card-title'>Get Watch data</h4>
        <form onSubmit={handleSubmit} className='d-flex align-items-center'>
            <div className='form-group pl-2'>
                <label htmlFor='peripheral-name'>Watch Name: </label>
                <input value={peripheralName} onInput={(e) => setPeripheralName(e.target.value)} type='text' className='form-control' id='peripheral-name' placeholder='CS 0000000' />  
            </div>
            <div className='form-group pl-2'>
                <label htmlFor='peripheral-id'>Watch ID: </label>
                <input value={peripheralId} onInput={(e) => setPeripheralId(e.target.value)} type='number' className='form-control' id='peripheral-id' />  
            </div>
            <div className='form-group pl-2'>
                <label htmlFor='patient-id'>Patient ID: </label>
                <input value={patientId} onInput={(e) => setPatientId(e.target.value)} type='number' className='form-control' id='patient-id' />  
            </div>
            <button disabled={!peripheralName && !peripheralId && !patientId} type='submit' className='btn btn-primary h-25 mt-2'>Get Details</button>
        </form>
    </div>
</div>
  )
}

export default PeripheralSearch