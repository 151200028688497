import React from 'react'
import './radio-button.component.scss'

function RadioButtonComponent({name, options, checkedOption, onChange}) {
    const isChecked = (option) => (option === checkedOption)
    return (
        <div className='wrap-radio-input'>
            {options.map(op => <div key={op} className={`form-check mb-3 ${isChecked(op) && 'active' }`}>
                <input onChange={() => onChange(op, name)} className={`form-check-input`} type="radio" name={name} id={op} checked={isChecked(op)} />
                <label className="form-check-label" htmlFor={op}>
                    {op}.
                </label>
            </div>)}
        </div>
    )
}

export default RadioButtonComponent