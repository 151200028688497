import React from 'react';

const HrGraphXAxisComponent = ({data}) => {
  return (
    <div className='rt-x-axis-content'>
      <div className='sec-text'>Sec</div>
     <div className='rt-x-axis'>
     {data && data.map((item, index) => {
        return(
          <div key={`${item}${index}`}>
            <div>{item}</div>
          </div>
        );
      })}
    </div>
    </div>
  );
}

export default HrGraphXAxisComponent;
