import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker';
import { Modal, Button } from "reactstrap"
import { getDaysInMonth } from 'utils';
import './date-picker-modal.scss'
import { YEARS as years, MONTHS as months } from 'consts'
import moment from 'moment';

export default function DatePickerModal({ isOpen, toggle, onConfirm, chooseRange, choose30Days, title, subtitle, selectDateAttention, togglePicker, reportTitle }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [year, setYear] = useState()
  const [month, setMonth] = useState()
  const [submited, setSubmited] = useState(false)

  const onChange = (newDate) => {
    setStartDate(newDate)
  };

  useEffect(() => {
    let start = moment().startOf('day').toDate();
    if (chooseRange) start.setDate(start.getDate() - 7);
    setStartDate(start)
    if (chooseRange) setEndDate(moment().endOf('day').toDate())
    setYear(new Date().getFullYear())
    setMonth(new Date().getMonth() + 1)
    setSubmited(false)
  }, [submited, togglePicker])

  const onChangeRange = (dates) => {
    const [start, end] = dates;
    const startOfDay = moment(start).startOf('day').toDate()
    setStartDate(startOfDay);
    const endOfDay = end ? moment(end).endOf('day').toDate() : null
    setEndDate(endOfDay);
  };

  const onDismiss = (confirm) => {
    toggle(false)
    setStartDate(null)
    setEndDate(null)
    setSubmited(true)
    confirm && onConfirm && onConfirm(year, startDate, endDate)
    // window.location.reload(false);
  }

  const filterDate = (date) => {
    if (chooseRange) return date < new Date()
    return getDaysInMonth(month - 1, year).includes(date.toLocaleDateString()) && date < new Date()
  }
  const CustomDatePickerHeader = () => {

    const onMonthChange = (e) => {
      if (e.target.value === '0') {
        setMonth(null)
        setStartDate(null)
        setEndDate(null)
      }
      else {
        const newMonth = Number(e.target.value)
        setMonth(newMonth)
        const firstDayOfMonth = new Date(`${newMonth}/1/${year}`)
        setStartDate(firstDayOfMonth)
      }
    }

    const onYearChange = (e) => {
      const newYear = Number(e.target.value)
      setYear(newYear)
      setMonth(null)
      setStartDate(null)
      setEndDate(null)
    }
    return (
      <>
        <div style={{ display: subtitle ? 'default' : 'none', paddingTop: '10px', textAlign: 'center', color: 'black', width: '100%', fontSize: 'smaller' }}>
          {subtitle}
        </div>

        <div style={{ marginTop: 10, display: "flex", justifyContent: "center" }}>
          <select
            className='date_modal_dropdown'
            value={month || 'Month'}
            style={{ cursor: year ? 'pointer' : 'default' }}
            disabled={year ? false : true}
            onChange={onMonthChange}
          >
            <option value={0}>{'Month'}</option>
            {months.map((month, i) =>
              <option
                key={i + 1}
                value={i + 1}
                disabled={new Date(`${i + 1}/1/${year}`) > new Date()}>
                {month}
              </option>
            )}
          </select>

          <select
            className='date_modal_dropdown'
            value={year || 'Year'}
            style={{ cursor: 'pointer' }}
            onChange={onYearChange}
          >
            <option value={0}>{'Year'}</option>
            {years.map((year, i) => <option key={i + 1} value={year}>{year}</option>)}
          </select>
        </div>
      </>
    )
  };

  const customDatePickerSelect = ({
    monthDate,
    customHeaderCount,
    decreaseMonth,
    increaseMonth,
  }) =>{
    return (
      <>
       <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
          <button style={{marginBottom:20}}
            aria-label="Previous Month"
            className={
              "react-datepicker__navigation react-datepicker__navigation--previous"
            }
            style={customHeaderCount === 1 ? { visibility: "hidden" } : null}
            onClick={decreaseMonth}
          >
            <span 
              className={
                "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
              }
            >
              {"<"}
            </span>
          </button>
          <span className="react-datepicker__current-month">
            {monthDate.toLocaleString("en-US", {
              month: "long",
              year: "numeric",
            })}
          </span>
          <button
            aria-label="Next Month"
            className={
              "react-datepicker__navigation react-datepicker__navigation--next"
            }
            style={customHeaderCount === 0 ? { visibility: "hidden" } : null}
            onClick={increaseMonth}
          >
            <span
              className={
                "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
              }
            >
              {">"}
            </span>
          </button>
        </div>
      </>
    )
  }

  return (
    <Modal
      className={`modal-dialog-centered ${chooseRange ? 'date-range-modal' : 'single-date-modal'}`}
      isOpen={isOpen}
      toggle={() => onDismiss(false)}
    >
      <div className="modal-body" style={{ padding: '20px 30px', fontSize: 'large' }}>
        <div style={{ color: '#002D66', textAlign: 'center' }} >
          {title}
        </div>
        <div style={{ color: 'black', textAlign: 'center', fontSize: 'smaller',zIndex:1, position: 'absolute',top: '100px', fontWeight:'normal', paddingRight:'60px' , width:'100%'}} >
          {subtitle}
        </div>
        { month === 6 &&
          <div style={{ color: 'black', textAlign: 'center', fontSize: 'smaller',zIndex:1, position: 'absolute',top: '120px', fontWeight:'bold', paddingLeft:'60px'}} >
            {selectDateAttention}
          </div>
        }
        <div className="date-modal">
          <ReactDatePicker
            selected={startDate}
            onChange={chooseRange ? onChangeRange : onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange={chooseRange}
            inline
            // filterDate={filterDate}
            onMonthChange={(v)=>{
              const month = moment(v).month()+1
              setMonth(month)
            }}
            monthsShown={chooseRange ?2 :1}
            showDisabledMonthNavigation
            minDate={moment(new Date()).subtract(6, 'months').toDate()}
            maxDate={new Date()}
            // renderCustomHeader={chooseRange ? customDatePickerSelect : CustomDatePickerHeader}
          />
        </div>
      </div>
      <div className="modal-footer" style={{ paddingTop: 0 }} >
        <div style={{ backgroundColor: '#aeaeae', height: '1px', width: '99%', margin: 'auto' }} />
        <div style={{ marginTop: '25px' }} >
          <Button
            outline
            color="3873AE"
            type="button"
            onClick={() => onDismiss(false)}
            style={{ padding: '0.36rem 1.05rem',borderRadius:20,color:'#000',fontWeight:'400'  }}
          >
            Cancel
          </Button>
          <Button
            className="text-white"
            style={{ padding: '0.425rem 1.05rem', backgroundColor: '#3873AE',borderRadius:20 }}
            data-dismiss="modal"
            type="button"
            disabled={chooseRange ? (!startDate || !endDate) : !month}
            onClick={() => onDismiss(true)}
          >
            {
              reportTitle === 'Reports Archive' ? 'Open' : 'Generate'
            }
            
          </Button>
        </div>
      </div>
    </Modal>
  )
}
