import { addFota } from 'api/admin-api'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import convertFileToBase64 from 'utils/readSingleFile'
import { isValidVersion } from 'utils/validations'

const initialFota = {
    fw_version: '',
    fota: '',
    country: '',
    from_app_version: '',
    to_app_version: ''
}
function FotaForm({backToList}) {
    const { countries, fotaList } = useSelector(state => state)
    const [newFota, setNewFota] = useState(initialFota)
    const [error, setError] = useState('')

    useEffect(() => {
        //fota version num validation
        if(!newFota.fw_version) return
        if(!isValidVersion(newFota.fw_version)) return setError('the version number is not valid')
        if(isExistFotaVersion()) return setError('the version number is already exist')
        setError('')
    }, [newFota.fw_version])

    const isExistFotaVersion = () => {
        const existFota = fotaList.find(f => f.fw_version === newFota.fw_version)
        console.log('existFota', existFota)
        return existFota
    }

    const handleInput = (e) => {
        setNewFota(prevData => ({...prevData, [e.target.name]: e.target.value}))
    }

    const handleInputFileFota = async (e) => {
        const file = e.target.files?.[0];
        console.log('file', file)
        if (file) {
            const base64 = await convertFileToBase64(file)
            const base64Content = base64?.split(',')[1];
            console.log('base64Content', base64Content)
            if(!base64Content) return window.alert('the fota uploaded empty, please try again')
            setNewFota(prevData => ({...prevData, fota: base64Content}))
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log('newFota', newFota)
       try {
         await addFota(newFota)
         backToList()
       } catch (error) {
        console.log('error', error)
       }
    }

    const cancelEdit = () => {
        backToList()
    }

    const renderCountriesSelect = (field) => {
        const options = countries?.map((c) => { return { value: c.code, label: c.name, id: c.id } })
        options.unshift({ value: null, label: 'All countries', id: 99999 })    
        return(
            <div key={field} className='form-group'>
                <label htmlFor={field}>{field}: </label>
                <Select
                    options={options}
                    // isMulti
                    onChange={(selectedElement) => {
                        console.log('selectedElements', selectedElement, selectedElement?.value)
                        setNewFota({...newFota, country: selectedElement?.value})
                    }} 
                />
            </div>
        )
    }

    const renderField = (field) => {
        if(field === 'country') {
           return renderCountriesSelect(field)
        }
        
        let inputProps = {type: 'text', name: field, value: newFota?.[field], onInput: handleInput, className: 'form-control', id: field}
        if(field === 'fota'){
            inputProps = {...inputProps, onInput: handleInputFileFota, type: 'file'}
            delete inputProps.value
        }
        return (
            <div key={field} className='form-group'>
                <label htmlFor={field}>{field}: </label>
                <input {...inputProps}/>
            </div>
        )
    }

  return (
    <div className=''>
            <form onSubmit={handleSubmit} className='form w-50'>
                { Object.keys(newFota).map(field => renderField(field)) }
                <div className='text-danger'>{error}</div>
                <div className='edit-patient-form-actions'>
                    <button disabled={!newFota.fw_version || !newFota.fota || error} type='submit' className='btn btn-primary'>Save Fota</button>
                    <button onClick={cancelEdit} type='button' className='btn btn-danger'>Cancel</button>
                </div>
            </form>        
    </div>
  )
}

export default FotaForm