import { setUserToState } from 'store/actions'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'reactstrap'
import UniqueId from '../../../assets/img/icons/generate-unique-id.svg'
const MAX_UNIQUE_LENGTH = 6

function UniqDoctorId({ user, setUser }) {
    const [uniqueIdList, setUniqueIdList] = useState([])
    const [uniqueId, setUniqueId] = useState()
    const [uniqueIdError, setUniqueIdError] = useState('')
    const dispatch = useDispatch()

    const isUniqueValid = (value) => {
        const alphanumericRegex = /^[0-9A-Za-z]{6}$/
        if (!alphanumericRegex.test(value)) {
            setUniqueIdError('Sorry, user ID is not valid')
            return false
        } else if (uniqueIdList.includes(value)) {
            setUniqueIdError('Sorry, user ID already exist')
            return false
        } else {
            setUniqueIdError('')
            return true
        }
    }

    const handleChange = (value) => {
        if(value.length > MAX_UNIQUE_LENGTH) return
        setUniqueId(value)
        isUniqueValid(value)
    }

    const getAllUniqueIds = async () => {
        const uniqueIds = []
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/app/doctor/get-all-unique-ids`, { headers: { 'Authorization': `Bearer ${user.jwtToken}` } })
        if (res?.data?.message) {
            return
        }
        res.data.map(d => uniqueIds.push(d.unique_id))
        setUniqueIdList(uniqueIds)
    }

    useEffect(() => {
        getAllUniqueIds()
    }, [])

    const generateCode = () => {
        const alphanumericChars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        let code = '';
        while (code.length < MAX_UNIQUE_LENGTH) {
            code += alphanumericChars.charAt(Math.floor(Math.random() * alphanumericChars.length));
        }
        if (isUniqueValid(code)) {
            setUniqueId(code)
        } else {
            generateCode()
        }
        return code;
    }

    const save = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/app/doctor/update-unique-id`, { user_id: user.data.userId, unique_id: uniqueId }, { headers: { 'Authorization': `Bearer ${user.jwtToken}` } })
            if (res?.data?.message) {
                return
            }
            setUser({ ...user, data: { ...user.data, uniqueId: res.data.unique_id } })
            dispatch(setUserToState({ ...user, data: { ...user.data, uniqueId: res.data.unique_id } }))
        } catch (error) {
            console.log('error', error)
        }

    }

    return (
        <div className='unique-section card row'>
            <div className='unique-title'> Create unique ID </div>
            <div className='unique-discreption'> Your physician code will serve as a unique identifier that allows patients to connect with you</div>
            <div className='unique-line'> </div>
            <div className='unique-user-section'>
                <div className='unique-user-id'>Unique user ID</div>
                <div>
                    <input type='text' value={uniqueId} className='unique-input' placeholder='Xx1234' onChange={e => handleChange(e.target.value)} />
                    {uniqueIdError ? <div className='unique-id-error'>{uniqueIdError}</div>
                        :
                        uniqueId &&
                        <div className='unique-id-valid'>User ID is available</div>
                    }
                </div>
            </div>
            <div className='code-requirement'>
                <div className='generate-code-container' onClick={generateCode}>
                    <img className='generate-img' src={UniqueId} alt='uniqueId' />
                    <div className='generate-code'> Generate code for me</div>
                </div>
                <li className='characters-only'>6 Characters only</li>
                <li className='letters-numbers'>Only letters and numbers</li>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button className='btn-submit' disabled={!uniqueId || uniqueIdError} onClick={() => save()}>Submit</Button>
            </div>
        </div>
    )
}

export default UniqDoctorId